import { atom } from "recoil";
import { setDayStart, setDayEnd } from "../utils/databoardHelper";

export const selectedOrganization = atom({
  key: "selectedOrganization",
  default: null,
});

export const selectedSite = atom({
  key: "selectedSite",
  default: null,
});

export const queriedTimeFrame = atom({
  key: "queriedTimeFrame",
  default: {
    startDate: setDayStart(),
    endDate: setDayEnd(),
  },
});

export const currentUser = atom({
  key: "currentUser",
  default: {},
});
