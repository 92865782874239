import React, { Suspense } from "react";
import Sidebar from "../../components/Sidebar";
import LeaderboardPage from "../../components/LeaderboardPage";
import LoadingSpinner from "../../components/LoadingSpinner";

const Leaderboard = () => {
  return (
    <>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Suspense
          fallback={
            <div className="w-full h-screen flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <Sidebar />
          <LeaderboardPage />
        </Suspense>
      </div>
    </>
  );
};

export default Leaderboard;
