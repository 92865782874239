import React, { useReducer } from "react";
import OrganisationsSidebar from "../OrganisationsSidebar";
import RegularSidebar from "../RegularSidebar";
import { XIcon as XSolid, MenuIcon as MenuSolid } from "@heroicons/react/solid";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";

const Sidebar = () => {
  const { transition } = FiniteStateMachine;
  const [sidebarCurrent, setSidebarCurrent] = useReducer(transition, {
    mobileMenuOpen: false,
    mobileMenuClosed: true,
  });

  return (
    <>
      <div className="hidden lg:flex">
        <OrganisationsSidebar />
        <RegularSidebar />
      </div>
      {sidebarCurrent.mobileMenuOpen && (
        <div className="absolute z-10 h-full w-full flex lg:hidden">
          <OrganisationsSidebar />
          <RegularSidebar />
          <div className="bg-gray-500 opacity-75 flex flex-1">
            &nbsp;
            <button
              className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              onClick={() => {
                setSidebarCurrent("mobileMenuClosed");
              }}
            >
              <XSolid className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}

      <div className="absolute z-14 w-full lg:hidden">
        <button
          className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
          onClick={() => {
            setSidebarCurrent("mobileMenuOpen");
          }}
        >
          <MenuSolid className="w-5 h-5" />
        </button>
      </div>
    </>
  );
};

export default Sidebar;
