import React, { useState, useEffect } from "react";
import { timeOptions } from "../../utils/shiftReportHelper";
import { XIcon as XSolid } from "@heroicons/react/solid";

const ShiftRow = ({
  uuid,
  startTime,
  endTime,
  userId = undefined,
  onShiftChange = () => {},
  onDeleteShift = () => {},
  frequency,
  rowIndex,
  users = [],
} = {}) => {
  const [fromTime, setFromTime] = useState(startTime);
  const [toTime, setToTime] = useState(endTime);
  const [selectedUser, setSelectedUser] = useState(userId);

  useEffect(() => {
    onShiftChange({
      uuid,
      startTime: fromTime,
      endTime: toTime,
      userId: selectedUser,
      frequency,
    });
  }, [fromTime, toTime, selectedUser]);

  return (
    <tr className="group">
      <td className=" px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 text-center">
        <span className="mt-6 w-4 block group-hover:hidden ">{rowIndex}</span>
        <button
          className="mt-6 w-4 h-4 p-px2 rounded-full hidden bg-red-500 rounded-full text-white group-hover:block hover:border-red-800 hover:text-red-800"
          onClick={() => onDeleteShift(uuid)}
        >
          <XSolid />
        </button>
      </td>
      <td className="px-2 py-4">
        <label
          htmlFor={`from-time-${uuid}`}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          From
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            id={`from-time-${uuid}`}
            value={fromTime}
            onChange={(e) => setFromTime(e.target.value)}
            className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {timeOptions.map(({ text, value }, i) => (
              <option key={i} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </td>
      <td className="px-2 py-4">
        <label
          htmlFor={`to-time-${uuid}`}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          To
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            id={`to-time-${uuid}`}
            value={toTime}
            onChange={(e) => setToTime(e.target.value)}
            className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {timeOptions.map(({ text, value }, i) => (
              <option key={i} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </td>
      <td className="px-2 py-4">
        <label
          htmlFor={`shift-manager-${uuid}`}
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Shift Manager
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            id={`shift-manager-${uuid}`}
            className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            // value={userId}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            <option value="null">-</option>
            {users.map(({ name, id }, i) => (
              <option key={i} value={id} selected={id == userId}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </td>
    </tr>
  );
};

export default ShiftRow;
