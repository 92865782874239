import distinctColors from "distinct-colors";
import { lastDayOfWeek } from "date-fns";

export const navLinkStyles = [
  "py-4",
  "px-3",
  "text-center",
  "border-b-2",
  "border-transparent",
  "font-medium",
  "text-sm",
  "leading-5",
  "text-gray-500",
  "hover:text-gray-700",
  "hover:border-gray-300",
  "focus:outline-none",
  "focus:text-gray-700",
  "focus:border-gray-300",
];
export const navLInkActiveStyles = [
  "py-4",
  "px-3",
  "text-center",
  "border-b-2",
  "border-green-500",
  "font-medium",
  "text-sm",
  "leading-5",
  "text-green-600",
  "focus:outline-none",
  "focus:text-green-800",
  "focus:border-green-700",
];

export const tetChartOptions = {
  scales: {
    xAxes: [
      {
        id: 'x',
        stacked: true,
      },
    ],
    yAxes: [
      {
        id: "y1",
        stacked: true,
        ticks: {
          suggestedMin: 0,
        },
      },
      {
        stacked: true,
        id: "y2",
        position: "right",
        ticks: {
          suggestedMin: 0,
        },
        // display:false,
      },
    ],
  },
  legend: {
    labels: {
      usePointStyle: true,
    },
  },
  annotation: {
    drawTime: "afterDatasetsDraw",
    events: ["click"],
    annotations: [
      {
        id: "peak15",
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: "",
        borderColor: "rgba(99, 158, 60,0.18)",
        borderWidth: 1,
        label: {
          position: "top",
          backgroundColor: "green",
          content: "Peak 15",
          enabled: true,
          yAdjust: 15,
        },
      },
    ],
  },
};

export const dayOptions = new Map([
  ["monday", "Monday"],
  ["tuesday", "Tuesday"],
  ["wednesday", "Wednesday"],
  ["thursday", "Thursday"],
  ["friday", "Friday"],
  ["saturday", "Saturday"],
  ["sunday", "Sunday"],
]);

export const timeOptions = [
  { text: "", value: 0 },
  { text: "12:00 AM", value: "00:00:00" },
  { text: "1:00 AM", value: "01:00:00" },
  { text: "2:00 AM", value: "02:00:00" },
  { text: "3:00 AM", value: "03:00:00" },
  { text: "4:00 AM", value: "04:00:00" },
  { text: "5:00 AM", value: "05:00:00" },
  { text: "6:00 AM", value: "06:00:00" },
  { text: "7:00 AM", value: "07:00:00" },
  { text: "8:00 AM", value: "08:00:00" },
  { text: "9:00 AM", value: "09:00:00" },
  { text: "10:00 AM", value: "10:00:00" },
  { text: "11:00 AM", value: "11:00:00" },
  { text: "12:00 PM", value: "12:00:00" },
  { text: "1:00 PM", value: "13:00:00" },
  { text: "2:00 PM", value: "14:00:00" },
  { text: "3:00 PM", value: "15:00:00" },
  { text: "4:00 PM", value: "16:00:00" },
  { text: "5:00 PM", value: "17:00:00" },
  { text: "6:00 PM", value: "18:00:00" },
  { text: "7:00 PM", value: "19:00:00" },
  { text: "8:00 PM", value: "20:00:00" },
  { text: "9:00 PM", value: "21:00:00" },
  { text: "10:00 PM", value: "22:00:00" },
  { text: "11:00 PM", value: "23:00:00" },
];

export const frequencyOptions = [
  { text: "Daily", value: "daily" },
  { text: "Weekly", value: "weekly" },
  { text: "Monthly", value: "monthly" },
];

// Add the ordinal suffix to a supplied number.
// e.g. 1st , 2nd, 3rd, 4th etc.
const ordinalSuffixOf = (number = 1) => {
  const mod10 = number % 10,
    mod100 = number % 100;
  if (mod10 === 1 && mod100 !== 11) {
    return number + "st";
  }
  if (mod10 === 2 && mod100 !== 12) {
    return number + "nd";
  }
  if (mod10 === 3 && mod100 !== 13) {
    return number + "rd";
  }
  return number + "th";
};

export const getChartData = (lastNWeekData = [], chartType = "") => {
  if (!lastDayOfWeek.length || chartType === "") {
    return {
      labels: [],
      datasets: [],
    };
  }

  // const pallet = distinctColors({
  //   count: lastNWeekData.length,
  // });

  const pallet = ["#7BAB43", "#A1C974", "#D0E4B9", "#DAE9C7"];

  const labels = [];

  const dataset = lastNWeekData
    .filter((item) => item !== "__typename")
    .map((item, key) => {
      if (!item[chartType]) {
        return {};
      }

      if (!labels.length) {
        labels.push(...item[chartType].labels);
      }
      return {
        label: `${
          key == 0 ? "Today" : key == 1 ? `Last week` : key + " Weeks ago"
        }`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: pallet[key],
        borderColor: pallet[key],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: item[chartType].counts,
      };
    });

  return {
    labels: labels,
    datasets: dataset,
  };
};

export const getChartsData = (lastNWeekData = []) => {
  const orderPaymentData = getChartData(lastNWeekData, "orderPaymentData");
  const paymentData = getChartData(lastNWeekData, "paymentData");
  const paymentDeliverData = getChartData(lastNWeekData, "paymentDeliverData");
  const deliverWithPFAndWBData = getChartData(
    lastNWeekData,
    "deliverWithPFAndWBData"
  );
  return {
    orderPaymentData,
    paymentData,
    paymentDeliverData,
    deliverWithPFAndWBData,
  };
};

export const tourSteps = [
  {
    selector: ".page-header",
    description:
      "This page will quickly show you an overview of the shift wise data.",
  },
];
