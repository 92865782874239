import React, { useRef } from "react";
import { getCommonChartData } from "../../utils/databoardHelper";
import { Bar } from "react-chartjs-2";
import { tetChartOptions } from "../../utils/shiftReportHelper";
import PulseBox from "../PulseBox";
import { get } from "lodash";
import { getChartHeight } from "../../utils";
import { selectedOrganization, selectedSite } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { useLaneUtilization } from "../../graphql/queries/databoard";
import { format } from "date-fns";
export const LaneUtilizationChart = ({
  redrawId = "",
  timeFrame = [],
} = {}) => {
  const organization = useRecoilValue(selectedOrganization);
  const site = useRecoilValue(selectedSite);
  const [timeFrom, timeTo] = timeFrame;

  const laneUtilizationChartRef = useRef();

  const { loading, data: { getLaneUtilization } = {} } = useLaneUtilization({
    site_id: get(site, "id", 0),
    organization: get(organization, "id", null),
    startDate: timeFrom,
    startTime: format(timeFrom, "HH:mm:ss"),
    endDate: timeTo,
    endTime: format(timeTo, "HH:mm:ss"),
    timezone: get(site, "time_zone", ""),
  });
  const dataObject = {
    labels: [...get(getLaneUtilization, "labels", [])],
    data: [...get(getLaneUtilization, "data", [])],
  };
  const chartData = getCommonChartData({
    dataObject,
    chartLabelSensitivity: null,
    chartType: "lane_utilization",
  });

  return (
    <>
      {!loading ? (
        <>
          <div className="w-full xl:col-span-2 bg-white p-5">
            <h2 className="text-center">{`Lane Utilization`}</h2>
            <div className="mt-4">
              <Bar
                key={`tet-chart-${redrawId}`}
                ref={laneUtilizationChartRef}
                height={getChartHeight({ desktop: 100, tablet: 250 })}
                data={chartData}
                options={tetChartOptions}
              />
            </div>
          </div>
        </>
      ) : (
        [...Array(1)].map((item, key) => (
          <PulseBox key={key} className="col-span-2 h-64" />
        ))
      )}
    </>
  );
};

export default LaneUtilizationChart;
