import React, { useState, useReducer } from "react";
import Modal from "../Modal";
import { frequencyOptions } from "../../utils/shiftReportHelper";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { v4 as uuidv4 } from "uuid";
import { get } from "lodash";

const NewReportSchedule = ({
  onAddReportingScheduleHandler = null,
  setNewReportFrequencyModal = null,
  users = [],
} = {}) => {
  const { transition } = FiniteStateMachine;
  if (!onAddReportingScheduleHandler) {
    throw new Error(
      `Invalid 'onAddReportingScheduleHandler' passed to 'NewReportSchedule'`
    );
  }
  if (!setNewReportFrequencyModal) {
    throw new Error(
      `Invalid 'setNewReportFrequencyModal' passed to 'NewReportSchedule'`
    );
  }
  const [initialUser] = users; //Default the user dropdown value to be the first user
  const [userId, setUserId] = useState(get(initialUser, "id", null));

  const [initialFrequency] = frequencyOptions; //Default the frequency dropdown value to be the first frequency option
  const [reportingFrequency, setReportingFrequency] = useState(
    initialFrequency.value
  );
  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
  });
  // const [savedData, setSavedData] = useState(false);

  const onAddReportSchedule = () => {
    onAddReportingScheduleHandler({
      uuid: uuidv4(),
      userId,
      reportingFrequency,
    });

    setCurrentModalState("success");
  };

  return (
    <Modal
      modalState={currentModalState}
      toggleModal={setNewReportFrequencyModal}
      successButtonText={"Add"}
      saveDataHandler={onAddReportSchedule}
    >
      <div className="bg-white p-6 ">
        <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          Add a Report Schedule
        </h2>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <label
                  htmlFor={`from-time-`}
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Report Reciever
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <select
                    id={`from-time-`}
                    // value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    {users.map(({ id, name }, i) => (
                      <option key={i} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <label
                  htmlFor={`report-frequency`}
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Report Frequency
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <select
                    id={`report-frequency`}
                    // value={reportingFrequency}
                    onChange={(e) => setReportingFrequency(e.target.value)}
                    className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    {frequencyOptions.map(({ text, value }, i) => (
                      <option key={i} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default NewReportSchedule;
