import { selector } from "recoil";
import { currentUser, graphqlClient } from "./atoms";
import { UPDATE_USER } from "../graphql/mutations";
import { settings } from "../data.js";
import { get as lodashGet } from "lodash";

const data = settings();

export const loggedInUserLatest = selector({
  key: "loggedInUserLatest",
  get: async ({ get }) => {
    const currentUserState = get(currentUser);

    const user = transformAuthUser(currentUserState);

    return user;
  },
  set: ({ set, get }, newValue) => {
    const user = get(currentUser);
    const tempVariables = { ...user, user_id: user.sub };
    if (newValue.portfolioTutorial) {
      tempVariables["portfolio_tutorial"] = newValue.portfolioTutorial;
    }
    if (newValue.shiftReportTutorial) {
      tempVariables["shiftreport_tutorial"] = newValue.shiftReportTutorial;
    }
    if (newValue.databoardTutorial) {
      tempVariables["databoard_tutorial"] = newValue.databoardTutorial;
    }
    if (newValue.leaderboardTutorial) {
      tempVariables["leaderboard_tutorial"] = newValue.leaderboardTutorial;
    }
    if (newValue.email) {
      tempVariables["email"] = newValue.email;
    }

    const tempNewUser = { ...user, ...newValue };

    set(currentUser, tempNewUser);
    /*setTimeout(async () => {

      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          ...tempVariables,
        },
      });
    }, 1000);*/
  },
});

export const availableOrganizations = selector({
  key: "availableOrganizations",
  get: ({ get }) => {
    // const availableOrgs = data.organizations;
    const authObj = get(currentUser);
    const organization = lodashGet(authObj, "organization", null);

    const availableOrgs = lodashGet(data, "organizations", []).filter(
      (org, i) => {
        if (!organization) {
          return false;
        }
        if (organization === "*") {
          return true;
        }
        if (org.name.toLocaleLowerCase() === organization.toLocaleLowerCase()) {
          return true;
        }
        return false;
      }
    );

    return availableOrgs;
  },
});

export const userLoggedInState = selector({
  key: "userLoggedInState",
  get: ({ get }) => {
    const user = get(currentUser);
    return !!Object.keys(user).length;
  },
});

export const transformAuthUser = (authUser) => {
  const mainNamespace = "https://www.fingermark.tech";
  const subNamespace = "databoard";

  const userMetadata = lodashGet(
    authUser,
    `${mainNamespace}/${subNamespace}/user_metadata`,
    {}
  );
  const newUser = {
    ...authUser,
    accessGroups: authUser[`${mainNamespace}/roles`],
    name: authUser.nickname,
    username: authUser.name,
    allowedSites:
      authUser[`${mainNamespace}/${subNamespace}/app_metadata`].allowed_sites,
    organization:
      authUser[`${mainNamespace}/${subNamespace}/app_metadata`].organization,
    portfolioTutorial: lodashGet(userMetadata, "tutorials.portfolio", false),
    leaderboardTutorial: lodashGet(
      userMetadata,
      "tutorials.leaderboard",
      false
    ),
    databoardTutorial: lodashGet(userMetadata, "tutorials.restaurant", false),
    shiftReportTutorial: lodashGet(
      userMetadata,
      "tutorials.shiftReport",
      false
    ),
  };

  return newUser;
};
