import React, { useRef, useState, useReducer, useMemo, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  eachWeekendOfInterval,
  subWeeks,
  format,
  isSaturday,
  addDays,
} from "date-fns";
import AlertError from "../AlertError";
import PortfolioPerformanceTable from "../PortfolioPerformanceTable";
import { usePerformancesData } from "../../graphql/queries/portfolio";
import { CogIcon as CogSolid } from "@heroicons/react/solid";
import {
  tourSteps,
  getChartsData,
  portfolioLinChartOptions,
} from "../../utils/portfolioHelper";
import CogWheelMenu from "../CogWheelMenu";
import { useClickedOutside } from "../../utils";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { selectedOrganization, currentUser } from "../../recoil/atoms";
import { loggedInUserLatest } from "../../recoil/selectors";
import { useRecoilState, useRecoilValue } from "recoil";
import { getChartHeight } from "../../utils";
import { v4 as uuidv4 } from "uuid";
//import Tour from "reactour";
import { get } from "lodash";
import { getMenus } from "./menus";

const PortfolioPage = () => {
  const organization = useRecoilValue(selectedOrganization);
  const [, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const [authAttribute] = useRecoilState(currentUser);
  const { transition } = FiniteStateMachine;
  const tetChartRef = useRef();
  const oepeChartRef = useRef();
  const vehicleCountChartRef = useRef();
  const getPerformances = [];
  const cogWheelMenuRef = useRef(null);
  const [tour, setTour] = useState(!authAttribute.portfolioTutorial);

  const [currentPageState, setCurrentPageState] = useReducer(transition, {
    menuOpen: false,
    menuClosed: true,
  });

  useEffect(() => {
    setTour(!authAttribute.portfolioTutorial);
  }, [authAttribute]);

  useClickedOutside(cogWheelMenuRef, () => setCurrentPageState("menuClosed"));

  const [timeframe, setTimeFrame] = useState({
    startDate: subWeeks(new Date(), 2),
    endDate: new Date(),
  });

  const { loading, error, data } = usePerformancesData({
    siteIdList: authAttribute.allowedSites,
    startDate: timeframe.startDate,
    endDate: timeframe.endDate,
    organization: get(organization, "id", null),
  });

  if (!loading) {
    getPerformances.splice(
      0,
      getPerformances.length,
      ...(data && data.getPerformances ? data.getPerformances : [])
    );
  }

  const chartsData = getChartsData(getPerformances);

  const menuItems = getMenus(setTimeFrame);

  const weekends = eachWeekendOfInterval({
    start: timeframe.startDate,
    end: timeframe.endDate,
  });

  useMemo(() => {
    portfolioLinChartOptions.annotation.annotations = [];
    weekends.map((weekend, i) => {
      if (i < weekends.length && isSaturday(weekend)) {
        portfolioLinChartOptions.annotation.annotations.push({
          id: "weekend" + uuidv4(),
          type: "box",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          xMin: format(weekends[i], "yyyy-MM-dd"),
          xMax: format(addDays(weekends[i], 1), "yyyy-MM-dd"),
          backgroundColor: "rgba(150, 150, 150,0.10)",
          borderColor: "transparent",
        });
      }
    });
  }, [weekends]);

  return (
    <>
      <div className="flex flex-col w-0 flex-1 lg:mt-0 mt-10 ">
        <div className="flex overflow-y-auto flex-grow">
          <main className="flex-1 relative z-0 overflow-y-scroll py-6 focus:outline-none">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap">
              <h1 className="first-step text-2xl font-semibold text-gray-900 inline flex items-end">
                Portfolio
                <div className="relative" ref={cogWheelMenuRef}>
                  <button
                    onClick={() => setCurrentPageState("menuOpen")}
                    className="options-menu inline-flex mb-2 ml-2"
                  >
                    <CogSolid className="h-5 w-5 text-gray-500 flex items-center content-center" />
                  </button>
                  {currentPageState.menuOpen && (
                    <CogWheelMenu
                      setMenuToggle={() => setCurrentPageState("menuClosed")}
                      menuItems={menuItems}
                    />
                  )}
                </div>
              </h1>
            </div>

            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
              <div className="bg-white rounded-lg overflow-hidden w-full">
                {error ? (
                  <AlertError alertText="Error loading the data for the queried time frame" />
                ) : null}

                <PortfolioPerformanceTable
                  loading={loading}
                  data={getPerformances}
                />
              </div>

              <div className="bg-white rounded-lg mt-4 px-4 py-4 w-full">
                <h2 className="text-center">Vehicle Counts</h2>
                <div className="mt-4">
                  {!loading ? (
                    <Line
                      ref={vehicleCountChartRef}
                      height={getChartHeight({ desktop: 75, tablet: 250 })}
                      data={chartsData["carCountChartData"]}
                      options={portfolioLinChartOptions}
                    />
                  ) : (
                    <span className="skeleton-box w-full h-64 inline-block"></span>
                  )}
                </div>
              </div>

              <div className="bg-white rounded-lg mt-4 px-4 py-4 w-full">
                <h2 className="text-center">{`${organization.oepeLabel} Performance`}</h2>
                <div className="mt-4">
                  {!loading ? (
                    <Line
                      ref={oepeChartRef}
                      height={getChartHeight({ desktop: 75, tablet: 250 })}
                      data={chartsData["oepeChartData"]}
                      options={portfolioLinChartOptions}
                    />
                  ) : (
                    <span className="skeleton-box w-full h-64 inline-block"></span>
                  )}
                </div>
              </div>

              <div className="bg-white rounded-lg mt-4 px-4 py-4 w-full">
                <h2 className="text-center">{`${organization.tetLabel} Performance`}</h2>
                <div className="mt-4">
                  {!loading ? (
                    <Line
                      ref={tetChartRef}
                      height={getChartHeight({ desktop: 75, tablet: 250 })}
                      data={chartsData["tetChartData"]}
                      options={portfolioLinChartOptions}
                    />
                  ) : (
                    <span className="skeleton-box w-full h-64 inline-block"></span>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/*<Tour
        steps={tourSteps}
        isOpen={tour}
        onBeforeClose={() => {
          setAuthAttribute({ databoardTutorial: true });
        }}
        onRequestClose={() => setTour(false)}
        rounded={5}
        accentColor="#42d760"
        className="text-md"
        lastStepNextButton={<button>Got it. Let's start</button>}
      />*/}
    </>
  );
};
export default PortfolioPage;
