import React, { useEffect } from "react";
import AlertError from "../AlertError";
import LoadingSpinner from "../LoadingSpinner";

const Modal = ({
  modalState = {
    error: false,
    loading: false,
    success: false,
  },
  successButtonText = "Save",
  successDisabled = false,
  saveDataHandler = null,
  toggleModal = () => {},
  alertText = "Error saving the data",
  children,
  className = "",
} = {}) => {
  if (!saveDataHandler) {
    throw new Error(`Invalid 'saveDataHandler' passed to 'Modal'`);
  }

  useEffect(() => {

    if (modalState.success) {
      toggleModal(false);
    }
  
  }, [modalState, toggleModal]);

  return (
    <div
      className={`fixed z-50 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center`}
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 transition-opacity"
        onClick={() => toggleModal(false)}
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full md:max-w-3xl ${className}`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {modalState.error && <AlertError alertText={alertText} />}

        {/* Modal Body */}
        {children}

        {/* Modal Footer */}
        <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              disabled={successDisabled}
              onClick={() => {
                saveDataHandler();
              }}
              className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-600 focus:ring-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:opacity-25 disabled:cursor-not-allowed"
            >
              {modalState.loading && <LoadingSpinner />}
              {successButtonText}
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={() => toggleModal(false)}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
