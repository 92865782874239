import { sortBy, orderBy } from "lodash";
export const sortData = (dataArray, sortByText) => {
  switch (sortByText) {
    case "sortByStoreName":
      return sortBy(dataArray, ["site.name"]);
    case "sortByOEPE":
      return sortBy(dataArray, ["oepe_rank"]);
    case "sortByTET":
      return sortBy(dataArray, ["tet_rank"]);
    case "sortByVehicleCount":
      return orderBy(dataArray, ["vehicle_count"], ["desc"]);
    case "sortByPeak15Count":
      return orderBy(dataArray, ["peak_15_count"], ["desc"]);
    default:
      return dataArray;
  }
};

export const getActiveClasses = (activeColumnName) => {
  const columns = {
    oepeRankColumnHeaderClasses: [],
    storeNameColumnHeaderClasses: [],
    tetRankColumnHeaderClasses: [],
    vehicleCountColumnHeaderClasses: [],
    peak15CountColumnHeaderClasses: [],
  };

  switch (activeColumnName) {
    case "sortByStoreName":
      columns.storeNameColumnHeaderClasses.push("font-bold", "!text-blue-400");
      break;
    case "sortByOEPE":
      columns.oepeRankColumnHeaderClasses.push("font-bold", "!text-blue-400");
      break;
    case "sortByTET":
      columns.tetRankColumnHeaderClasses.push("font-bold", "!text-blue-400");
      break;
    case "sortByVehicleCount":
      columns.vehicleCountColumnHeaderClasses.push(
        "font-bold",
        "text-blue-400"
      );
      break;
    case "sortByPeak15Count":
      columns.peak15CountColumnHeaderClasses.push("font-bold", "!text-blue-400");
      break;
  }

  return columns;
};

export const tourSteps = [
  {
    selector: ".first-step",
    description: "This page will give you an overview of all the eyecue stores ",
  },
  {
    selector: ".options-menu",
    description:
      "From this menu you can change the time range for the queried data.",
  },
  {
    selector: "table.leaderboard-table",
    description:
      "By default your stores will be sorted according to their OEPE/OEPU ranking. But you can order them by Store Name, TET/Total Journey Time ranking, vehicle Count or Peak 15 count by clicking the Column heading",
  },
  {
    selector: ".reset-tutorials",
    description:
      "If you want to take a look at this tutorials again, This is where you would reset it again :)",
  },

  {
    selector: ".feedback-dialog",
    description:
      "Finally but most importantly, you can submit your feedback, any bugs/issues you come across or any feature requests from here. This will help us to make this product an ideal fit for you when transitioning.",
  },
];
