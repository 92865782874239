export const getTourSteps = (activeState, setCurrentPageState) => {
  return [
    {
      selector: ".page-header",
      description:
        "This page will give you a quick summary of an individual store.",
    },
    {
      selector: ".options-menu-button",
      description: "Use this menu to use special options.",
      customNextFunc: (logic) => {
        setCurrentPageState("menuOpen");
        logic.next();  
      },
    },
    {
      selector: ".options-menu .dont-round",
      description:
        "If you need to view/download the unrounded data you can use this option",
      customNextFunc: (logic) => {
        setCurrentPageState("menuOpen");
        logic.next();
      },
    },
    {
      selector: ".options-menu .switch-to-old-oepe",
      description:
        "You can switch between OLD OEPE metric and NEW OEPE metric with this option if you are still using the OLD OEPE",
      customNextFunc: (logic) => {
        setCurrentPageState("menuOpen");
        logic.next();
      },
    },
    {
      selector: ".options-menu .download-csv",
      description: "You can download the current queried data as a CSV from here",
      customNextFunc: (logic) => {
        setCurrentPageState("menuClosed");
        logic.next();
      },
    },
    {
      selector: ".options-menu .multi-store",
      description: "You can switch between stores using this menu",
    },
    {
      selector: ".actions",
      description: "Use this button to change the date range which being queried",
    },
    {
      selector: ".queried-time-frame",
      description: "Queried date range is shown here",
    },
    {
      selector: ".reset-tutorials",
      description:
        "If you want to take a look at this tutorials again, This is where you would reset it again :)",
    },
    {
      selector: ".feedback-dialog",
      description:
        "Finally but most importantly, you can submit your feedback, any bugs/issues you come across or any feature requests from here. This will help us to make this product an ideal fit for you when transitioning.",
    },
  ];
};
