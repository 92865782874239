import React from "react";
import Sidebar from "../../components/Sidebar";
import HomePage from "../../components/HomePage";

const Home = () => {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Sidebar />
      <HomePage />
    </div>
  );
};

export default Home;
