import React, { Suspense } from "react";
import Sidebar from "../../components/Sidebar";
import PortfolioPage from "../../components/PortfolioPage";
import LoadingSpinner from "../../components/LoadingSpinner";

const Portfolio = () => {
  return (
    <>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Suspense
          fallback={
            <div className="w-full h-screen flex justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <Sidebar />
          <PortfolioPage />
        </Suspense>
      </div>
    </>
  );
};

export default Portfolio;
