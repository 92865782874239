import React from "react";

const MenuItem = ({
  href = "#",
  onClick = () => {},
  children,
  className = "",
} = {}) => {
  return (
    <a
      href={href}
      className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${className}`}
      role="menuitem"
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const CogWheelMenu = ({
  loadingMenuOptions = false,
  setMenuToggle = null,
  menuItems = [],
  additionalMenuItems = null,
  className = "",
} = {}) => {
  return (
    <>
      <div
        className={`origin-top-left absolute left-0 mt-1 w-56 rounded-md shadow-lg z-10 ${className}`}
      >
        <div className="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            onClick={() => setMenuToggle()}
          >
            {!loadingMenuOptions &&
              menuItems.map((item, i) => {
                return (
                  <MenuItem
                    key={i}
                    onClick={item.onClick}
                    className={item.className}
                  >
                    {item.icon}
                    {item.text}
                  </MenuItem>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CogWheelMenu;
