import React from "react";
import AlertCard from "../AlertCard";
import { format, addMinutes, fromUnixTime } from "date-fns";
import { useRecoilValue } from "recoil";
import { selectedOrganization } from "../../recoil/atoms";
import { get } from "lodash";
const ShiftReportStatBoxes = ({
  loadingSiteConfigs,
  dataSiteConfigs = {},
} = {}) => {
  const {
    pullOuts = 0,
    pullForward = 0,
    waitBay = 0,
    peak15Hour = "",
    peak15HourTimestamp = "",
    peak15Count = 0,
    total = 0,
  } = dataSiteConfigs["getShiftData"] || {};

  const shouldFormatString = !loadingSiteConfigs && peak15Hour !== "";
  const fromTime =
    shouldFormatString &&
    format(fromUnixTime(peak15HourTimestamp / 1000), "hh:mm a");
  const toTime =
    shouldFormatString &&
    format(addMinutes(fromUnixTime(peak15HourTimestamp / 1000), 15), "hh:mm a");

  const dateString = shouldFormatString ? `${fromTime}-${toTime}` : "";

  const diffByText = `last ${format(new Date(), "EEE")} shift`;

  const organization = useRecoilValue(selectedOrganization);

  const show = !(
    organization &&
    (organization.id === "kfc" ||
      organization.id === "kfc_uae" ||
      organization.id === "hrd")
  );

  return (
    <div className="stat-boxes grid xl:grid-cols-15 lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1 gap-4 w-full">
      {!loadingSiteConfigs ? (
        <>
          <AlertCard
            className={`xl:col-span-${
              show ? "3" : "5"
            } lg:col-span-2 md:col-span-1`}
            alert={{
              status: 1,
              value: pullOuts,
              diffBy: pullOuts,
              title: "Pull Outs",
            }}
            diffByText={diffByText}
            showAlertIcon={false}
            viewAll={null}
          />
          <AlertCard
            className="xl:col-span-3 lg:col-span-2 md:col-span-1"
            alert={{
              status: 1,
              value: pullForward,
              diffBy: pullForward,
              title: get(
                organization,
                "pullForwardLabel",
                "Secondary delivery window"
              ),
            }}
            diffByText={diffByText}
            showAlertIcon={false}
            viewAll={null}
            show={show}
          />
          <AlertCard
            className="xl:col-span-3 lg:col-span-2 md:col-span-1"
            alert={{
              status: 1,
              value: waitBay,
              diffBy: waitBay,
              title: "Wait Bay",
            }}
            diffByText={diffByText}
            showAlertIcon={false}
            viewAll={null}
            show={show}
          />
          <AlertCard
            className={`xl:col-span-${
              show ? "3" : "5"
            } lg:col-span-3 md:col-span-1`}
            alert={{
              status: 1,
              value: peak15Count,
              diffBy: peak15Count,
              title: get(organization, "peakPerformanceLabel", "Peak 15min"),
              subTitle: `${dateString}`,
            }}
            diffByText={diffByText}
            showAlertIcon={false}
            viewAll={null}
          />
          <AlertCard
            className={`xl:col-span-${
              show ? "3" : "5"
            } lg:col-span-3 md:col-span-1`}
            alert={{ status: 1, value: total, diffBy: total, title: "Total" }}
            diffByText={diffByText}
            showAlertIcon={false}
            viewAll={null}
          />
        </>
      ) : (
        [...Array(5)].map((item, key) => {
          return (
            <div
              key={key}
              className="xl:col-span-3 lg:col-span-2 md:col-span-1"
            >
              <span className="rounded-md skeleton-box w-full h-32 inline-block"></span>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ShiftReportStatBoxes;
