import React from "react";
import SearchBox from "../SearchBox";
import AcitvityFeed from "../ActivityFeed";
import AlertCard from "../AlertCard";
import MapComponent from "../MapComponent";
import PerformanceTable from "../PerformanceTable";
import StoreTable from "../StoreTable";
import { settings } from "../../data.js";

const data = settings();

const HomePage = () => {
  const tempActivityFeed = data.tempActivityFeed,
    tempAlerts = data.tempAlerts,
    tempStorePerfomances = data.tempStorePerfomances;

  return (
    <div className="flex flex-col w-0 flex-1 ">
      <SearchBox className="flex" />

      <div className="flex overflow-y-auto flex-grow">
        <main className="flex-1 relative z-0 overflow-y-scroll py-6 focus:outline-none">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap">
            <h1 className="text-2xl font-semibold text-gray-900 inline">
              Yum Australia
            </h1>
            <div className="inline flex items-center">
              <p className="text-sm text-gray-600 leading-tight">
                <b>3</b> Items require attention
              </p>
              <button className="ml-4 inline-flex justify-center items-center rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-700 focus:ring-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                View All
              </button>
            </div>
          </div>

          <div className="mt-6 max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:col-span-2 xl:grid-cols-3">
              {tempAlerts.map((alert, i) => (
                <AlertCard
                  key={i}
                  alert={alert}
                  className="sm:col-span-2 md:col-span-2 xl:col-span-1"
                />
              ))}
            </div>
          </div>

          {/* Map And Store Performance */}
          <div className="mt-6 max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-3">
              <div className="col-span-2 flex flex-1">
                <MapComponent
                  src="https://maps.google.com/maps?q=australia&t=&z=5&ie=UTF8&iwloc=&output=embed"
                  height="288"
                />
              </div>
              <div className="col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-1 flex flex-1">
                <PerformanceTable storeData={tempStorePerfomances} />
              </div>
            </div>
          </div>

          <div className="mt-6 max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="col-span-3 sm:col-span-4">
              <StoreTable storeData={tempStorePerfomances} />
            </div>
          </div>
        </main>

        <div className="">
          <AcitvityFeed activityFeed={tempActivityFeed} />
        </div>
      </div>
    </div>
  );
};
export default HomePage;
