import React from "react";
import { format } from "date-fns";
import {
  dayOptions,
  navLinkStyles,
  navLInkActiveStyles,
} from "../../utils/shiftReportHelper";

const currentDay = format(new Date(), "iiii").toLowerCase();

const WeekDaysNavBar = ({
  activeDay = currentDay,
  setActiveDay = null,
} = {}) => {
  if (!setActiveDay) {
    throw new Error(`Invalid 'setActiveDay' passed to 'WeekDaysNavBar'`);
  }
  return (
    <>
      <div className="sm:hidden mb-4">
        <select
          aria-label="Selected tab"
          className="form-select block w-full"
          // value={activeDay}
          onChange={(e) => {
            setActiveDay(e.target.value);
          }}
        >
          {Array.from(dayOptions.keys()).map((key, i) => (
            <option key={i} value={key}>
              {dayOptions.get(key)}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {Array.from(dayOptions.keys()).map((key, i) => (
              <button
                onClick={() => {
                  setActiveDay(key);
                }}
                key={i}
                className={
                  activeDay === key
                    ? navLInkActiveStyles.join(" ")
                    : navLinkStyles.join(" ")
                }
                aria-current="page"
              >
                {dayOptions.get(key)}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default WeekDaysNavBar;
