import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";
import {
  Auth0Provider as AuthProvider,
} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider
      domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
        audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`                
      }}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
