import React from "react";

const AlertError = ({
  showBadge = true,
  badgeText = "Error",
  alertText = "Error loading the data",
  className = "",
} = {}) => {
  return (
    <div className={`bg-red-300 w-full text-center py-4 lg:px-4 ${className}`}>
      <div
        className="bg-red-400 text-red-200 p-2 items-center leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        {showBadge ? (
          <span className="bg-red-500 flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3">
            {badgeText}
          </span>
        ) : null}
        <span className="font-semibold mr-2 text-left flex-auto">
          {alertText}
        </span>
      </div>
    </div>
  );
};

export default AlertError;
