// Used with `useReducer` is a bare minimum implementation

// For more info see:
// - https://xstate.js.org/
// - https://kentcdodds.com/blog/stop-using-isloading-booleans/

const transition = (state, field) => {
  const fields = Object.keys(state).reduce((result, item) => {
    result[item] = false;
    return result;
  }, {});

  return {
    ...fields,
    [field]: true,
  };
};

const getActiveFieldName = (machine) => () => {
  // Should only every be `true` once but in the event of manual intervention
  // - Return last true field in machine
  return Object.entries(machine).reduce((result, entry) => {
    const [key, value] = entry;
    if (value) result.push(key);
    return result;
  }, [])[0];

};

const FiniteStateMachine = {
  transition,
  getActiveFieldName,
};

export { FiniteStateMachine };
