import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";

export const GET_REPORT_USERS = gql`
  query getReportUsers($fingermark_id: String!) {
    getReportUsers(fingermark_id: $fingermark_id) {
      id
      name
      email
      position
      fingermark_id
    }
  }
`;

const GET_SHIFT_DATA = gql`
  query getShiftData(
    $site_id: Int!
    $start_date: String!
    $shift_start_time: String!
    $shift_end_time: String!
    $timezone: String!
    $last_n_shifts: Int
    $organization: String!
  ) {
    getShiftData(
      site_id: $site_id
      date: $start_date
      start_time: $shift_start_time
      end_time: $shift_end_time
      timezone: $timezone
      last_n_shifts: $last_n_shifts
      organization: $organization
    ) {
      pullOuts
      pullForward
      waitBay
      peak15Hour
      peak15HourTimestamp
      peak15Count
      total
      tetData {
        labels
        order
        beforeOrder
        payment
        beforeDeliver
        deliver
        waitingBay
        pullForward
      }
      lastNWeekData {
        orderPaymentData {
          labels
          counts
        }
        paymentData {
          labels
          counts
        }
        paymentDeliverData {
          labels
          counts
        }
        deliverWithPFAndWBData {
          labels
          counts
        }
      }
    }
  }
`;

const GET_LAST_SHIFT_DATA = gql`
  query getLastShiftData(
    $site_id: Int!
    $start_date: String!
    $shift_start_time: String!
    $shift_end_time: String!
    $timezone: String!
    $last_n_shifts: Int!
    $organization: String!
  ) {
    getLastShiftData(
      site_id: $site_id
      date: $start_date
      start_time: $shift_start_time
      end_time: $shift_end_time
      timezone: $timezone
      last_n_shifts: $last_n_shifts
      organization: $organization
    ) {
      orderPaymentData {
        labels
        counts
      }
      paymentData {
        labels
        counts
      }
      paymentDeliverData {
        labels
        counts
      }
      deliverWithPFAndWBData {
        labels
        counts
      }
    }
  }
`;

export const useLastShiftData = ({
  site_id = 0,
  startDate = new Date(),
  startTime = "00:00:00",
  endTime = "00:00:00",
  timezone = "Australia/Sydney",
  last_n_shifts = 3,
  organization = null,
}) => {
  return useQuery(GET_LAST_SHIFT_DATA, {
    variables: {
      site_id: site_id,
      start_date: format(startDate, "yyyy-MM-dd"),
      shift_start_time: startTime,
      shift_end_time: endTime,
      timezone: timezone,
      last_n_shifts: last_n_shifts,
      organization,
    },
  });
};

export const useShiftData = ({
  site_id = 0,
  startDate = new Date(),
  startTime = "00:00:00",
  endTime = "00:00:00",
  timezone = "Australia/Sydney",
  last_n_shifts = 3,
  organization = null,
}) => {
  return useQuery(GET_SHIFT_DATA, {
    variables: {
      site_id: site_id,
      start_date: format(startDate, "yyyy-MM-dd"),
      shift_start_time: startTime,
      shift_end_time: endTime,
      timezone: timezone,
      last_n_shifts: last_n_shifts,
      organization,
    },
  });
};

export const useGetReportUsers = ({ fingermark_id = "" }) => {
  return useQuery(GET_REPORT_USERS, {
    variables: {
      fingermark_id,
    },
  });
};
