import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { format, subDays } from "date-fns";

const GET_PERFORMANCES = gql`
  query getPerformances(
    $site_id_list: [String]!
    $start_date: String!
    $start_time: String!
    $end_date: String!
    $end_time: String!
    $get_all: Boolean
    $organization: String!
  ) {
    getPerformances(
      site_id_list: $site_id_list
      start_date: $start_date
      start_time: $start_time
      end_date: $end_date
      end_time: $end_time
      get_all: $get_all
      organization: $organization
    ) {
      site {
        id
        fingermark_id
        name
        time_zone
      }
      oepe_rank
      oepe_ranking
      tet_rank
      tet_ranking
      vehicle_count
      peak_15_hour
      peak_15_count
      data {
        date
        oepe_sum
        tet_sum
        car_counts
      }
      thruput
    }
  }
`;
export const usePerformancesData = ({
  siteIdList = [],
  startDate = subDays(new Date(), 14),
  startTime = "00:00:00",
  endDate = new Date(),
  endTime = "00:00:00",
  get_all = false,
  organization = null,
}) => {
  return useQuery(GET_PERFORMANCES, {
    variables: {
      site_id_list: siteIdList.map((id) => `${id}`),
      start_date: format(startDate, "yyyy-MM-dd"),
      start_time: startTime,
      end_date: format(endDate, "yyyy-MM-dd"),
      end_time: endTime,
      get_all: get_all,
      organization,
    },
  });
};
