import React from "react";
import { format } from "date-fns";
import {
  CalendarIcon as CalendarOutline,
  ArrowNarrowRightIcon as ArrowNarrowRightOutline,
  SearchIcon as SearchOutline,
  AdjustmentsIcon as AdjustmentsOutline,
} from "@heroicons/react/outline";
import {
  CogIcon as CogSolid,
  ChevronRightIcon as ChevronRightSolid,
  ChevronLeftIcon as ChevronLeftSolid,
  ChevronDoubleRightIcon as ChevronDoubleRightSolid,
  ChevronDoubleLeftIcon as ChevronDoubleLeftSolid,
} from "@heroicons/react/solid";
import CogWheelMenu from "../CogWheelMenu";
import DateTimeRangePicker from "@fingermarkglobal/react-datetimerange-picker";
import { get } from "lodash";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";

const DataboardPageHeader = ({
  cogWheelMenuRef = null,
  currentPageState = null,
  setCurrentPageState = null,
  menuItems = [],
  showAdvance = false,
  setShowAdvance = null,
  setTimeFrame = null,
  timeFrame = null,
  onTimeFrameChange = null,
  site = null,
  sensitivity = null,
  setSensitivity = null,
} = {}) => {
  const { getActiveFieldName } = FiniteStateMachine;

  const onRadioChange = (e) => {
    const val = e.target.value;
    setSensitivity(`${val}`);
  };
  return (
    <>
      <h1 className="page-header text-2xl font-semibold text-gray-900 inline flex items-end flex-wrap lg:flex-nowrap">
        Databoard
        <div className="relative" ref={cogWheelMenuRef}>
          <button
            onClick={() => setCurrentPageState("menuOpen")}
            className="options-menu-button inline-flex mb-2 ml-2 col-span-1"
          >
            <CogSolid className="h-5 w-5 text-gray-500 flex items-center content-center" />
          </button>
          {currentPageState.menuOpen && (
            <CogWheelMenu
              // loadingMenuOptions={loading}
              className="options-menu"
              setMenuToggle={() => setCurrentPageState("menuClosed")}
              menuItems={menuItems}
            />
          )}
        </div>
        <button
          onClick={() => setShowAdvance(!showAdvance)}
          className="actions inline-flex mb-2 ml-2 col-span-1"
        >
          <AdjustmentsOutline className="h-5 w-5 text-gray-500 flex items-center content-center" />
        </button>
        {showAdvance && (
          <div className="date-time-picker-wrapper text-sm font-normal ml-0 mb-2 lg:ml-5 lg:mb-0 w-full flex items-center">
            <div className="inline-flex items-center">
              <div className="flex">
                <div className="mr-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-primary"
                      name="radio-colors"
                      onChange={(e) => onRadioChange(e)}
                      value="daily"
                      checked={getActiveFieldName(sensitivity)() === "daily"}
                    />
                    <span className="ml-2">Daily</span>
                  </label>
                </div>
                <div className="mr-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-primary"
                      name="radio-colors"
                      onChange={(e) => onRadioChange(e)}
                      value="hourly"
                      checked={getActiveFieldName(sensitivity)() === "hourly"}
                    />
                    <span className="ml-2">Hourly</span>
                  </label>
                </div>
                <div className="mr-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-primary"
                      name="radio-colors"
                      onChange={(e) => onRadioChange(e)}
                      value="fifteenMinute"
                      checked={
                        getActiveFieldName(sensitivity)() === "fifteenMinute"
                      }
                    />
                    <span className="ml-2">15 minute</span>
                  </label>
                </div>
                <div className="mr-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-primary"
                      name="radio-colors"
                      onChange={(e) => onRadioChange(e)}
                      value="eachCar"
                      checked={getActiveFieldName(sensitivity)() === "eachCar"}
                    />
                    <span className="ml-2">Each Car</span>
                  </label>
                </div>
              </div>
              <span className="border br-2 mr-2 h-4"></span>
            </div>

            <DateTimeRangePicker
              calendarIcon={<CalendarOutline className="w-5 h-5" />}
              onChange={setTimeFrame}
              value={timeFrame}
              rangeDivider={<ArrowNarrowRightOutline className="w-5 h-5" />}
              clearIcon={null}
              format={sensitivity.daily ? "y-MM-dd" : "y-MM-dd HH:mm"}
              maxDate={new Date()}
              disableClock={true}
              disableTimePicker={sensitivity.daily}
              nextLabel={<ChevronRightSolid className="w-4 h-4 m-auto" />}
              next2Label={
                <ChevronDoubleRightSolid className="w-4 h-4 m-auto" />
              }
              prevLabel={<ChevronLeftSolid className="w-4 h-4 m-auto" />}
              prev2Label={<ChevronDoubleLeftSolid className="w-4 h-4 m-auto" />}
            />
            <button
              onClick={() => onTimeFrameChange()}
              className="inline-flex ml-2 col-span-1 items-center justify-center border p-1 pr-3 b-gray-600 rounded-lg hover:bg-white "
            >
              {" "}
              <SearchOutline className="h-4 w-4 text-gray-500 flex items-center content-center mr-2" />
              Search
            </button>
          </div>
        )}
      </h1>
      <h2 className="queried-time-frame text-lg text-gray-700">
        {`${get(site, "name", "").trim()}`}{" "}
        <span className="text-sm text-gray-500">{`(From ${format(
          timeFrame[0],
          "yyyy MMM do 'at' h:mm aaaa"
        )} to  ${format(timeFrame[1], "yyyy MMM do 'at' h:mm aaaa")} )`}</span>
      </h2>
    </>
  );
};

export default DataboardPageHeader;
