import { useEffect } from "react";
import { format, addMinutes } from "date-fns";
import { mapValues } from "lodash";
export const useClickedOutside = (ref, callbackFunction, tour) => {
  useEffect(() => {

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFunction();
      }
    };

    if(tour) {
      document.removeEventListener("mousedown", handleClickOutside);
      return;
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, tour]);
};

// This will validate if the supplied email is a correct email or not.
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getChartHeight = ({
  desktop = 100,
  tablet = 500,
  mobile = 500,
} = {}) => {
  return window.innerWidth < 900 ? tablet : desktop;
};
export const subChartHeight = ({
  desktop = 100,
  tablet = 500,
  mobile = 500,
} = {}) => {
  return window.innerWidth < 900 ? 300 : 150;
};

export const getFormattedDateLabel = (label, sensitivity) => {
  switch (sensitivity) {
    case "daily":
      return `${format(new Date(label), "yyyy-MM-dd")}`;
    case "hourly":
      return `${format(new Date(label), "yyyy-MM-dd Ha")}`;
    case "fifteenMinute":
      return `${format(new Date(label), "MMM-dd HH:mm")}`;
    case "eachCar":
      return `${format(new Date(label), "MM-dd HH:mm")}`;
    default:
      return `${format(new Date(label), "MM-dd HH:mm")}`;
  }
};

export const getError = (error) => {
  if (error) {
    error = `${error.networkError || error}`.replace(
      /Error: GraphQL error: /g,
      ""
    );
  }
  return error;
};

export const roundValue = (val, precision) => {
  const result =
    Math.round(parseFloat(val) * Math.pow(10, precision)) /
    Math.pow(10, precision);

  if (result >= 0) {
    return result;
  }
  return 0;
};
export const roundArray = (array, precision) => {
  return array.map((val) => {
    if (typeof val === "string") {
      return val;
    } else if (typeof val === "number") {
      if (precision >= 0) {
        return roundValue(val, precision);
      }
      return Math.round(val);
    } else if (typeof val === "object" && Array.isArray(val) === false) {
      return roundObject(val);
    } else if (typeof val === "object" && Array.isArray(val) === true) {
      return roundArray(val);
    } else {
      return val;
    }
  });
};

export const roundObject = (object, precision = null) => {
  return mapValues(object, (val) => {
    if (typeof val === "number") {
      if (precision >= 0) {
        return roundValue(val, precision);
      }
      return Math.round(val);
    } else if (typeof val === "object" && Array.isArray(val) === false) {
      return roundObject(val, precision);
    } else if (typeof val === "object" && Array.isArray(val) === true) {
      return roundArray(val, precision);
    } else {
      return val;
    }
  });
};

export const formatDate = (date, date_format) => {
  return format(addMinutes(date, date.getTimezoneOffset()), `${date_format}`);
};

export const automaticAnnotationColumnWidthPlugin = [
  {
    resize: function (chart) {
      const columns = chart.data.datasets[0].data.length;
      const chartWidth = chart.width - 40;
      const setBorderWidth = chartWidth / columns;
      // const annotationsData = chart.annotation.options.annotations.map(
      //   function (item) {
      //     item.borderWidth = setBorderWidth;
      //     return item;
      //   }
      // );

      // chart.options.annotation.annotations = annotationsData;
      chart.update();
    },
    afterInit: function (chart) {
      const columns = chart.data.datasets[0].data.length;
      const chartWidth = chart.width - 40;
      const setBorderWidth = chartWidth / columns;
      // const annotationsData = chart.annotation.options.annotations.map(
      //   function (item) {
      //     item.borderWidth = setBorderWidth;
      //     return item;
      //   }
      // );
      // chart.options.annotation.annotations = annotationsData;
      chart.update();
    },
  },
];
