import React, { useReducer } from "react";
import { format, addMinutes, subDays, subMonths } from "date-fns";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  queriedTimeFrame,
  selectedSite,
  selectedOrganization,
} from "../../recoil/atoms";
import { setDayStart, setDayEnd } from "../../utils/databoardHelper";
import { useNavigate } from "react-router-dom";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { sortData, getActiveClasses } from "../../utils/leaderboardHelper";
import { TableHeaderCell, TableRow, TableCell } from "../Table";

const PortfolioPerformanceTable = ({ loading = true, data = [] } = {}) => {
  const { transition, getActiveFieldName } = FiniteStateMachine;
  const history = useNavigate();
  const [globalTimeFrame, setGlobalTimeFrame] =
    useRecoilState(queriedTimeFrame);
  const [site, setSite] = useRecoilState(selectedSite);
  const organization = useRecoilValue(selectedOrganization);
  const [currentPortfolioTable, setCurrentPortfolioTable] = useReducer(
    transition,
    {
      sortByStoreName: false,
      sortByOEPE: true,
      sortByTET: false,
      sortByVehicleCount: false,
      sortByPeak15Count: false,
    }
  );

  const getYesterdayReport = (queriedSite) => {
    const startDate = subDays(setDayStart(), 1);
    const endDate = subDays(setDayEnd(), 1);
    setGlobalTimeFrame({
      startDate,
      endDate,
    });

    setSite(queriedSite);
    history("/databoard/restaurant-overview");
  };

  const getLastWeekReport = (queriedSite) => {
    const startDate = subDays(setDayStart(), 7);
    const endDate = setDayEnd();
    setGlobalTimeFrame({
      startDate,
      endDate,
    });

    setSite(queriedSite);
    history("/databoard/restaurant-overview");
  };

  const getLastMonthReport = (queriedSite) => {
    const startDate = subMonths(setDayStart(), 1);
    const endDate = setDayEnd();
    setGlobalTimeFrame({
      startDate,
      endDate,
    });

    setSite(queriedSite);
    history("/databoard/restaurant-overview");
  };

  const activeCurrentPortfolioTable = getActiveFieldName(
    currentPortfolioTable
  )();

  const activeHeaderColumnClasses = getActiveClasses(
    activeCurrentPortfolioTable
  );

  const clickableThClassname = ["cursor-pointer", "hover:text-blue-300"];

  const filteredData = sortData(
    data.filter((store) => store.vehicle_count),
    activeCurrentPortfolioTable
  );

  return (
    <table className="performance-table table-auto w-full">
      <thead>
        <tr>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.storeNameColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPortfolioTable("sortByStoreName")}
          >
            Store Name
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.oepeRankColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPortfolioTable("sortByOEPE")}
          >
            {`${organization.oepeLabel} Ranking`}
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.tetRankColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPortfolioTable("sortByTET")}
          >
            {`${organization.tetLabel} Ranking`}
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.vehicleCountColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPortfolioTable("sortByVehicleCount")}
          >
            Vehicle Count
          </TableHeaderCell>

          {organization.id === "mcd" && (
            <>
              <TableHeaderCell>Peak 15min</TableHeaderCell>
              <TableHeaderCell
                className={clickableThClassname
                  .concat(
                    activeHeaderColumnClasses.peak15CountColumnHeaderClasses
                  )
                  .join(" ")}
                onClick={() => setCurrentPortfolioTable("sortByPeak15Count")}
              >
                Peak 15 Car Count
              </TableHeaderCell>
            </>
          )}
          {(organization.id === "kfc" ||
            organization.id === "kfc_uae" ||
            organization.id === "hrd") && (
            <TableHeaderCell
            // className={clickableThClassname
            //   .concat(activeHeaderColumnClasses.peak15CountColumnHeaderClasses)
            //   .join(" ")}
            // onClick={() => setCurrentPortfolioTable("sortByPeak15Count")}
            >
              Thru-put
            </TableHeaderCell>
          )}

          <TableHeaderCell>View Report for previouse</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {!loading && data ? (
          filteredData.map((store, i) => {
            const peak15Hour = store.peak_15_hour
              ? `${format(
                  new Date(parseInt(store.peak_15_hour)),
                  "yyyy-MM-dd ( h:mm"
                )} - ${format(
                  addMinutes(new Date(parseInt(store.peak_15_hour)), 15),
                  "h:mm a)"
                )}`
              : ""; //returns a string in this format, yyyy-MM-dd ( h:mm - h:mm a)
            return (
              <TableRow key={i} index={i + 1}>
                <TableCell>{store.site.name}</TableCell>
                <TableCell className="text-center">
                  {store.oepe_ranking}
                </TableCell>
                <TableCell className="text-center">
                  {" "}
                  {store.tet_ranking}{" "}
                </TableCell>
                <TableCell className="text-center">
                  <span className="text-sm text-gray-900">
                    {store.vehicle_count}
                  </span>
                </TableCell>

                {organization.id === "mcd" && (
                  <>
                    <TableCell>{peak15Hour}</TableCell>
                    <TableCell className="text-center">
                      {" "}
                      {store.peak_15_count}{" "}
                    </TableCell>
                  </>
                )}

                {(organization.id === "kfc" ||
                  organization.id === "kfc_uae" ||
                  organization.id === "hrd") && (
                  <TableCell className="text-center">
                    {" "}
                    {store.thruput}{" "}
                  </TableCell>
                )}

                <TableCell className="text-center text-gray-500">
                  <span className="flex justify-center">
                    <span
                      className="text-gray-500 hover:text-blue-500 cursor-pointer px-1"
                      onClick={() => getYesterdayReport(store.site)}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-xs ">day</span>
                      </div>
                    </span>{" "}
                    |
                    <span
                      className="text-gray-500 hover:text-blue-500  cursor-pointer  px-1"
                      onClick={() => getLastWeekReport(store.site)}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-xs ">week</span>
                      </div>
                    </span>{" "}
                    |
                    <span
                      className="text-gray-500 hover:text-blue-500  cursor-pointer  px-1"
                      onClick={() => getLastMonthReport(store.site)}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-xs ">month</span>
                      </div>
                    </span>
                  </span>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow index={1}>
            {[...Array(7)].map((item, key) => (
              <TableCell key={key}>
                <span className="skeleton-box h-3 w-full inline-block"></span>
              </TableCell>
            ))}
          </TableRow>
        )}
      </tbody>
    </table>
  );
};

export default PortfolioPerformanceTable;
