import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const GET_SITE_CONFIGS = gql`
  query getSiteConfig($id: String!) {
    getSiteConfigs(id: $id) {
      id
      created_at
      shifts {
        uuid
        frequency
        startTime
        endTime
        userId
      }
      reportSchedules {
        uuid
        reportingFrequency
        userId
      }
      not_allowed_bays
    }
  }
`;

const GET_STORES = gql`
  query getStore($site_id_list: [String!], $organization: String!) {
    getStores(site_id_list: $site_id_list, organization: $organization) {
      name
      id
      fingermark_id
      time_zone
      tet_target
      oepe_target
    }
  }
`;

export const useSiteConfigs = ({ id = "" } = {}) => {
  return useQuery(GET_SITE_CONFIGS, {
    variables: {
      id: id,
    },
  });
};

export const useStores = ({ site_id_list = [], organization = null } = {}) => {
  const resp = useQuery(GET_STORES, {
    fetchPolicy: "network-only",
    variables: {
      site_id_list: site_id_list,
      organization,
    },
  });

  return resp;
};
