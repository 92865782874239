import React, { useReducer } from "react";
import Modal from "../Modal";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { RESET_TUTORIALS } from "../../graphql/mutations";
import { currentUser } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { get } from "lodash";
// import { getCurrentUser, useAuthorized } from "../../auth";
import { loggedInUserLatest } from "../../recoil/selectors";
import { useApolloClient } from '@apollo/client';

const ResetTutorialsModal = ({ toggleModal = null }) => {
  const { transition } = FiniteStateMachine;

  const [loggedInUser] = useRecoilState(currentUser);
  const [, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const client = useApolloClient();

  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
  });

  const username = get(loggedInUser, "name", null);

  const resetTutorials = async () => {
    setCurrentModalState("loading");

    try {
      await client.mutate({
        mutation: RESET_TUTORIALS,
        variables: {
          user_id: get(loggedInUser, "sub", null),
          username: username,
          shiftreport_tutorial: false,
          portfolio_tutorial: false,
          databoard_tutorial: false,
          leaderboard_tutorial: false,
          organization: loggedInUser.organization,
        },
      });

      setCurrentModalState("success");
      setAuthAttribute({
        leaderboardTutorial: false,
        portfolioTutorial: false,
        databoardTutorial: false,
        shiftReportTutorial: false,
      });
    } catch (e) {
      console.log("e", e);
      setCurrentModalState("error");
    }
  };

  return (
    <Modal
      modalState={currentModalState}
      toggleModal={toggleModal}
      saveDataHandler={resetTutorials}
      successButtonText="Yes, Reset"
    >
      <div className="bg-white p-6 ">
        <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          Reset Tutorials
        </h2>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                {/* <label className="block text-sm font-medium leading-5 text-gray-700"> */}
                Do you want to reset the tutorials ?{/* </label> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ResetTutorialsModal;
