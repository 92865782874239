import React, { useState, useEffect } from "react";
import { frequencyOptions } from "../../utils/shiftReportHelper";
import { XIcon } from "@heroicons/react/solid";
import { get } from "lodash";

const ReportScheduleRow = ({
  uuid,
  users,
  userId,
  reportingFrequency = null,
  onReportScheduleChange = null,
  onDeleteReportSchedule = null,
} = {}) => {
  if (!onReportScheduleChange) {
    throw new Error(
      `Invalid 'onReportScheduleChange' passed to 'ReportScheduleRow'`
    );
  }
  if (!onDeleteReportSchedule) {
    throw new Error(
      `Invalid 'onDeleteReportSchedule' passed to 'ReportScheduleRow'`
    );
  }
  const [frequency, setFrequency] = useState(reportingFrequency);

  const currentUser =
    users && users.length ? users.find((user) => user.id === userId) : {};

  useEffect(() => {
    onReportScheduleChange({
      uuid,
      userId: userId,
      reportingFrequency: frequency,
    });
  }, [frequency]);

  return (
    <tr>
      <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
        <div className="flex items-center justify-between group">
          <div className="w-10 h-10 rounded-full flex-shrink-0 mr-4 group-hover:hidden bg-green-600 text-gray-200 text-lg flex items-center justify-center ">
            {get(currentUser, "name[0]", "N/A")}
          </div>
          <button
            className="mr-4 w-10 h-10 p-px2 rounded-full hidden bg-red-500 rounded-full text-white group-hover:block hover:border-red-800 hover:text-red-800"
            onClick={() => onDeleteReportSchedule(uuid)}
          >
            <XIcon />
          </button>
          <div className="flex-1 truncate">
            <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
              {get(currentUser, "name", "Unable to load the user")}
            </h3>
            <p className="mt-1 text-gray-500 text-sm leading-5 truncate">
              {get(currentUser, "name", "Unable to load the user")}
            </p>
          </div>
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
        <div className="mt-1 rounded-md shadow-sm">
          <select
            // value={frequency}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            onChange={(e) => setFrequency(e.target.value)}
          >
            {frequencyOptions.map(({ text, value }, i) => (
              <option key={i} value={value} selected={frequency == value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </td>
    </tr>
  );
};

export default ReportScheduleRow;
