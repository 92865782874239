import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useAuthorized } from "../auth";

const AuthenticatedRoute = ({
  component: Component,
  isAuthorized,
  path = "/",
  ...rest
}) => {
  const isSuperManager = useAuthorized("superManager");
  const isRestrictedManager = useAuthorized("restrictedManager");
  const redirectPath = isSuperManager
    ? "/databoard/leaderboard"
    : isRestrictedManager
    ? "/databoard/portfolio"
    : "/databoard/restaurant-overview";
  return (
    <>
      {isAuthorized ? (
        <Outlet />
      ) : (
        <Route element={<Navigate to={redirectPath} />} />
      )}
    </>
  );
};

export default AuthenticatedRoute;
