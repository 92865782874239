import React, { useRef } from "react";
import { getCommonChartData } from "../../utils/databoardHelper";
import { Bar, Line } from "react-chartjs-2";
import { getShiftReportTetChartData } from "../../utils/getShiftReportTETChartData";
import { tetChartOptions } from "../../utils/shiftReportHelper";
import PulseBox from "../PulseBox";
import { get } from "lodash";
import { getChartHeight } from "../../utils";
import { selectedOrganization, selectedSite } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import LaneUtilizationChart from "../LaneUtilizationChart";
import { useAuthorized } from "../../auth";
import { LockOpenIcon as LockOpen } from "@heroicons/react/outline";
import { useSiteConfigs } from "../../graphql/queries";
export const DataboardChartGrid = ({
  redrawId = "",
  processedData,
  loading = false,
  showOldOEPE = false,
  sensitivity = {},
  timeFrame = [],
}) => {
  const { getActiveFieldName } = FiniteStateMachine;

  const organization = useRecoilValue(selectedOrganization);

  const orgId = get(organization, "id", null);

  const tetChartRef = useRef(),
    oepeChartRef = useRef(),
    vehicleCountChartRef = useRef();

  const tetData = {
    beforeDeliver: get(processedData, "pulse.before_deliver.data_for_tet", []),
    // beforeOrder: get(processedData, "pulse.before_order.data_for_tet", []),
    deliver: get(processedData, "pulse.deliver.data_for_tet", []),
    labels: get(processedData, "pulse.tet.labels", []),
    order: get(processedData, "pulse.order.data_for_tet", []),
    beforePayment: get(processedData, "pulse.before_payment.data_for_tet", []),
    payment: get(processedData, "pulse.payment.data_for_tet", []),
    pullForward: get(processedData, "pulse.pull_forward.data_for_tet", []),
    waitingBay: get(processedData, "pulse.waiting_bay.data_for_tet", []),
  };

  const site = useRecoilValue(selectedSite);

  const {
    loading: loadingSiteConfigs,
    error: errorSiteConfigs,
    data: siteConfigData,
  } = useSiteConfigs({
    id: get(site, "fingermark_id", ""),
  });

  const notAllowedBays = get(
    siteConfigData,
    "getSiteConfigs.not_allowed_bays",
    []
  );

  Object.keys(tetData).map((property, i) => {
    if (notAllowedBays.includes(property)) {
      delete tetData[property];
    }
  });

  if (orgId == "czp") {
    delete tetData.payment;
    delete tetData.beforePayment;
  }

  const totalTetChartData = {
    labels: get(processedData, "pulse.tet.labels", []),
    data: get(processedData, "pulse.tet.data_for_tet", []),
  };

  const tetChartData = getShiftReportTetChartData(
    tetData,
    getActiveFieldName(sensitivity)(),
    get(organization, "tetLabel", "Total journey time"),
    get(site, "tet_target", 180),
    totalTetChartData,
    organization
  );

  const oepeChartData = getCommonChartData({
    dataObject: {
      data: showOldOEPE
        ? get(processedData, "pulse.old_oepe.data", [])
        : get(processedData, "pulse.oepe.data", []),
      labels: showOldOEPE
        ? get(processedData, "pulse.old_oepe.labels", [])
        : get(processedData, "pulse.oepe.labels", []),
    },
    chartType: get(organization, "oepeLabel", "Customer journey time"),
    chartLabelSensitivity: getActiveFieldName(sensitivity)(),
    oepeTaget: get(site, "oepe_target", 120),
  });

  const pivotData = get(processedData, "pivot_data", []);

  const vehicleCountData = pivotData.map((data, i) => data.car_counts);
  const vehicleCountLabels = pivotData.map((data, i) => data.date);

  const vehicleCountChartData = getCommonChartData({
    dataObject: {
      data: vehicleCountData,
      labels: vehicleCountLabels,
    },
    chartType: "vehicle count",
    chartLabelSensitivity: getActiveFieldName(sensitivity)(),
  });
  const isAdmin = useAuthorized("admin");

  const showLaneUtilizationChart =
    get(organization, "id", null) === "kfc" && get(site, "id", 0) === 2; // This hard coded values are so that we can display this component only on DTO as we don't have a flag in the DB to identify the DT type yet

  const maxTet = Math.max.apply(this, totalTetChartData.data);
  const maxChartValue = Math.max.apply(this, [
    maxTet,
    get(site, "tet_target", 180),
  ]);

  const modifiedTetChartOptions = { ...tetChartOptions };
  modifiedTetChartOptions.scales.yAxes[0].ticks.suggestedMax = maxChartValue;
  modifiedTetChartOptions.scales.yAxes[1].ticks.suggestedMax = maxChartValue;

  const automaticAnnotationColumnWidthPlugin = {
    afterDatasetDraw: function (chart, args, options) {
      if (args.meta.yAxisID == "y2") {
        args.meta.data.forEach(function (element) {
          const lineDash = [10, 5];

          element._chart.legend.legendItems[1].lineDash = lineDash;

          var borderWidth = 2;
          var ctx = chart.ctx;
          var vm = element._view;
          var half = vm.width / 2;
          var left = vm.x - half;
          var right = vm.x + half;
          var top = vm.y;
          var width = right - left;
          var height = chart.chartArea.bottom - top + borderWidth / 2 - 1;
          ctx.beginPath();
          ctx.lineWidth = borderWidth;
          ctx.strokeStyle = vm.borderColor;
          ctx.setLineDash(lineDash);
          ctx.moveTo(left, top);
          ctx.lineTo(left, top + height);
          ctx.moveTo(left, top);
          ctx.lineTo(left + width, top);
          ctx.moveTo(left + width, top);
          ctx.lineTo(left + width, top + height);
          ctx.stroke();
          ctx.save();
        });
      }
    },
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="w-full xl:col-span-2 bg-white p-5">
            <h2 className="text-center">{`Average ${get(
              organization,
              "oepeLabel",
              "Customer journey time"
            )} (seconds)`}</h2>
            <div className="mt-4">
              <Bar
                key={`tet-chart-${redrawId}`}
                ref={oepeChartRef}
                height={getChartHeight({ desktop: 100, tablet: 250 })}
                data={oepeChartData}
                options={tetChartOptions}
              />
            </div>
          </div>

          <div className="w-full xl:col-span-2 bg-white p-5">
            <h2 className="text-center">{`Average ${get(
              organization,
              "tetLabel",
              "Total journey time"
            )} Chart (seconds)`}</h2>
            <div className="mt-4">
              <Bar
                key={`tet-chart-${redrawId}`}
                ref={tetChartRef}
                plugins={[automaticAnnotationColumnWidthPlugin]}
                height={getChartHeight({ desktop: 100, tablet: 300 })}
                data={tetChartData}
                options={modifiedTetChartOptions}
              />
            </div>
          </div>
          {isAdmin && (
            <div className="w-full xl:col-span-2 bg-white p-5">
              <h2 className="flex justify-center">
                <LockOpen className="w-5 h-5 text-green-600 mr-2" />{" "}
                {` Vehicle Count Chart`}
              </h2>
              <div className="mt-4">
                <Line
                  key={`count-chart-${redrawId}`}
                  ref={vehicleCountChartRef}
                  height={getChartHeight({ desktop: 100, tablet: 300 })}
                  data={vehicleCountChartData}
                  options={tetChartOptions}
                />
              </div>
            </div>
          )}

          {showLaneUtilizationChart && (
            <LaneUtilizationChart timeFrame={timeFrame} redrawId={redrawId} />
          )}
        </>
      ) : (
        [...Array(2)].map((item, key) => (
          <PulseBox key={key} className="col-span-2 h-64" />
        ))
      )}
    </>
  );
};

export default DataboardChartGrid;
