import React, { useRef, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import { getShiftReportTetChartData } from "../../utils/getShiftReportTETChartData";
import { tetChartOptions } from "../../utils/shiftReportHelper";
import { get } from "lodash";
import {
  automaticAnnotationColumnWidthPlugin,
  getChartHeight,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue } from "recoil";
import { selectedSite, selectedOrganization } from "../../recoil/atoms";
import { useSiteConfigs } from "../../graphql/queries";

const ChartGrid = ({
  redrawId = "",
  chartData = {},
  tetData = {},
  dataSiteConfigs = {},
} = {}) => {
  /*
   * At the moment We need to supply refs to each chart otherwise it tends to not render the chart correctly on subsequent renders.
   * Hopefully this will be fixed on future updates on chartjs library
   */
  const { peak15Hour } = get(dataSiteConfigs, "getShiftData", {});
  const tetChartRef = useRef();
  const orderEndPayStartChartRef = useRef();
  const payChartRef = useRef();
  const payEndDeliveryStartChartRef = useRef();
  const deliveryPFWBChartRef = useRef();

  const site = useRecoilValue(selectedSite);
  const organization = useRecoilValue(selectedOrganization);

  const {
    loading: loadingSiteConfigs,
    error: errorSiteConfigs,
    data: siteConfigData,
  } = useSiteConfigs({
    id: get(site, "fingermark_id", ""),
  });

  const notAllowedBays = get(
    siteConfigData,
    "getSiteConfigs.not_allowed_bays",
    []
  );

  Object.keys(tetData).map((property, i) => {
    if (notAllowedBays.includes(property)) {
      delete tetData[property];
    }
  });

  const tetChartData = getShiftReportTetChartData(
    tetData,
    "fifteenMinute",
    get(organization, "tetLabel", "Total journey time"),
    get(site, "tet_target", 180),
    organization
  );

  const customTETChartOptions = Object.assign({}, { ...tetChartOptions });

  useMemo(() => {
    const peak15Annotation = tetChartOptions.annotation.annotations.find(
      (annot) => {
        if (annot.id === "peak15") {
          annot.value = peak15Hour;
          return annot;
        }
        customTETChartOptions.annotation.annotations = [];
        customTETChartOptions.annotation.annotations.push(peak15Annotation);
      }
    );
    redrawId = uuidv4();
  }, [peak15Hour]);

  return (
    <>
      <div className="tet-chart w-full xl:col-span-2 bg-white p-5">
        <h2 className="text-center">
          Drive Thru-Stage Times (Averaged over 15 min intervals)
        </h2>
        <div className="mt-4">
          <Bar
            key={`tet-chart-${redrawId}`}
            ref={tetChartRef}
            height={getChartHeight()}
            data={tetChartData}
            plugins={automaticAnnotationColumnWidthPlugin}
            options={customTETChartOptions}
          />
        </div>
      </div>
      <div className="order-end-pay-start-comparison w-full xl:col-span-1 bg-white p-5">
        <div className="w-full xl:col-span-2 bg-white p-5">
          <h3 className="text-center text-sm">
            Order End to Pay Start Comparison(3 Recent{" "}
            {`${format(new Date(), "EEEE")}`} shifts)
          </h3>
          <div className="mt-4">
            <Bar
              key={`order-end-pay-start-chart`}
              ref={orderEndPayStartChartRef}
              height={getChartHeight({ desktop: 150, tablet: 300 })}
              data={chartData["orderPaymentData"]}
            />
          </div>
        </div>
      </div>
      <div className="w-full xl:col-span-1 bg-white p-5">
        <div className="w-full xl:col-span-2 bg-white p-5">
          <h3 className="text-center text-sm">
            Pay Duration Comparison(3 Recent {`${format(new Date(), "EEEE")}`}{" "}
            shifts)
          </h3>
          <div className="mt-4">
            <Bar
              key={`pay-chart`}
              ref={payChartRef}
              height={getChartHeight({ desktop: 150, tablet: 300 })}
              data={chartData["paymentData"]}
            />
          </div>
        </div>
      </div>
      <div className="w-full xl:col-span-1 bg-white p-5">
        <div className="w-full xl:col-span-2 bg-white p-5">
          <h3 className="text-center text-sm">
            Pay End to Delivery Start Comparison(3 Recent{" "}
            {`${format(new Date(), "EEEE")}`} shifts)
          </h3>
          <div className="mt-4">
            <Bar
              key={`pay-end-delivery-start-chart`}
              ref={payEndDeliveryStartChartRef}
              height={getChartHeight({ desktop: 150, tablet: 300 })}
              data={chartData["paymentDeliverData"]}
            />
          </div>
        </div>
      </div>
      <div className="w-full xl:col-span-1 bg-white p-5">
        <div className="w-full xl:col-span-2 bg-white p-5">
          <h3 className="text-center text-sm">
            Delivery inc. PF & WB Comparison(3 Recent{" "}
            {`${format(new Date(), "EEEE")}`} shifts)
          </h3>
          <div className="mt-4">
            <Bar
              key={`delivery-PFWB-chart`}
              ref={deliveryPFWBChartRef}
              height={getChartHeight({ desktop: 150, tablet: 300 })}
              data={chartData["deliverWithPFAndWBData"]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PulsLoadingComponent = () => {
  return [...Array(5)].map((item, key) => {
    return (
      <div
        key={key}
        className={`w-full ${
          key > 0 ? "xl:col-span-1" : "xl:col-span-2"
        } bg-white p-5`}
      >
        <span
          className={`rounded-md skeleton-box w-full ${
            key > 0 ? " h-32 " : " h-64 "
          } inline-block`}
        ></span>
      </div>
    );
  });
};

const ShiftReportChartGrid = ({
  redrawId = "",
  tetData,
  chartData,
  loadingSiteConfigs = true,
  dataSiteConfigs = {},
}) => {
  return (
    <>
      {!loadingSiteConfigs && tetData ? (
        <ChartGrid
          redrawId={redrawId}
          chartData={chartData}
          tetData={tetData}
          dataSiteConfigs={dataSiteConfigs}
        />
      ) : (
        <PulsLoadingComponent />
      )}
    </>
  );
};

export default ShiftReportChartGrid;
