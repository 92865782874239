import distinctColors from "distinct-colors";
import { format, addMinutes } from "date-fns";
import { get, sortBy } from "lodash";
import { getFormattedDateLabel } from "./index";
const sortPredicateArray = [
  "Before Order",
  "Order",
  "Before Payment",
  "Payment",
  "Before Deliver",
  "Deliver",
  "Pull Forward",
  "Waiting Bay",
];
export const getShiftReportTetChartData = (
  dataObj = {},
  chartLabelSensitivity = "daily",
  chartType = "TET",
  tetTarget = 180,
  totalTetChartData = null,
  organization = {}
) => {
  // const pallet = distinctColors({
  //   count: Object.keys(dataObj).length,
  // });

  const pallet = [
    "#dd614a",
    "#ff9b71",
    "#ffdc7c",
    "#6ba292",

    "#bbd9e2",
    "#bbe2c5",
    "#7bab43",
    "#e4fde1",
  ];

  const labels = get(dataObj, "labels", []).map((date) => {
    // return getFormattedDateLabel(date, chartLabelSensitivity)
    return date;
  });

  const tempDatasets = Object.keys(dataObj)
    .filter((dataKey) => dataKey !== "labels" && dataKey !== "__typename")
    .map((dataKey, key) => {
      if (!dataObj[dataKey]) return {};

      const chartData = dataObj[dataKey];

      const sentenceCaseDataKey = dataKey.replace(/([A-Z])/g, " $1");
      let label =
        sentenceCaseDataKey.charAt(0).toUpperCase() +
        sentenceCaseDataKey.slice(1);

      switch (dataKey) {
        case "order":
          label = get(organization, "orderLabel", "Order Point");
          break;
        case "payment":
          label = get(organization, "paymentLabel", "Payment");
          break;
        case "deliver":
          label = get(organization, "deliverLabel", "Deliver");
          break;
        case "waitingBay":
          label = get(organization, "waitBayLabel", "Waiting Bay");
          break;
        case "pullForward":
          label = get(
            organization,
            "pullForwardLabel",
            "Secondary delivery window"
          );
          break;
        default:
          break;
      }

      return {
        label,
        // xAxisID:"x1",
        yAxisID: "y1",
        fill: false,
        lineTension: 0.1,
        backgroundColor: pallet[key],
        borderColor: pallet[key],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartData,
        type: "bar",
        order: 3,
      };
    });

  if (totalTetChartData) {
    tempDatasets.push({
      label: chartType,
      // xAxisID:"x2",
      yAxisID: "y2",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "transparent",
      borderColor: "#bbbbbb",
      hoverBorderColor: "#3e68f2",
      borderCapStyle: "butt",
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: totalTetChartData.data,
      type: "bar",
      order: 2,
      borderWidth: 0,
      pointStyle: "rect",
    });
  }

  const datasets = sortBy(tempDatasets, (dataset) =>
    sortPredicateArray.indexOf(dataset.label)
  );

  const lineChartDataPoints = get(tempDatasets, "0.data", []);

  datasets.push({
    label: `Target ${chartType}`,
    data: lineChartDataPoints.map(() => tetTarget),
    borderColor: "#DFACAC",
    pointBorderColor: "#BE5858",
    pointBackgroundColor: "#BE5858",
    pointHoverBackgroundColor: "#B94A4A",
    pointHoverBorderColor: "#B94A4A",
    backgroundColor: "transparent",
    pointRadius: 1,
    // Changes this dataset to become a line
    type: "line",
    order: 1,
  });

  return {
    labels: labels,
    datasets: datasets,
  };
};
