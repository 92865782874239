import React from "react";

const AlertWarning = ({
  showBadge = true,
  badgeText = "Warning",
  alertText = "Warning",
  className = "",
} = {}) => {
  return (
    <div
      className={`bg-yellow-300 w-full text-center py-4 lg:px-4 ${className}`}
    >
      <div
        className="bg-yellow-400 text-yellow-800 p-2 items-center leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        {showBadge ? (
          <span className="bg-yellow-500 flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3">
            {badgeText}
          </span>
        ) : null}
        <span className="font-semibold mr-2 text-left flex-auto">
          {alertText}
        </span>
      </div>
    </div>
  );
};

export default AlertWarning;
