import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import fingermark_logo from "../../assets/images/fingermark_logo.png";
import {
  PresentationChartBarIcon as PresentationChartBarOutline,
  ClockIcon as ClockOutline,
  ChartBarIcon as ChartBarOutline,
  TemplateIcon as TemplateOutline,
  IdentificationIcon as IdentificationOutline,
} from "@heroicons/react/outline";


import { useAuthorized } from "../../auth";

const primaryNavBarClassList = [
  "group",
  "flex",
  "items-center",
  "px-2",
  "py-2",
  "text-sm",
  "leading-5",
  "font-medium",
  "text-gray-300",
  "rounded-md",
  "hover:outline-none",
  "hover:bg-gray-700",
  "transition",
  "ease-in-out duration-150",
];
const primaryNavBarActiveClassList = [...primaryNavBarClassList, "text-green-500", "bg-gray-900"];
const secondaryNavBarClassList = [
  "group",
  "flex",
  "items-center",
  "px-2",
  "pl-8",
  "py-2",
  "text-sm",
  "leading-5",
  "font-medium",
  "rounded-md",
  "text-gray-300",
  "hover:outline-none",
  "hover:bg-gray-700",
  "transition",
  "ease-in-out duration-150",
]

const secondaryNavBarActiveClassList = [
  ...secondaryNavBarClassList,
  "text-green-500"
];

const DataboardSubMenu = () => {
  const restrictedManager = useAuthorized("restrictedManager");
  const superManager = useAuthorized("superManager");

  return (
    <>
      {!superManager && restrictedManager && (
        <NavLink
          to="/databoard/portfolio"
          className={(navData) => navData.isActive? secondaryNavBarActiveClassList.join(" ") 
                        : secondaryNavBarClassList.join(" ")}>
          <ChartBarOutline className="h-5 w-5 mr-3 " />
          Portfolio
        </NavLink>
      )}
      {superManager && (
        <NavLink
          className={(navData) => navData.isActive? secondaryNavBarActiveClassList.join(" ")
            : secondaryNavBarClassList.join(" ")}
              to="/databoard/leaderboard">
          <IdentificationOutline className="h-5 w-5 mr-3 " />
          Leaderboard
        </NavLink>
      )}

      <NavLink
        to="/databoard/restaurant-overview"
        className={(navData) => navData.isActive ? secondaryNavBarActiveClassList.join(" ")
                                : secondaryNavBarClassList.join(" ")}>
        <TemplateOutline className="h-5 w-5 mr-3 " />
        Restaurant Overview
      </NavLink>

      <NavLink
        to="/databoard/shift-report"
        className={(navData) => navData.isActive? secondaryNavBarActiveClassList.join(" ")
                                  : secondaryNavBarClassList.join(" ")}>
        <ClockOutline className="h-5 w-5 mr-3 " />
        Shift Report
      </NavLink>
    </>
  );
};

const RegularSidebar = () => {
  const { pathname } = useLocation();
  const isSuperManager = useAuthorized("superManager");
  const isRestrictedManager = useAuthorized("restrictedManager");
  return (
    <div className="flex flex-col w-64">
      <div className="flex items-center flex-shrink-0 px-5 py-5 bg-gray-800">
        <img className="w-auto" src={fingermark_logo} alt="Fingermark" />
      </div>

      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
        <nav className="flex-1 px-2 py-4 bg-gray-800">
          <NavLink
            to={
              isSuperManager
                ? "/databoard/leaderboard"
                : isRestrictedManager
                ? "/databoard/portfolio"
                : "/databoard/restaurant-overview"
            }
            className={primaryNavBarActiveClassList.join(" ")}>
            <PresentationChartBarOutline className="h-6 w-6 mr-3 " />
            Databoard
          </NavLink>
          {pathname.startsWith("/databoard") ? <DataboardSubMenu /> : null}
        </nav>
      </div>
    </div>
  );
};

export default RegularSidebar;
