// import { Auth } from "aws-amplify";
import { useRecoilState } from "recoil";
import { currentUser } from "../recoil/atoms";
import { get } from "lodash";

export const useAuthorized = (role) => {
  const [loggedInUser] = useRecoilState(currentUser);
  const accessGroups = get(loggedInUser, "accessGroups", null);

  if (!accessGroups) {
    return false;
  }

  switch (role) {
    case "admin":
      return accessGroups.some((group) => ["databoardAdmin"].includes(group));
    case "superManager":
      return accessGroups.some((group) =>
        ["databoardAdmin", "databoardSuperManager"].includes(group)
      );
    case "restrictedManager":
      return accessGroups.some((group) =>
        [
          "databoardAdmin",
          "databoardSuperManager",
          "databoardRestrictedManager",
        ].includes(group)
      );
    default:
      return false;
  }
};
