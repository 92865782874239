import React, { useReducer, useEffect, useState } from "react";
import { format, addMinutes } from "date-fns";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  queriedTimeFrame,
  selectedSite,
  currentUser,
  selectedOrganization,
} from "../../recoil/atoms";
import { useNavigate } from "react-router-dom";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { get } from "lodash";
import { sortData, getActiveClasses } from "../../utils/leaderboardHelper";
import {
  TableHeaderCell,
  TableRowAdvance as TableRow,
  TableCell,
} from "../Table";

const LeaderboardPerformanceTable = ({
  loading = true,
  data,
  timeframe = null,
}) => {

  const authObj = useRecoilValue(currentUser);
  const { transition, getActiveFieldName } = FiniteStateMachine;
  const [, setSite] = useRecoilState(selectedSite);
  const organization = useRecoilValue(selectedOrganization);
  const [, setCurrentQueriedTimeframe] =
    useRecoilState(queriedTimeFrame);
  if (!timeframe) {
    throw new Error(
      `Invalid 'timeframe' passed to 'LeaderboardPerformanceTable'`
    );
  }
  const history = useNavigate();
  const [currentPerformanceTable, setCurrentPerformanceTable] = useReducer(
    transition,
    {
      sortByStoreName: false,
      sortByOEPE: true,
      sortByTET: false,
      sortByVehicleCount: false,
      sortByPeak15Count: false,
    }
  );

  const activeCurrentPerformanceTable = getActiveFieldName(currentPerformanceTable)();

  const [activeHeaderColumnClasses, setActiveHeaderColumnClasses] = useState({});
  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    
    if(loading) return;

    const sorted = sortData(
      data.filter(store => store.vehicle_count > 0),
      activeCurrentPerformanceTable
    );
    setFilteredData(sorted);

    const activeClasses = getActiveClasses(
      activeCurrentPerformanceTable
    );

    setActiveHeaderColumnClasses(activeClasses);
  }, [loading, data, activeCurrentPerformanceTable]);
  
  const clickableThClassname = ["cursor-pointer", "hover:text-blue-300"];

  const ownStoreRowClasses = [
    "opacity-100",
    "hover:cursor-pointer hover:bg-blue-100",
  ];

  const allowedSites = get(authObj, "allowedSites", []);

  if (!allowedSites.includes("*")) {
    ownStoreRowClasses.push("shadow-centeredxl");
  }

  const onRowClickHandler = (site) => {
    console.log('setting site:', site);
    setSite(site);
    setCurrentQueriedTimeframe(timeframe);
    history("/databoard/restaurant-overview");
  };

  return (
    <table className="leaderboard-table table-auto">
      <thead>
        <tr>
          {/* {headers.map((header, key) => ( */}
          <TableHeaderCell>Rank</TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.storeNameColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPerformanceTable("sortByStoreName")}
          >
            Store Name
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.oepeRankColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPerformanceTable("sortByOEPE")}
          >
            {`${organization.oepeLabel} Ranking`}
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.tetRankColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPerformanceTable("sortByTET")}
          >
            {`${organization.tetLabel} Ranking`}
          </TableHeaderCell>
          <TableHeaderCell
            className={clickableThClassname
              .concat(activeHeaderColumnClasses.vehicleCountColumnHeaderClasses)
              .join(" ")}
            onClick={() => setCurrentPerformanceTable("sortByVehicleCount")}
          >
            Vehicle Count
          </TableHeaderCell>
          {organization.id === "mcd" && (
            <>
              <TableHeaderCell>Peak 15 at</TableHeaderCell>
              <TableHeaderCell
                className={clickableThClassname
                  .concat(
                    activeHeaderColumnClasses.peak15CountColumnHeaderClasses
                  )
                  .join(" ")}
                onClick={() => setCurrentPerformanceTable("sortByPeak15Count")}
              >
                Peak 15 Car Count
              </TableHeaderCell>
            </>
          )}

          {(organization.id === "kfc" ||
            organization.id === "kfc_uae" ||
            organization.id === "hrd") && (
            <TableHeaderCell
            // className={clickableThClassname
            //   .concat(activeHeaderColumnClasses.peak15CountColumnHeaderClasses)
            //   .join(" ")}
            // onClick={() => setCurrentPortfolioTable("sortByPeak15Count")}
            >
              Thru-put
            </TableHeaderCell>
          )}
        </tr>
      </thead>
      <tbody>
        {filteredData ? (
          filteredData.map((store, i) => {
            const peak15Hour = store.peak_15_hour
              ? `${format(
                  new Date(parseInt(store.peak_15_hour)),
                  "yyyy-MM-dd ( h:mm"
                )} - ${format(
                  addMinutes(new Date(parseInt(store.peak_15_hour)), 15),
                  "h:mm a)"
                )}`
              : ""; //returns a string in this format, yyyy-MM-dd ( h:mm - h:mm a)

            return (
              <TableRow
                key={i}
                index={i + 1}
                className={
                  allowedSites.includes(store.site.id) ||
                  allowedSites.includes("*")
                    ? ownStoreRowClasses.join(" ")
                    : ""
                }
                onClick={
                  allowedSites.includes(store.site.id) ||
                  allowedSites.includes("*")
                    ? () => {
                        onRowClickHandler(store.site);
                      }
                    : null
                }
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell>{store.site.name}</TableCell>
                <TableCell className="text-center">
                  {store.oepe_ranking}
                </TableCell>
                <TableCell className="text-center">
                  {" "}
                  {store.tet_ranking}{" "}
                </TableCell>
                <TableCell className="text-center">
                  <span className="text-sm text-gray-900">
                    {store.vehicle_count}
                  </span>
                </TableCell>
                {organization.id === "mcd" && (
                  <>
                    <TableCell>{peak15Hour}</TableCell>
                    <TableCell className="text-center">
                      {" "}
                      {store.peak_15_count}{" "}
                    </TableCell>
                  </>
                )}
                {(organization.id === "kfc" ||
                  organization.id === "kfc_uae" ||
                  organization.id === "hrd") && (
                  <TableCell className="text-center">
                    {" "}
                    {store.thruput}{" "}
                  </TableCell>
                )}
              </TableRow>
            );
          })
        ) : (
          <TableRow index={1}>
            {[...Array(7)].map((item, key) => (
              <TableCell key={key}>
                <span className="skeleton-box h-3 w-full inline-block"></span>
              </TableCell>
            ))}
          </TableRow>
        )}
      </tbody>
    </table>
  );
};

export default LeaderboardPerformanceTable;
