import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import { useStores } from "../../graphql/queries";
import { sortBy } from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedSite,
  currentUser,
  selectedOrganization,
} from "../../recoil/atoms";
import { get } from "lodash";

const StorePicker = ({ toggleModal }) => {
  const authObj = useRecoilValue(currentUser);
  const organization = useRecoilValue(selectedOrganization);
  const [site, setSite] = useRecoilState(selectedSite);
  const [siteId, setSiteId] = useState(get(site, "id", 0));

  const { data, error, loading } = useStores({
    site_id_list: get(authObj, "allowedSites", null).map((site) => `${site}`),
    organization: get(organization, "id", null),
  });

  useEffect(() => {
    console.log('stores', {data});
  }, [data]);

  useEffect(() => {
    if (get(data, "getStores", []).length === 1) {
      setSite(data.getStores[0]);
      toggleModal(false);
      console.log('set site successfull', { data });
    }  
  }, [ data, setSite, toggleModal ]);

  const onStoreSelect = () => {
    const selectedSiteObject = get(data, "getStores", []).find(
      (store) => store.id === parseInt(siteId)
    );

    setSite(selectedSiteObject);
    toggleModal(false);
  };
  return (
    <div>
      <Modal
        toggleModal={toggleModal}
        //    savedData={saveData}
        saveDataHandler={onStoreSelect}
        successButtonText={"Select"}
        saveLoading={loading}
        saveError={error}
      >
        <div className="bg-white p-6 ">
          <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
            Select a store
          </h2>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody>
              <tr>
                <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                  <label
                    htmlFor={`from-time-`}
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Store
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <select
                      id={`from-time-`}
                      // value={siteId}
                      onChange={(e) => {
                        setSiteId(e.target.value);
                      }}
                      className="rounded block w-full border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                      <option key={"default-value"} value={null}>
                        -- Select a Store --
                      </option>
                      {sortBy(get(data, "getStores", []), ["name"]).map(
                        ({ id, name }, i) => (
                          <option key={`${id}-${name}`} value={id}>
                            {name.trim()}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default StorePicker;
