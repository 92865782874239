import React from "react";
import {
  SearchIcon as SearchOutline,
} from "@heroicons/react/outline";
import {
  ChevronRightIcon as ChevronRightSolid,
  DotsVerticalIcon as DotsVerticalSolid,
} from "@heroicons/react/solid";
import ArrowUp from "../../assets/images/icons/arrow-up.svg";
import ArrowDown from "../../assets/images/icons/arrow-down.svg";

const TableHeaderCell = ({ className, children } = {}) => (
  <th
    className={`px-6 py-3 border-b border-gray-100 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider ${className}`}
  >
    {children}
  </th>
);
const TableRow = ({ index, children }) => (
  <tr className={`${index % 2 ? "bg-gray-200" : ""}`}>{children}</tr>
);
const TableCell = ({ className, children }) => (
  <td
    className={`px-6 py-4 whitespace-nowrap border-b border-gray-100 ${className}`}
  >
    {children}
  </td>
);

const StoreTable = ({ storeData = [] } = {}) => {
  return (
    <div>
      <div className="w-full bg-white rounded-lg shadow overflow-x-auto">
        <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="">
              <h3 className="text-lg leading-6 font-medium text-gray-900 whitespace-nowrap mr-8">
                Job Postings
              </h3>
            </div>
            <div className="w-full flex md:ml-0 mr-2">
              <label htmlFor="search_field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchOutline className="h-5 w-5" />
                </div>
                <input
                  id="search_field"
                  className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>

            <div className="flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  Stores
                  <ChevronRightSolid className="h-6 w-6 -mr-1 ml-2 text-gray-500" />
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="align-middle inline-block min-w-full overflow-hidden border-gray-100">
          <table className="min-w-full">
            <thead>
              <tr>
                <TableHeaderCell>Store</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell className="tracking-wider text-right">
                  Avg Session Time
                </TableHeaderCell>
                <TableHeaderCell className="tracking-wider text-right">
                  Avg Spend
                </TableHeaderCell>
                <TableHeaderCell className="tracking-wider text-right">
                  Weekly Total
                </TableHeaderCell>
                <TableHeaderCell className="tracking-wider text-right">
                  <span className="flex flex-nowrap items-center justify-end">
                    $ Per Minute
                    <ArrowDown className="w-5 h-5 self-center flex-shrink-0 text-gray-500" />
                  </span>
                </TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </tr>
            </thead>
            <tbody className="bg-white">
              {storeData.map((store, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <div className="text-sm leading-5 font-medium text-blue-500">
                        {store.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      {store.status === 1 ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-500">
                          All Active
                        </span>
                      ) : store.status === 2 ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-500">
                          Some Inactive
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-500">
                          Offline
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex items-baseline flex-nowrap justify-end">
                        <span className="text-sm text-gray-800">
                          {store.avg_session_time}
                        </span>
                        <span className="text-xs text-gray-400">min</span>
                      </div>
                    </TableCell>
                    <TableCell className="text-sm leading-5 text-gray-500 text-right">
                      <span className="text-sm text-gray-800">
                        {store.avg_spend}
                      </span>
                    </TableCell>
                    <TableCell className="text-sm leading-5 text-gray-500 text-right">
                      <span className="text-sm text-gray-800">
                        {store.weekly_total}
                      </span>
                    </TableCell>
                    <TableCell className="text-sm leading-5 text-gray-500 text-right">
                      <span className="flex items-baseline flex-nowrap justify-end">
                        <span className="text-sm text-gray-900">
                          {store.per_minute}
                        </span>
                        <span className="text-xs text-gray-500">/min</span>
                        {store.per_minute_diff_by > 0 ? (
                          <ArrowUp className="w-5 h-5 self-center flex-shrink-0 text-green-500" />
                        ) : (
                          <ArrowDown className="w-5 h-5 self-center flex-shrink-0 text-red-500" />
                        )}
                      </span>
                    </TableCell>
                    <TableCell className="text-sm leading-5 text-gray-500 text-right">
                      <button
                        className="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500"
                        aria-label="Notifications"
                      >
                        <DotsVerticalSolid className="w-5 h-5 text-gray-500" />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-5 sm:px-6">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="">
              <p className="text-sm leading-6 text-gray-800 whitespace-nowrap mr-8">
                Showing <span className="font-medium">1</span> to{" "}
                <span className="font-medium">5</span> of{" "}
                <span className="font-medium">97</span>
                results
              </p>
            </div>

            <div className="flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  Load All
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreTable;
