import React, { useRef } from "react";
import { CogIcon as CogSolid } from "@heroicons/react/solid";
import ShiftCustomiser from "../ShiftCustomiser";
import AlertError from "../AlertError";
import { format, parse, formatRelative } from "date-fns";
import ShiftReportStatBoxes from "../ShiftReportStatBoxes";
import ShiftReportChartGrid from "../ShiftReportChartGrid";
import { get } from "lodash";
import CogWheelMenu from "../CogWheelMenu";
import StorePicker from "../StorePicker";
import { useClickedOutside, getError } from "../../utils";

const ShiftReport = ({
  site = null,
  showStorePicker = false,
  setShowStorePicker = null,
  showShiftCustomiser = null,
  setShowShiftCustomiser = null,
  setMenuToggle = null,
  menuToggle = {},
  timeframe = null,
  loading = false,
  menuItems = [],
  error = null,
  loadingSiteConfigs = false,
  dataSiteConfigs = {},
  redrawId = "",
  tetData = [],
  chartData = [],
} = {}) => {
  const wrapperRef = useRef(null);

  const startDateTime = parse(timeframe.startTime, "HH:mm:ss", new Date());
  const endDateTime = parse(timeframe.endTime, "HH:mm:ss", new Date());

  useClickedOutside(wrapperRef, () => {
    setMenuToggle("menuClosed");
  });

  return (
    <div className="flex flex-col w-0 flex-1  lg:mt-0 mt-10">
      <div className="flex overflow-y-auto flex-grow">
        {(!site || showStorePicker) && (
          <StorePicker toggleModal={setShowStorePicker} />
        )}
        <main className="flex-1 relative z-0 overflow-y-scroll py-6 focus:outline-none">
          {showShiftCustomiser ? (
            <ShiftCustomiser setShiftCustomiser={setShowShiftCustomiser} />
          ) : null}

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 flex  justify-between flex-wrap flex-col">
            <h1 className="page-header text-2xl font-semibold text-gray-900 inline flex items-end">
              Shift Report
              <div className="relative" ref={wrapperRef}>
                <button
                  onClick={() => setMenuToggle("menuOpen")}
                  className="options-menu-button inline-flex mb-2 ml-2 col-span-1"
                >
                  <CogSolid className="h-5 w-5 text-gray-500 flex items-center content-center" />
                </button>

                {menuToggle.menuOpen && (
                  <CogWheelMenu
                    className="options-menu"
                    loadingMenuOptions={loading}
                    setMenuToggle={() => setMenuToggle("menuClosed")}
                    menuItems={menuItems}
                  />
                )}
              </div>
            </h1>
            <h2 className="queried-time-frame text-lg text-gray-700">
              {`${get(site, "name", "").trim()}`}{" "}
              {startDateTime && endDateTime && (
                <span className="text-sm text-gray-500">{`(${formatRelative(
                  startDateTime,
                  new Date()
                )} - ${format(endDateTime, "hh:mm a")})`}</span>
              )}
            </h2>
          </div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
            {error ? (
              <div className="rounded-md overflow-hidden w-full mb-3">
                <AlertError alertText={`${getError(error)}`} />
              </div>
            ) : null}
            <ShiftReportStatBoxes
              loadingSiteConfigs={loadingSiteConfigs}
              dataSiteConfigs={dataSiteConfigs}
            />
          </div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
            <div className="w-full grid xl:grid-cols-2 gap-4">
              <ShiftReportChartGrid
                dataSiteConfigs={dataSiteConfigs}
                loadingSiteConfigs={loadingSiteConfigs}
                redrawId={redrawId}
                tetData={tetData}
                chartData={chartData}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ShiftReport;
