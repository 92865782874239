import React from "react";
import {
  UserGroupIcon as UserGroupOutline,
  CursorClickIcon as CursorClickOutline,
  MailOpenIcon as MailOpenOutline,
} from "@heroicons/react/outline";
import TrendUp from "../../assets/images/icons/trend-up.svg";
import TrendDown from "../../assets/images/icons/trend-down.svg";
import ArrowUp from "../../assets/images/icons/arrow-up.svg";
import ArrowDown from "../../assets/images/icons/arrow-down.svg";

const AlertIcon = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <div className="flex-shrink-0 bg-green-500 rounded-md p-3">
          <CursorClickOutline className="h-6 w-6 text-white" />
        </div>
      );
    case 2:
      return (
        <div className="flex-shrink-0 bg-yellow-500 rounded-md p-3">
          <MailOpenOutline className="h-6 w-6 text-white" />
        </div>
      );
    case 3:
      return (
        <div className="flex-shrink-0 bg-red-500 rounded-md p-3">
          <UserGroupOutline className="h-6 w-6 text-white" />
        </div>
      );
    default:
      return <></>;
  }
};

const TrendIcon = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <TrendUp
          className="stroke-2 text-green-500 stroke-current "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 2:
      return (
        <TrendDown
          className="stroke-2 text-yellow-500 stroke-current"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    case 3:
      return (
        <TrendUp
          className="stroke-2 text-red-500 stroke-current"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      );
    default:
      return <></>;
  }
};

const AlertCard = ({
  alert = { status: 1, value: 2, diffBy: 2, title: "Alerts", subTitle: null },
  diffByText = "last hour",
  showAlertIcon = true,
  viewAll = () => {},
  className = "",
  showTrendLine = false,
  showDifference = false,
  show = true,
} = {}) => {
  if (!show) {
    return <></>;
  }
  return (
    <div
      className={`bg-white overflow-hidden shadow rounded-lg flex flex-col flex-1 ${className}`}
    >
      <div className="px-4 py-5 sm:p-6 flex-grow">
        <div className="flex items-center">
          {showAlertIcon ? <AlertIcon status={alert.status} /> : null}

          <div className="w-0 flex-1">
            <dl className="flex flex-col items-center">
              <dt className="text-lg leading-5 font-medium text-gray-500 truncate flex flex-nowrap justify-between mb-2">
                {alert.title}
                {showTrendLine && <TrendIcon status={alert.status} />}
              </dt>
              <dd className="flex flex-wrap flex-col items-center">
                <div className="text-4xl leading-8 font-semibold text-gray-900">
                  {alert.value}
                </div>
                {alert.subTitle && (
                  <div className="mt-2 text-smleading-8 font-semibold text-gray-500">
                    {alert.subTitle}
                  </div>
                )}
                {showDifference && (
                  <>
                    {alert.diffBy > 0 ? (
                      <div className="flex items-baseline text-sm leading-5 font-semibold text-green-600 whitespace-nowrap">
                        <ArrowUp className="self-center flex-shrink-0 h-5 w-5 text-green-500" />
                        <span className="sr-only">Increased by</span>
                        {Math.abs(alert.diffBy)} {diffByText}
                      </div>
                    ) : (
                      <div className="flex items-baseline text-sm leading-5 font-semibold text-red-600 whitespace-nowrap">
                        <ArrowDown className="self-center flex-shrink-0 h-5 w-5 text-red-500" />
                        <span className="sr-only">Decreased by</span>
                        {Math.abs(alert.diffBy)} {diffByText}
                      </div>
                    )}
                  </>
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {viewAll ? (
        <div className="bg-gray-100 px-4 py-4 sm:px-6">
          <div className="text-sm leading-5">
            <button
              className="font-medium text-blue-500 hover:text-blue-400 transition ease-in-out duration-150"
              onClick={() => viewAll}
            >
              View all
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AlertCard;
