import React, { useState, useReducer, useRef, useEffect } from "react";
import { subDays, formatRelative } from "date-fns";
import AlertError from "../AlertError";
import LeaderboardPerformanceTable from "../LeaderboardPerformanceTable";
import { usePerformancesData } from "../../graphql/queries/portfolio";
import { CogIcon as CogSolid } from "@heroicons/react/solid";
import {
  setLastNWeeksTimeFrame,
  setLastNDaysTimeFrame,
} from "../../utils/portfolioHelper";
import { tourSteps } from "../../utils/leaderboardHelper";
import CogWheelMenu from "../CogWheelMenu";
import { get } from "lodash";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { useClickedOutside } from "../../utils";
import { selectedOrganization, currentUser } from "../../recoil/atoms";
import { loggedInUserLatest } from "../../recoil/selectors";
import { useRecoilValue, useRecoilState } from "recoil";
import { Walktour } from 'walktour';

const LeaderboardPage = () => {
  const [, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const [authAttribute] = useRecoilState(currentUser);
  const organization = useRecoilValue(selectedOrganization);
  const cogWheelMenuRef = useRef(null);
  const { transition } = FiniteStateMachine;
  const [tour, setTour] = useState(!authAttribute.leaderboardTutorial);
  const [timeframe, setTimeFrame] = useState({
    startDate: subDays(new Date(), 1),
    endDate: new Date(),
  });
  useEffect(() => {
    setTour(!authAttribute.leaderboardTutorial);
  }, [authAttribute]);

  const [currentPageState, setCurrentPageState] = useReducer(transition, {
    menuOpen: false,
    menuClosed: true,
  });
  const args = {
    siteIdList: authAttribute.allowedSites,
    startDate: timeframe.startDate,
    endDate: timeframe.endDate,
    get_all: true,
    organization: get(organization, "id", null),
  };
  const { loading, error, data } = usePerformancesData(args);

  const [getPerformances, setPerformancesData] = useState([]);
  useEffect(() => {
    if(loading) return;
    setPerformancesData([...get(data, "getPerformances", [])]);
  }, [loading, data]);

  useClickedOutside(cogWheelMenuRef, () => setCurrentPageState("menuClosed"));
  const menuItems = [
    {
      text: "Last 24 hours",
      onClick: () => setLastNDaysTimeFrame(1, setTimeFrame),
    },
    {
      text: "Last 48 hours",
      onClick: () => setLastNDaysTimeFrame(2, setTimeFrame),
    },
    {
      text: "Last Week",
      onClick: () => setLastNWeeksTimeFrame(1, setTimeFrame),
    },
    {
      text: "Last 2 Weeks",
      onClick: () => setLastNWeeksTimeFrame(2, setTimeFrame),
    },
  ];

  const relativeDateText =
    `Since ` + formatRelative(timeframe.startDate, timeframe.endDate);
  return (
    <>
      <div className="flex flex-col w-0 flex-1 lg:mt-0 mt-10">
        <div className="flex overflow-y-auto flex-grow">
          <main className="flex-1 relative z-0 overflow-y-scroll py-6 focus:outline-none">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col  justify-between flex-wrap mb-1">
              <h1 className="first-step text-2xl font-semibold text-gray-900 inline flex items-end">
                Leader board
                <div className="relative" ref={cogWheelMenuRef}>
                  <button
                    onClick={() => setCurrentPageState("menuOpen")}
                    className="options-menu inline-flex mb-2 ml-2"
                  >
                    <CogSolid className="h-5 w-5 text-gray-500 flex items-center content-center" />
                  </button>
                  {currentPageState.menuOpen && (
                    <CogWheelMenu
                      setMenuToggle={() => {
                        setCurrentPageState("menuClosed");
                      }}
                      menuItems={menuItems}
                    />
                  )}
                </div>
              </h1>
              <h2 className="text-lg text-gray-700">
                <span className="text-sm text-gray-500">
                  {relativeDateText}
                </span>
              </h2>
            </div>

            <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
              <div className="bg-white rounded-lg overflow-hidden">
                {error ? (
                  <AlertError alertText="Error loading the data for the queried time frame" />
                ) : null}

                <LeaderboardPerformanceTable
                  loading={loading}
                  data={getPerformances}
                  timeframe={timeframe}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Walktour
        steps={tourSteps}
      />      
    </>
  );
};
export default LeaderboardPage;
