import React from "react";
import ActivityFeedItem from "./ActivityFeedItem";

const ActivityFeed = ({ activityFeed = [] }) => {
  return (
    <div className="">
      <div className="flex-col w-64 bg-white hidden xl:flex overflow-y-auto max-h-full">
        <h2 className="text-base font-medium text-gray-900 p-6 pb-3">
          Activity Feed
        </h2>

        <h3 className="text-sm font-medium text-gray-900 p-6 pt-3">Today</h3>
        {activityFeed
          .filter((activity) => !activity.old)
          .map((activity, i) => {
            return (
              <ActivityFeedItem
                key={i}
                time={activity.time}
                device={activity.device}
                location={activity.location}
                old={activity.old}
                status={activity.status}
              />
            );
          })}

        <h3 className="text-sm font-medium text-gray-900 p-6 pt-6">
          Yesterday
        </h3>

        {activityFeed
          .filter((activity) => activity.old)
          .map((activity, i) => {
            return (
              <ActivityFeedItem
                key={i}
                time={activity.time}
                device={activity.device}
                location={activity.location}
                old={activity.old}
                status={activity.status}
              />
            );
          })}
      </div>
    </div>
  );
};
export default ActivityFeed;
