import React from "react";
import ReportScheduleRow from "../ReportScheduleRow";
import { UserAddIcon as UserAdd } from "@heroicons/react/outline";

const ReportFrequencies = ({
  reportSchedules = [],
  users = [],
  onReportScheduleChange = null,
  onDeleteReportSchedule = null,
  setNewUserModalHandler = null,
  setNewReportFrequencyModalHandler = null,
} = {}) => {
  if (!onReportScheduleChange) {
    throw new Error(
      `Invalid 'onReportScheduleChange' passed to 'ReportFrequencies'`
    );
  }
  if (!onDeleteReportSchedule) {
    throw new Error(
      `Invalid 'onDeleteReportSchedule' passed to 'ReportFrequencies'`
    );
  }
  if (!setNewUserModalHandler) {
    throw new Error(
      `Invalid 'setNewUserModalHandler' passed to 'ReportFrequencies'`
    );
  }
  if (!setNewReportFrequencyModalHandler) {
    throw new Error(
      `Invalid 'setNewReportFrequencyModalHandler' passed to 'ReportFrequencies'`
    );
  }

  return (
    <>
      <div className="w-full border-t border-gray-200 mb-6"></div>
      <div className="flex justify-between mb-1">
        <h2 className="text-base font-semibold text-gray-900 mb-4">
          Scheduled Reports
        </h2>
        <div className="flex-shrink-0">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="add-reporting-user px-4 py-2 mr-1 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              onClick={() => setNewUserModalHandler(true)}
            >
              <UserAdd className="w-5 h-5" />
            </button>
            <button
              type="button"
              onClick={() => setNewReportFrequencyModalHandler(true)}
              className="add-scheduled-report px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              Add a report schedule
            </button>
          </span>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <tbody>
          {reportSchedules.map((reportSchedule, i) => {
            return (
              <ReportScheduleRow
                key={reportSchedule.uuid}
                users={users}
                {...reportSchedule}
                onReportScheduleChange={onReportScheduleChange}
                onDeleteReportSchedule={onDeleteReportSchedule}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ReportFrequencies;
