import distinctColors from "distinct-colors";
import { format } from "date-fns";

export const getPortfolioChartData = ({
  dataArray = [],
  chartType = "tet_chart",
} = {}) => {
  const pallet = distinctColors({
    count: dataArray.length,
  });

  const labels = [];

  dataArray.map((store, key) => {
    const tempLabels = [];
    if (!store.data) return {};

    store.data.map((day, i) => {
      tempLabels.push(format(new Date(parseInt(day.date)), "yyyy-MM-dd"));
    });

    if (labels.length < tempLabels.length) {
      labels.splice(0, labels.length, ...tempLabels);
    }
  });

  const data = new Map();

  labels.map((label) => data.set(label, null));

  const dataset = dataArray.map((store, key) => {
    var dataMap = new Map(data);

    if (!store.data) return {};

    store.data.map((day, i) => {
      const label = format(new Date(parseInt(day.date)), "yyyy-MM-dd");

      if (chartType === "tet_chart") {
        return dataMap.set(label, day.tet_sum / day.car_counts);
      } else if (chartType === "oepe_chart") {
        return dataMap.set(label, day.oepe_sum / day.car_counts);
      } else if (chartType === "car_count_chart") {
        return dataMap.set(label, day.car_counts);
      }
      return 0;
    });

    return {
      label: store.site.name.trim(),
      fill: false,
      lineTension: 0.1,
      backgroundColor: pallet[key].hex(),
      borderColor: pallet[key].hex(),
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [...dataMap.values()],
    };
  });

  return {
    labels: labels,
    datasets: dataset,
  };
};
