import { InMemoryCache, ApolloClient, createHttpLink, ApolloProvider } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import React, { useState, useEffect } from "react";
import { useAuth0 as useAuth } from "@auth0/auth0-react";

const ApolloWrapper = ({ children }) => {
  const [bearerToken, setBearerToken] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth();

  useEffect(() => {
    const getToken = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : "";
      setBearerToken(token);
    };

    getToken();
  }, [bearerToken, isAuthenticated]);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_DATABOARD_GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!bearerToken) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${bearerToken}`,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
