import { format } from "date-fns";
import { get } from "lodash";
import { Parser } from '@json2csv/plainjs';
export const getCommonChartData = ({
  dataObject = {
    data: [],
    labels: [],
  },
  chartLabelSensitivity = "daily",
  chartType = "tet_chart",
  oepeTaget = 120,
} = {}) => {
  const chartData = { label: "", data: [] };

  chartData.data = get(dataObject, "data", []);
  // const labels = get(dataObject, "labels", []).map((date) =>
  //   format(new Date(date), "yyyy-MM-dd")
  // );
  const labels = get(dataObject, "labels", []).map((label) => {
    // if(chartLabelSensitivity){
    //   return getFormattedDateLabel(label, chartLabelSensitivity)
    // }
    return label;
  });

  const lineChartDataPoints = get(chartData, "data", []);
  switch (chartType) {
    case "lane_utilization":
      chartData.label = "Vehicle Count";
      break;
    case "tet_chart":
      chartData.label = "TET";
      break;
    case "oepe_chart":
      chartData.label = "OEPE";
      break;
    case "car_count_chart":
      chartData.label = "Successful journey count";
      break;
    default:
      chartData.label = chartType;
      break;
  }
  const datasets = [];
  datasets.push({
    label: chartData.label,
    fill: false,
    lineTension: 0.1,
    backgroundColor: "#D1EEEE",
    borderColor: "#C0D9D9",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "rgba(75,192,192,1)",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "rgba(75,192,192,1)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: chartData.data,
    type: "bar",
    order: 2,
  });
  datasets.push({
    label: `Target ${chartType}`,
    data: lineChartDataPoints.map(() => oepeTaget),
    borderColor: "#DFACAC",
    pointBorderColor: "#BE5858",
    pointBackgroundColor: "#BE5858",
    pointHoverBackgroundColor: "#B94A4A",
    pointHoverBorderColor: "#B94A4A",
    backgroundColor: "transparent",
    pointRadius: 1,
    // Changes this dataset to become a line
    type: "line",
    order: 1,
  });

  return {
    labels: labels,
    datasets: datasets,
  };
};

export const downloadCsv = (
  currentQueriedData,
  sensitivity,
  show_old_oepe = false,
  site = {},
  organization = {
    oepeLabel: "Customer journey time",
    tetLabel: "Total journey time",
  }
) => {
  if (!organization.id) {
    throw Error("Organization id is required");
  }

  const oepeLabel = organization.oepeLabel;
  const tetLabel = organization.tetLabel;
  const oepeColumnName = show_old_oepe ? "Old " + oepeLabel : oepeLabel;
  const siteName = site.name.trim();
  const fingermarkId = site.fingermark_id;
  let csvArray = currentQueriedData.pivot_data.map((row, i) => {
    let singleRow = {
      "Date Hour": row.date,
      "Number of Vehicles": row.car_counts,
      "Start secs": currentQueriedData.pulse.start.data[i],
      "Before Order secs": currentQueriedData.pulse.before_order.data[i],
      "Order secs": row.avg_order,
      "Before Payment secs": currentQueriedData.pulse.before_payment.data[i],
      "Payment secs": row.avg_payment,
      "Before Deliver secs": currentQueriedData.pulse.before_deliver.data[i],
      "Deliver secs": row.avg_deliver,
      [oepeColumnName]: show_old_oepe ? row.avg_old_oepe : row.avg_oepe,
      [oepeColumnName + "%"]: show_old_oepe
        ? null
        : row.above_target_percentage,
      [tetLabel]: row.avg_tet,
      "Wait secs": row.avg_waiting_bay,
      "Number of Vehicles sent to Wait Bay":
        currentQueriedData.pulse.waiting_bay.counts[i],
      "Wait Bay %": row.car_counts
        ? Math.round(
            (currentQueriedData.pulse.waiting_bay.counts[i] / row.car_counts) *
              100 *
              10
          ) / 10
        : 0,
      "Pull Forward secs": currentQueriedData.pulse.pull_forward.data[i],
      "Pull Forward count": currentQueriedData.pulse.pull_forward.counts[i],
      "Pull Forward %": row.car_counts
        ? Math.round(
            (currentQueriedData.pulse.pull_forward.counts[i] / row.car_counts) *
              100 *
              10
          ) / 10
        : 0,
      "Pull Out count": currentQueriedData.pulse.pull_out.counts[i],
      "Pull Out %": row.car_counts
        ? Math.round(
            (currentQueriedData.pulse.pull_out.counts[i] / row.car_counts) *
              100 *
              10
          ) / 10
        : 0,
    };

    if (organization.id.match(/kfc/g) || organization.id.match(/hrd/g)) {
      if (sensitivity == "eachCar" || sensitivity == "fifteenMinute") {
        // do nothing as we don't wanna show thruput for minute and 15min intervals.
        // singleRow[oepeColumnName + "%"] = (parseInt(singleRow[oepeColumnName]) < parseInt(ow.target_oepe))? "Yes":"No";
      } else {
        singleRow = Object.assign({}, singleRow, { "Thru-put": row.thruput });
      }
    }

    if (sensitivity == "eachCar") {
      // do nothing as we don't wanna show thruput for minute and 15min intervals.

      delete singleRow[oepeColumnName + "%"];

      if (parseInt(singleRow[oepeColumnName]) > 0) {
        singleRow[oepeColumnName + " Target Met"] =
          parseInt(singleRow[oepeColumnName]) <
          parseInt(currentQueriedData.target_oepe)
            ? "Yes"
            : "No";
      } else {
        singleRow[oepeColumnName + " Target Met"] = "-";
      }
    }

    if (currentQueriedData["laneUtilization"]) {
      let laneUtilizationKeys = [
        ...currentQueriedData["laneUtilization"].keys(),
      ];

      let laneData = currentQueriedData["laneUtilization"].get(
        laneUtilizationKeys[i]
      );
      let orderLanesAsc = currentQueriedData["orderLanesAsc"];

      let laneCSVData = {};

      [...orderLanesAsc.keys()].sort().map((key) => {
        laneCSVData[`lane ${key}`] = 0;
      });

      if (laneData) {
        laneData.map((item, i) => {
          laneCSVData = Object.assign({}, laneCSVData, {
            [`lane ${item.label}`]: item.count,
          });
        });
      }

      singleRow = Object.assign({}, singleRow, laneCSVData);
    }

    if (
      (organization.id.match(/kfc/g) || organization.id.match(/hrd/g)) &&
      sensitivity == "minute"
    ) {
      singleRow = Object.assign({}, singleRow, {
        "Order Lane": row.order_lane,
      });
    }

    return singleRow;
  });

  const parser = new Parser({});
  const csv1 = parser.parse(csvArray);

  const a = document.createElement("a");
  a.href = "data:attachment/csv," + encodeURIComponent(csv1);
  a.target = "_blank";
  a.download = `${siteName.trim()} (${fingermarkId.trim()})_${format(
    new Date(),
    "yyyy-MM-dd h:mm aaa"
  )}.csv`;
  document.body.appendChild(a);
  a.click();
};

export const setDayStart = (date = new Date()) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};
export const setDayEnd = (date = new Date()) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};
