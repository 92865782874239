import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { GET_REPORT_USERS } from "../queries/shiftreport";
import { GET_SITE_CONFIGS } from "../queries";
import { get } from "lodash";

export const SET_SITE_CONFIGS = gql`
  mutation setSiteConfig(
    $id: String!
    $updated_by: String!
    $shifts: [ShiftInput]
    $reportSchedules: [ReportScheduleInput]
    $timezone: String
    $site_id: Int
  ) {
    setSiteConfigs(
      id: $id
      updated_by: $updated_by
      shifts: $shifts
      reportSchedules: $reportSchedules
      timezone: $timezone
      site_id: $site_id
    ) {
      id
      created_at
      shifts {
        uuid
        frequency
        startTime
        endTime
        userId
      }
      reportSchedules {
        uuid
        reportingFrequency
        userId
      }
    }
  }
`;

const SET_REPORT_USER = gql`
  mutation setReportUser(
    $id: String!
    $fingermark_id: String!
    $name: String!
    $email: String!
    $position: String
    $created_by: String
    $organization: String
  ) {
    setReportUser(
      id: $id
      fingermark_id: $fingermark_id
      name: $name
      email: $email
      position: $position
      created_by: $created_by
      organization: $organization
    ) {
      id
      name
      email
      position
      fingermark_id
    }
  }
`;

export const UPDATE_EMAIL_ADDRESS = gql`
  mutation updateUser($username: String!, $user_id: String, $email: String) {
    updateUser(user_id: $user_id, username: $username, email: $email) {
      Username
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $user_id: String
    $username: String!
    $password: String
  ) {
    updateUser(user_id: $user_id, username: $username, password: $password) {
      Username
      UserAttributes {
        Name
        Value
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $username: String!
    $user_id: String
    $portfolio_tutorial: Boolean
    $leaderboard_tutorial: Boolean
    $databoard_tutorial: Boolean
    $shiftreport_tutorial: Boolean
    $switched_to_beta: Boolean
    $organization: String
  ) {
    updateUser(
      username: $username
      user_id: $user_id
      portfolio_tutorial: $portfolio_tutorial
      leaderboard_tutorial: $leaderboard_tutorial
      databoard_tutorial: $databoard_tutorial
      shiftreport_tutorial: $shiftreport_tutorial
      switched_to_beta: $switched_to_beta
      organization: $organization
    ) {
      Username
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback(
    $username: String!
    $email: String
    $starRating: Int
    $feedback: String
    $url: String
    $version: String
  ) {
    submitFeedback(
      username: $username
      email: $email
      starRating: $starRating
      feedback: $feedback
      url: $url
      version: $version
    ) {
      id
    }
  }
`;

export const RESET_TUTORIALS = gql`
  mutation resetTutorial(
    $user_id: String
    $username: String!
    $shiftreport_tutorial: Boolean
    $portfolio_tutorial: Boolean
    $databoard_tutorial: Boolean
    $leaderboard_tutorial: Boolean
    $organization: String
  ) {
    updateUser(
      user_id: $user_id
      username: $username
      shiftreport_tutorial: $shiftreport_tutorial
      portfolio_tutorial: $portfolio_tutorial
      databoard_tutorial: $databoard_tutorial
      leaderboard_tutorial: $leaderboard_tutorial
      organization: $organization
    ) {
      Username
    }
  }
`;

export const useSetSiteConfigs = () => {
  return useMutation(SET_SITE_CONFIGS, {
    update: (cache, { data: { setSiteConfigs } }) => {
      const existingData = cache.readQuery({
        query: GET_SITE_CONFIGS,
        variables: { id: setSiteConfigs.id },
      });
      cache.writeQuery({
        query: GET_SITE_CONFIGS,
        variables: { id: setSiteConfigs.id },
        data: {
          getSiteConfigs: {
            ...existingData.getSiteConfigs,
            ...setSiteConfigs,
          },
        },
      });
    },
  });
};

export const useSetReportUser = () => {
  return useMutation(SET_REPORT_USER, {
    //manually updating the cache so the new users will be in the get query without having to refetch
    update: (cache, { data: { setReportUser } }) => {
      const { getReportUsers } = cache.readQuery({
        query: GET_REPORT_USERS,
        variables: { fingermark_id: setReportUser.fingermark_id },
      });
      cache.writeQuery({
        query: GET_REPORT_USERS,
        variables: { fingermark_id: setReportUser.fingermark_id },
        data: { getReportUsers: [...getReportUsers, setReportUser] },
      });
    },
  });
};

export const useUpdateUser = () => {
  return useMutation(UPDATE_USER);
};

export const useSubmitFeedback = () => {
  return useMutation(SUBMIT_FEEDBACK);
};
