import React from "react";
import { get } from "lodash";
import { selectedOrganization } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { roundValue } from "../../utils";

const DataboardStatBox = ({
  statBoxTitle = "Stat Box title",
  stat1Label = "Avg Sec",
  stat2Label = "Count",
  stat1Value = 0,
  stat2Value = 0,
  showPercentage = false,
  percentageLabel = null,
  percentage = null,
  show = true,
  className = "",
} = {}) => {
  if (!show) {
    return <></>;
  }

  return (
    <div
      className={`bg-white shadow overflow-hidden rounded-lg flex flex-col flex-1 ${className}`}
    >
      <div className="bg-green-200 text-center text-green-800">
        {statBoxTitle}
      </div>
      <div className="px-3 py-3 flex-grow">
        <div className="flex items-center h-full">
          <div className="w-0 flex-1">
            <dl className="grid grid-cols-2">
              <div className="">
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate flex flex-nowrap justify-center">
                  {stat1Label}
                </dt>
                <dd className="flex items-baseline flex-wrap justify-center">
                  <div className="text-xl leading-8 font-semibold text-gray-900 mr-2">
                    {stat1Value !== undefined ? stat1Value : ""}
                  </div>
                </dd>
              </div>
              <div className="border-l">
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate flex flex-nowrap justify-center">
                  {stat2Label}
                </dt>
                <dd className="flex items-baseline flex-wrap justify-center">
                  <div className="text-xl leading-8 font-semibold text-gray-900 mr-2">
                    {stat2Value !== undefined ? stat2Value : ""}
                  </div>
                </dd>
              </div>

              {showPercentage && (
                <div className="col-span-2 border-t pt-2">
                  <dd className="flex  flex-col justify-center items-center">
                    <div className="text-2xl leading-8 font-semibold text-gray-900 mr-2">
                      {percentage}%
                    </div>
                    {percentageLabel && (
                      <div className="text-sm leading-5 font-medium text-gray-500 truncate flex flex-nowrap justify-center">
                        {percentageLabel}
                      </div>
                    )}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataboardPromoStatBlock = ({
  processedData = {},
  round = true,
  showOldOEPE = false,
  sensitivity = "daily",
} = {}) => {
  const {
    oepe = 0,
    old_oepe = 0,
    pull_forward = 0,
    tet = 0,
    waiting_bay = 0,
  } = processedData.averageTime || {};

  let { targetOEPEMetPercentage = 0 } = processedData.averageTime || {};

  const {
    total_count = 0,
    waiting_bay_count = 0,
    pull_forward_count = 0,
    pull_out_count = 0,
  } = get(processedData, "vehicle_counts", {});

  if (sensitivity.eachCar) {
    const oepeTargetMetCars = get(processedData, "pivot_data", []).reduce(
      (acc, cv) => {
        if (
          parseInt(cv.avg_oepe) > 0 &&
          parseInt(cv.avg_oepe) < parseInt(processedData.target_oepe)
        ) {
          return acc + 1;
        } else {
          return acc;
        }
      },
      0
    );

    targetOEPEMetPercentage =
      total_count > 0 ? (oepeTargetMetCars / total_count) * 100 : 0;
  }

  const waitingBayPercentage = round
    ? roundValue((waiting_bay_count / total_count) * 100, 0)
    : roundValue((waiting_bay_count / total_count) * 100, 4);
  const pullOutPercentage = round
    ? roundValue((pull_out_count / total_count) * 100, 0)
    : roundValue((pull_out_count / total_count) * 100, 4);
  const pullForwardPercentage = round
    ? roundValue((pull_forward_count / total_count) * 100, 0)
    : roundValue((pull_forward_count / total_count) * 100, 4);
  const targetOEPEMetPercentageValue = round
    ? roundValue(targetOEPEMetPercentage, 0)
    : roundValue(targetOEPEMetPercentage, 4);
  const {
    max_oepe = 0,
    max_tet = 0,
    max_old_oepe,
  } = get(processedData, "maxTimes", {});

  const organization = useRecoilValue(selectedOrganization);
  const oepeLabel = get(organization, "oepeLabel", "Customer journey time");
  const tetLabel = get(organization, "tetLabel", "Total journey time");
  const show = !(
    organization.id === "kfc" ||
    organization.id === "kfc_uae" ||
    organization.id === "hrd"
  );

  return (
    <>
      <DataboardStatBox
        className="col-span-6  md:col-span-2 sm:col-span-3"
        statBoxTitle={get(organization, "waitBayLabel", "Waiting Bay")}
        stat1Value={`${waiting_bay}s`}
        stat2Value={`${waiting_bay_count}`}
        totalCount={`${total_count}`}
        showPercentage={waitingBayPercentage >= 0}
        percentage={`${waitingBayPercentage}`}
        show={show}
      />
      <DataboardStatBox
        className="col-span-6  md:col-span-2 sm:col-span-3"
        statBoxTitle={get(
          organization,
          "pullForwardLabel",
          "Secondary delivery window"
        )}
        stat1Value={`${pull_forward}s`}
        stat2Value={`${pull_forward_count}`}
        totalCount={`${total_count}`}
        showPercentage={pullForwardPercentage >= 0}
        percentage={`${pullForwardPercentage}`}
        show={show}
      />
      <DataboardStatBox
        className="col-span-6 md:col-span-2 sm:col-span-3 "
        statBoxTitle={get(organization, "pullOutLabel", "Pull Out")}
        stat1Label={`${get(organization, "pullOutLabel", "Pull Out")} %`}
        stat1Value={`${pullOutPercentage}%`}
        stat2Value={`${pull_out_count}`}

        // percentage={null}
      />
      <DataboardStatBox
        className={`md:col-span-${show ? "3" : "2"} sm:col-span-3 col-span-6`}
        statBoxTitle={`${showOldOEPE ? `Old ${oepeLabel}` : `${oepeLabel}`}`}
        stat1Label={`Avg. ${showOldOEPE ? `Old ${oepeLabel}` : `${oepeLabel}`}`}
        stat1Value={`${showOldOEPE ? old_oepe : oepe}s`}
        stat2Label={`Max. ${showOldOEPE ? `Old ${oepeLabel}` : `${oepeLabel}`}`}
        stat2Value={`${showOldOEPE ? max_old_oepe : max_oepe}`}
        showPercentage={true}
        percentage={showOldOEPE ? false : targetOEPEMetPercentageValue}
        percentageLabel={`vehicles inside the ${oepeLabel} target`}
      />
      <DataboardStatBox
        className={`md:col-span-${show ? "3" : "2"} sm:col-span-6 col-span-6`}
        statBoxTitle={tetLabel}
        stat1Label={`Avg. ${tetLabel}`}
        stat1Value={`${tet}s`}
        stat2Label={`Max. ${tetLabel}`}
        stat2Value={`${max_tet}`}
      />
    </>
  );
};

export default DataboardPromoStatBlock;
