import React from "react";
import ArrowUp from "../../assets/images/icons/arrow-up.svg";
import ArrowDown from "../../assets/images/icons/arrow-down.svg";

const PerformanceTable = ({ storeData = [] } = {}) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg  flex flex-col w-full">
      <div className="sm:hidden">
        <select
          aria-label="Selected tab"
          className="form-select block w-full"
          defaultValue="DEFAULT"
        >
          <option val="DEFAULT">High Performing</option>
          <option>Low Performing</option>
        </select>
      </div>
      <div className="hidden sm:block px-3 py-3 sm:p-3">
        <nav className="flex">
          <button
            className="mr-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-700 bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-200"
            aria-current="page"
          >
            High Performing
          </button>
          <button className="mr-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-600 focus:bg-gray-50">
            Low Performing
          </button>
        </nav>
      </div>
      <ul className="flex-grow">
        {storeData.map((store, i) => {
          return (
            <li
              key={i}
              className="flex justify-between item-center px-4 py-2 items-baseline"
            >
              <span className="flex flex-nowrap">
                <span className="text-xs font-bold text-gray-400 mr-4">
                  {i + 1}
                </span>
                <button className="text-xs font-medium text-blue-500 hover:text-blue-400">
                  {store.name}
                </button>
              </span>
              <span className="flex items-baseline flex-nowrap">
                <span className="text-sm text-gray-900">
                  ${store.per_minute}
                </span>
                <span className="text-xs text-gray-500">/min</span>
                {store.per_minute_diff_by > 0 ? (
                  <ArrowUp className="w-5 h-5 self-center flex-shrink-0 text-green-500" />
                ) : (
                  <ArrowDown className="w-5 h-5 self-center flex-shrink-0 text-red-500" />
                )}
              </span>
            </li>
          );
        })}
      </ul>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 ">
        <div className="text-sm leading-5">
          <button className="font-medium text-blue-500 hover:text-blue-400 transition ease-in-out duration-150">
            View all
          </button>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTable;
