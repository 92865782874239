import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";

const GET_PROCESSED_AGGREGATED_DATA = gql`
  query getProcessedAggregatedData(
    $site_id: Int!
    $start_date: String!
    $start_time: String!
    $end_date: String!
    $end_time: String!
    $timezone: String!
    $sensitivity: String
    $organization: String!
  ) {
    getProcessedAggregatedData(
      site_id: $site_id
      start_date: $start_date
      start_time: $start_time
      end_date: $end_date
      end_time: $end_time
      timezone: $timezone
      sensitivity: $sensitivity
      organization: $organization
    ) {
      target_tet
      target_oepe
      averageTime {
        cod
        payment
        deliver
        oepe
        tet
        waiting_bay
        pull_forward
        targetOEPEMetPercentage
        old_oepe
      }
      maxTimes {
        max_oepe
        max_tet
        max_old_oepe
      }
      pulse {
        start {
          data
        }
        before_deliver {
          data
          data_for_tet
        }
        before_order {
          data
          data_for_tet
        }
        deliver {
          data_for_tet
        }
        tet {
          data
          data_for_tet
          labels
        }
        order {
          data_for_tet
        }
        before_payment {
          data
          data_for_tet
        }
        payment {
          data
          data_for_tet
        }
        pull_forward {
          data
          data_for_tet
          counts
        }
        waiting_bay {
          data_for_tet
          counts
        }
        pull_out {
          counts
        }
        oepe {
          data
          labels
        }
        old_oepe {
          data
          labels
        }
      }
      vehicle_counts {
        total_count
        waiting_bay_count
        pull_forward_count
        pull_out_count
      }
      pivot_data {
        date
        car_counts
        avg_order
        avg_payment
        avg_deliver
        avg_old_oepe
        avg_oepe
        above_target_percentage
        avg_tet
        avg_waiting_bay
        thruput
      }
    }
  }
`;

const GET_PEAK_15_DATA = gql`
  query getPeak15Data(
    $site_id: Int!
    $start_date: String!
    $start_time: String!
    $end_date: String!
    $end_time: String!
    $timezone: String!
    $organization: String!
  ) {
    getPeak15Data(
      site_id: $site_id
      start_date: $start_date
      start_time: $start_time
      end_date: $end_date
      end_time: $end_time
      timezone: $timezone
      organization: $organization
    ) {
      data {
        date
        car_counts
      }
    }
  }
`;

const GET_LANE_UTILIZATION = gql`
  query getLaneUtilization(
    $site_id: Int!
    $organization: String!
    $start_date: String!
    $start_time: String!
    $end_date: String!
    $end_time: String!
    $timezone: String!
  ) {
    getLaneUtilization(
      site_id: $site_id
      organization: $organization
      start_date: $start_date
      start_time: $start_time
      end_date: $end_date
      end_time: $end_time
      timezone: $timezone
    ) {
      data
      labels
    }
  }
`;

export const useAggregatedData = ({
  site_id = 0,
  startDate = new Date(),
  startTime = "00:00:00",
  endDate = new Date(),
  endTime = "00:00:00",
  timezone = "Australia/Sydney",
  sensitivity = "daily",
  organization = null,
}) => {
  const variables = {
    site_id,
    start_date: format(startDate, "yyyy-MM-dd"),
    start_time: startTime,
    end_date: format(endDate, "yyyy-MM-dd"),
    end_time: endTime,
    timezone: timezone,
    sensitivity,
    organization,
  };
  const response = useQuery(GET_PROCESSED_AGGREGATED_DATA, {
    variables,
  });

  return response;
};

export const use15MinuteAggregatedData = ({
  site_id = 0,
  startDate = new Date(),
  startTime = "00:00:00",
  endDate = new Date(),
  endTime = "00:00:00",
  timezone = "Australia/Sydney",
  organization = null,
}) => {
  return useQuery(GET_PEAK_15_DATA, {
    variables: {
      site_id,
      start_date: format(startDate, "yyyy-MM-dd"),
      start_time: startTime,
      end_date: format(endDate, "yyyy-MM-dd"),
      end_time: endTime,
      timezone: timezone,
      organization,
    },
  });
};

export const useLaneUtilization = ({
  site_id = 0,
  startDate = new Date(),
  startTime = "00:00:00",
  endDate = new Date(),
  endTime = "00:00:00",
  timezone = "Australia/Sydney",
  organization = null,
}) => {
  return useQuery(GET_LANE_UTILIZATION, {
    variables: {
      site_id,
      start_date: format(startDate, "yyyy-MM-dd"),
      start_time: startTime,
      end_date: format(endDate, "yyyy-MM-dd"),
      end_time: endTime,
      timezone: timezone,
      organization,
    },
  });
};
