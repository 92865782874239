import React, { useState, useReducer, useMemo } from "react";
import Modal from "../Modal";
import { useSiteConfigs } from "../../graphql/queries";
import { useGetReportUsers } from "../../graphql/queries/shiftreport";
import { useSetSiteConfigs } from "../../graphql/mutations";
import { dayOptions } from "../../utils/shiftReportHelper";
import { v4 as uuidv4 } from "uuid";
import ShiftRow from "../ShiftRow";
import NewReportUser from "../NewReportUser";
import ReportFrequencies from "../ReportFrequencies";
import NewReportSchedule from "../NewReportSchedule";
import { format } from "date-fns";
import { get } from "lodash";
import WeekDaysNavBar from "../WeekDaysNavBar";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { useRecoilValue } from "recoil";
import { selectedSite, currentUser } from "../../recoil/atoms";

const ShiftCustomiser = ({ setShiftCustomiser = () => {} }) => {
  const { transition } = FiniteStateMachine;
  const currentDay = format(new Date(), "iiii").toLowerCase();
  const [newUserModal, setNewUserModal] = useState(false);
  const [newReportFrequencyModal, setNewReportFrequencyModal] = useState(false);
  const site = useRecoilValue(selectedSite);
  const authObj = useRecoilValue(currentUser);
  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
  });

  const fingermarkId = get(site, "fingermark_id", "");
  const { data } = useSiteConfigs({
    id: fingermarkId,
  });

  const { data: usersData = [] } = useGetReportUsers({
    fingermark_id: fingermarkId,
  });

  const [activeDay, setActiveDay] = useState(currentDay);
  const [
    saveShiftData,
    { data: saveData, error: saveError, loading: saveLoading },
  ] = useSetSiteConfigs();
  const shiftData = get(data, "getSiteConfigs.shifts", []).map(
    ({ uuid, startTime, endTime, frequency, userId }) => ({
      uuid,
      startTime,
      endTime,
      frequency,
      userId,
    })
  );

  useMemo(() => {
    if (saveData) {
      setCurrentModalState("success");
    } else if (saveError) {
      setCurrentModalState("error");
    } else if (saveLoading) {
      setCurrentModalState("loading");
    }
  }, [saveData, saveError, saveLoading]);

  const reportSchedulesData = get(
    data,
    "getSiteConfigs.reportSchedules",
    []
  ).map(({ uuid, userId, reportingFrequency }) => ({
    uuid,
    userId,
    reportingFrequency,
  }));

  const [shifts, setShifts] = useState(shiftData);
  const [reportSchedules, setReportSchedules] = useState(reportSchedulesData);

  const currentDayShifts = shifts.filter(
    (shift) => shift.frequency === activeDay
  );

  const changeShifts = (newShift) => {
    const newShifts = shifts.map((s) => {
      if (s.uuid === newShift.uuid) {
        return newShift;
      }
      return s;
    });

    setShifts(newShifts);
  };

  const deleteShiftHandler = (uuid) => {
    if (uuid) {
      const newShifts = shifts.filter((s) => s.uuid !== uuid);

      setShifts(newShifts);
    }
  };

  const changeReportSchedules = (newSchedule) => {
    const newReportSchedule = reportSchedules.map((schedule) => {
      if (schedule.uuid === newSchedule.uuid) {
        return newSchedule;
      }
      return schedule;
    });

    setReportSchedules(newReportSchedule);
  };

  const deleteSReportSchedules = (uuid) => {
    if (uuid) {
      const newReportSchedules = reportSchedules.filter(
        (schedule) => schedule.uuid !== uuid
      );
      setReportSchedules(newReportSchedules);
    }
  };

  const onSaveShiftData = () => {
    saveShiftData({
      variables: {
        id: site.fingermark_id,
        updated_by: get(authObj, "name", ""),
        shifts,
        reportSchedules,
        timezone: get(site, "time_zone", ""),
        site_id: get(site, "id", 0),
      },
    });
  };

  const onAddReportingScheduleHandler = (newReportSchedule) => {
    const newReportSchedules = [...reportSchedules, newReportSchedule];
    setReportSchedules(newReportSchedules);
  };

  return (
    <>
      <Modal
        className="shift-customize-dialog"
        modalState={currentModalState}
        toggleModal={setShiftCustomiser}
        saveDataHandler={onSaveShiftData}
        title="Customize Shifts"
      >
        <div className="bg-white p-6 ">
          <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
            Customize Shifts
          </h2>
          <WeekDaysNavBar activeDay={activeDay} setActiveDay={setActiveDay} />

          <div
            className="w-full"
            style={{ overflow: "auto", maxHeight: "calc(100vh - 290px)" }}
          >
            <div className="flex justify-between mb-1">
              <h2 className="text-base font-semibold text-gray-900">
                Shifts for {dayOptions.get(activeDay)}
              </h2>
              <div className="flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={() => {
                      setShifts([
                        ...shifts,
                        {
                          uuid: uuidv4(),
                          startTime: "",
                          endTime: "",
                          frequency: activeDay,
                        },
                      ]);
                    }}
                    className="px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  >
                    Add Shift
                  </button>
                </span>
              </div>
            </div>

            <table className="min-w-full divide-y divide-gray-200 mb-8">
              <tbody>
                {currentDayShifts.length ? (
                  currentDayShifts.map((shift, i) => (
                    <ShiftRow
                      key={shift.uuid}
                      {...shift}
                      rowIndex={i + 1}
                      onShiftChange={changeShifts}
                      onDeleteShift={deleteShiftHandler}
                      users={usersData.getReportUsers}
                    />
                  ))
                ) : (
                  <div
                    className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 text-sm"
                    role="alert"
                  >
                    Oops... No Shifts available
                  </div>
                )}
              </tbody>
            </table>

            <ReportFrequencies
              reportSchedules={reportSchedules}
              users={usersData.getReportUsers}
              onReportScheduleChange={changeReportSchedules}
              onDeleteReportSchedule={deleteSReportSchedules}
              setNewUserModalHandler={setNewUserModal}
              setNewReportFrequencyModalHandler={setNewReportFrequencyModal}
            />
          </div>
        </div>
      </Modal>
      {newReportFrequencyModal ? (
        <NewReportSchedule
          setNewReportFrequencyModal={setNewReportFrequencyModal}
          users={usersData.getReportUsers}
          onAddReportingScheduleHandler={onAddReportingScheduleHandler}
        />
      ) : null}
      {newUserModal ? (
        <NewReportUser setNewUserModal={setNewUserModal} />
      ) : null}
    </>
  );
};

export default ShiftCustomiser;
