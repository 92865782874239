import React from "react";
import { DotsHorizontalIcon as DotsHorizontal } from "@heroicons/react/outline";

const getStatusInfo = (status) => {
  switch (status) {
    case 1:
      return {
        status: "Online",
        color: "text-green-500",
      };
    case 2:
      return {
        status: "Offline",
        color: "text-red-500",
      };
    case 3:
      return {
        status: "Inactive",
        color: "text-yellow-500",
      };
    default:
      return {
        string: "",
        color: "text-gray-500",
      };
  }
};

const ActivityFeedItem = ({ time, device, location, status, old }) => {
  const statusInfo = getStatusInfo(status);
  return (
    <div>
      <div
        className={`pt-3 pb-4 px-6 ${
          old ? "bg-white" : "bg-gray-100"
        }  border-t border-gray-200`}
      >
        <div className="flex justify-between content-center">
          <span className="inline text-xs text-gray-400 leading-6">{time}</span>
          <button
            className="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500"
            aria-label="Notifications"
          >
            <DotsHorizontal height="5" width="5" className="text-gray-500 h-5 w-5" />
          </button>
        </div>
        <p className="text-xs text-gray-600">
          <button className="text-blue-500 font-medium">{device}</button> at{" "}
          <button className="text-blue-500 font-medium">{location}</button> has
          come{" "}
          <span className={`${statusInfo.color} font-medium`}>
            {statusInfo.status}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ActivityFeedItem;
