import React, { useState, useMemo, useReducer, useEffect } from "react";
import {
  SwitchHorizontalIcon as SwitchHorizontalOutline,
} from "@heroicons/react/outline";
import {
  PencilIcon as PencilSolid,
} from "@heroicons/react/solid";
import {
  useShiftData,
  useLastShiftData,
} from "../../graphql/queries/shiftreport";
import { useSiteConfigs } from "../../graphql/queries";
import { getChartsData } from "../../utils/shiftReportHelper";
import { format } from "date-fns";
import { get } from "lodash";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  selectedSite,
  selectedOrganization,
  currentUser,
} from "../../recoil/atoms";
import { loggedInUserLatest } from "../../recoil/selectors";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import ShiftReport from "../ShiftReport";
import { getTourSteps } from "./tours";
import { Walktour } from 'walktour';

const ShiftReportPage = () => {
  const [, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const [authObj] = useRecoilState(currentUser);
  const organization = useRecoilValue(selectedOrganization);
  const { transition, getActiveFieldName } = FiniteStateMachine;

  const lastNWeekData = [];
  const startDate = new Date();
  const [menuToggle, setMenuToggle] = useReducer(transition, {
    menuOpen: false,
    menuClosed: true,
  });
  const [showShiftCustomiser, setShowShiftCustomiser] = useState(false);
  const site = useRecoilValue(selectedSite);
  const [showStorePicker, setShowStorePicker] = useState(false);
  const [tour, setTour] = useState(!authObj.shiftReportTutorial && site);
  useMemo(() => {
    setTour(!authObj.shiftReportTutorial && site);
  }, [site]);

  useEffect(() => {
    setTour(!authObj.shiftReportTutorial && site);
  }, [authObj, site]);

  const {
    loading,
    error: errorSiteConfigs,
    data,
  } = useSiteConfigs({
    id: get(site, "fingermark_id", ""),
  });
  const shifts = get(data, "getSiteConfigs.shifts", []);
  const currentDay = format(new Date(), "iiii").toLowerCase();
  const [firstShift = {}] = shifts.filter(
    (shift) => shift.frequency === currentDay
  );

  const [timeframe, setTimeFrame] = useState({
    startTime: get(firstShift, "startTime", "00:00:00"),
    endTime: get(firstShift, "endTime", "23:59:59"),
  });

  useMemo(() => {
    if (firstShift.startTime && firstShift.endTime) {
      setTimeFrame({
        startTime: `${firstShift.startTime}`,
        endTime: `${firstShift.endTime}`,
      });
    }
  }, [firstShift]);

  const redrawId = useMemo(() => {
    return timeframe.startTime + timeframe.endTime;
  }, [timeframe]);

  const {
    loading: loadingSiteConfigs,
    error: errorShiftData,
    data: dataSiteConfigs,
  } = useShiftData({
    site_id: get(site, "id", 0),
    startDate: startDate,
    startTime: timeframe.startTime,
    endTime: timeframe.endTime,
    organization: get(organization, "id", null),
  });

  const { error: errorLastShiftData, data: dataLastShiftData } =
    useLastShiftData({
      site_id: get(site, "id", 0),
      startDate: startDate,
      startTime: timeframe.startTime,
      endTime: timeframe.endTime,
      last_n_shifts: 3,
      organization: get(organization, "id", null),
    });

  const { tetData = {} } = get(dataSiteConfigs, "getShiftData", {});

  const orgId = get(organization, "id", null);

  if (orgId == "czp") {
    delete tetData.payment;
    delete tetData.beforePayment;
  }

  // this will create a new array by adding the queried shift data to the previous weeks shift data in order display within charts.
  lastNWeekData.splice(
    0,
    lastNWeekData.length,
    ...get(dataLastShiftData, "getLastShiftData", [])
  );

  const error = !site
    ? "No site selected"
    : errorSiteConfigs || errorShiftData || errorLastShiftData;

  const chartData = getChartsData(lastNWeekData);

  const menuItems = [
    ...get(data, "getSiteConfigs.shifts", [])
      .filter((shift) => shift.frequency === currentDay)
      .map((shift, i) => ({
        onClick: () => {
          setTimeFrame({
            startTime: `${shift.startTime}`,
            endTime: `${shift.endTime}`,
          });
        },
        text: ` Shift ${i + 1} (${shift.startTime.substring(
          0,
          shift.startTime.length - 3
        )}-${shift.endTime.substring(0, shift.endTime.length - 3)}) `, // This is to remove seconds values from displaying
      })),
  ];

  menuItems.push({
    className: "customize-shift",
    text: "Customize my shifts",
    icon: <PencilSolid className="h-4 w-4 inline mr-1" />,
    onClick: () => setShowShiftCustomiser(!showShiftCustomiser),
  });

  if (
    get(authObj, "allowedSites", [])[0] === "*" ||
    get(authObj, "allowedSites", []).length > 1
  ) {
    menuItems.push({
      text: `Switch the Store`,
      onClick: () => setShowStorePicker(true),
      icon: <SwitchHorizontalOutline className="h-4 w-4 inline mr-1" />,
    });
  }

  const activeState = getActiveFieldName(menuToggle)();
  const tourSteps = getTourSteps(
    activeState,
    setMenuToggle,
    showShiftCustomiser,
    setShowShiftCustomiser
  );

  return (
    <>
      <ShiftReport
        site={site}
        showStorePicker={showStorePicker}
        setShowStorePicker={setShowStorePicker}
        showShiftCustomiser={showShiftCustomiser}
        setShowShiftCustomiser={setShowShiftCustomiser}
        setMenuToggle={setMenuToggle}
        menuToggle={menuToggle}
        timeframe={timeframe}
        loading={loading}
        menuItems={menuItems}
        error={error}
        loadingSiteConfigs={loadingSiteConfigs}
        dataSiteConfigs={dataSiteConfigs}
        redrawId={redrawId}
        tetData={tetData}
        chartData={chartData}
      />
      <Walktour
        steps={tourSteps}
      />
    </>
  );
};
export default ShiftReportPage;
