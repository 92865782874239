import React, { useState, useReducer, useMemo } from "react";
import Modal from "../Modal";
import { useSetReportUser } from "../../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue } from "recoil";
import {
  selectedSite,
  currentUser,
  selectedOrganization,
} from "../../recoil/atoms";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { get } from "lodash";
import { validateEmail } from "../../utils";
const NewReportUser = ({ setNewUserModal = () => {} }) => {
  const authObj = useRecoilValue(currentUser);
  const site = useRecoilValue(selectedSite);
  const organization = useRecoilValue(selectedOrganization);

  const { transition } = FiniteStateMachine;
  const [
    saveReportUser,
    { data: saveData, error: saveError, loading: saveLoading },
  ] = useSetReportUser();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");

  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
  });

  useMemo(() => {
    if (saveData) {
      setCurrentModalState("success");
    } else if (saveError) {
      setCurrentModalState("error");
    } else if (saveLoading) {
      setCurrentModalState("loading");
    }
  }, [saveData, saveError, saveLoading]);

  const updated_by = get(authObj, "name", "");

  const onSaveReportUser = () => {
    saveReportUser({
      variables: {
        id: uuidv4(),
        fingermark_id: site.fingermark_id,
        name,
        email,
        position,
        created_by: updated_by,
        organization: get(organization, "id", null),
      },
    });
  };

  return (
    <Modal
      modalState={currentModalState}
      toggleModal={setNewUserModal}
      saveDataHandler={onSaveReportUser}
      successDisabled={!(name !== "" && validateEmail(email))}
    >
      <div className="bg-white p-6 ">
        <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          Add a New User
        </h2>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <label
                  htmlFor={`from-time-`}
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Name
                </label>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <input
                  type="text"
                  class="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 mt-1 block w-full"
                  placeholder="John Doe"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Email
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <input
                  type="email"
                  class="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 mt-1 block w-full"
                  placeholder="john@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Position
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <input
                  type="text"
                  class="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400 mt-1 block w-full"
                  placeholder="Shift Manager"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default NewReportUser;
