import React, { useState } from "react";
import {
  LockClosedIcon as LockClosedOutline,
  SupportIcon as SupportOutline,
  QuestionMarkCircleIcon as QuestionMarkCircleOutline,
} from "@heroicons/react/outline";
import {
  currentUser,
  selectedOrganization,
  selectedSite,
  queriedTimeFrame,
} from "../../recoil/atoms";
import { useLogout } from "../../auth";
import { useResetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import FeedbackDialog from "../FeedbackDialog";
import { availableOrganizations } from "../../recoil/selectors";
import { get } from "lodash";
import ResetTutorialsModal from "../ResetTutorialsModal";
import ProfileModal from "../ProfileModal";
import { useAuth0 as useAuth } from "@auth0/auth0-react";

const OrganisationsSidebar = () => {
  const { logout } = useAuth();
  const organizations = useRecoilValue(availableOrganizations);
  const [showFeedback, setShowFeedback] = useState(false);
  const [resetTutorials, setShowResetTutorials] = useState(false);
  const [profileModal, setShowProfileModal] = useState(false);
  const currentUserReset = useResetRecoilState(currentUser);
  const selectedOrganizationReset = useResetRecoilState(selectedOrganization);
  const [selectedOrg, setSelectedOrg] = useRecoilState(selectedOrganization);
  const selectedSiteReset = useResetRecoilState(selectedSite);
  const queriedTimeFrameReset = useResetRecoilState(queriedTimeFrame);
  // const logout = useLogout();

  const logoutHandler = () => {
    currentUserReset();
    selectedOrganizationReset();
    selectedSiteReset();
    queriedTimeFrameReset();
    logout({ returnTo: process.env.REACT_APP_HOST_URL });
  };

  const changeOrg = (org) => {
    if (selectedOrg.id !== org.id) {
      // currentUserReset();
      selectedOrganizationReset();
      selectedSiteReset();
      queriedTimeFrameReset();

      setSelectedOrg(org);
    }
  };

  return (
    <div className="flex flex-col w-14 px-2 py-3 bg-gray-900">
      {showFeedback && <FeedbackDialog setShowFeedback={setShowFeedback} />}

      {resetTutorials && (
        <ResetTutorialsModal toggleModal={setShowResetTutorials} />
      )}

      {profileModal && <ProfileModal toggleModal={setShowProfileModal} />}

      {organizations.map((org, i) => (
        <button
          key={i}
          className={`outline-none inline w-10 h-10  mb-3 p-2 rounded flex items-center justify-center hover:bg-gray-500 ${
            get(selectedOrg, "id", null) === org.id
              ? "bg-gray-600"
              : "bg-gray-800"
          }`}
          alt={org.name}
          onClick={() => changeOrg(org)}
        >
          <img src={org.thumbnail} alt={org.name}></img>
        </button>
      ))}
      {/* <button className="rounded-full py-2 px-2 inline-flex items-center text-gray-100 justify-center hover:bg-gray-800 h-10 w-10 rounded-full focus:outline-none">
        <PlusSolid className="h-6 w-6 fill-current text-gray-600" />
      </button> */}

      <div className="flex-1"></div>

      <div>
        <button
          className="reset-tutorials rounded-full py-2 px-2 inline-flex items-center text-gray-100 justify-center hover:bg-gray-800 h-10 w-10 rounded-full focus:outline-none"
          onClick={() => {
            setShowResetTutorials(true);
          }}
       >
          <QuestionMarkCircleOutline className="h-6 w-6 text-gray-600  hover:text-primary" />
        </button>
      </div>
      <div>
        <button
          className="feedback-dialog rounded-full py-2 px-2 inline-flex items-center text-gray-100 justify-center hover:bg-gray-800 h-10 w-10 rounded-full focus:outline-none"
          onClick={() => {
            setShowFeedback(true);
          }}
        >
          <SupportOutline className="h-6 w-6 text-gray-600  hover:text-primary" />
        </button>
      </div>
      <div>
        <button
          className="rounded-full py-2 px-2 inline-flex items-center text-gray-100 justify-center hover:bg-gray-800 h-10 w-10 rounded-full focus:outline-none"
          onClick={() => {
            logoutHandler();
          }}
        >
          <LockClosedOutline className="h-6 w-6  text-gray-600 hover:text-red-500" />
        </button>
      </div>
    </div>
  );
};

export default OrganisationsSidebar;
