import React from "react";

const MapComponent = (props) => {
  const {
    title = "locations-map",
    className = "w-full",
    height,
    id = "gmap_canvas",
    src,
    frameBorder = "0",
    scrolling = "no",
    marginHeight = "0",
    marginWidth = "0",
  } = props;

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg w-full">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title={title}
            className={className}
            height={height}
            id={id}
            src={src} // https://maps.google.com/maps?q=australia&t=&z=5&ie=UTF8&iwloc=&output=embed
            frameBorder={frameBorder}
            scrolling={scrolling}
            marginHeight={marginHeight}
            marginWidth={marginWidth}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
