import { addQuarters, subDays, subWeeks } from "date-fns";
import { getPortfolioChartData } from "./getPortfolioChartData";

export const setQuarterTimeFrame = (quarter = 1, setterFunction = () => {}) => {
  if (quarter > 0) {
    const currentYear = new Date().getFullYear();
    const startDate = addQuarters(new Date(currentYear, 0, 1), quarter - 1);
    const endDate = subDays(
      addQuarters(new Date(currentYear, 0, 1), quarter),
      1
    );

    return setterFunction({
      startDate,
      endDate,
    });
  }
};

export const setLastNWeeksTimeFrame = (
  lastNWeeks = 2,
  setterFunction = () => {}
) => {
  const startDate = subWeeks(new Date(), lastNWeeks);
  return setterFunction({
    startDate,
    endDate: new Date(),
  });
};

export const setLastNDaysTimeFrame = (
  lastNDays = 1,
  setterFunction = () => {}
) => {
  const startDate = subDays(new Date(), lastNDays);
  return setterFunction({
    startDate,
    endDate: new Date(),
  });
};

export const getChartsData = (getPerformances = []) => {
  const tetChartData =
    getPortfolioChartData({
      dataArray: getPerformances,
      chartType: "tet_chart",
    }) || {};

  const oepeChartData =
    getPortfolioChartData({
      dataArray: getPerformances,
      chartType: "oepe_chart",
    }) || {};
  const carCountChartData =
    getPortfolioChartData({
      dataArray: getPerformances,
      chartType: "car_count_chart",
    }) || {};

  return {
    tetChartData,
    oepeChartData,
    carCountChartData,
  };
};

export const portfolioLinChartOptions = {
  annotation: {
    drawTime: "afterDatasetsDraw",
    events: ["click"],
    annotations: [],
  },
};

export const tourSteps = [
  {
    selector: ".first-step",
    description:
      "This page will give you an overview of all the restaurants you own/have access to",
  },
  {
    selector: ".options-menu",
    description: "From this menu you can change the date range of the data.",
  },
  {
    selector: "table.performance-table",
    description:
      "By default your stores will be sorted according to their OEPE ranking. But you can order them by Store Name, TET ranking, vehicle Count or Peak 15 count by clicking the Column heading",
  },
  {
    selector: ".chartjs-render-monitor",
    description:
      "You can tap on the site name in the legend and get rid of it temporarily from the chart if you only interested in certain sites. Weekends are highlighted in a light gray color so can easily be identified",
  },
  {
    selector: ".reset-tutorials",
    description:
      "If you want to take a look at this tutorials again, This is where you would reset it again :)",
  },
  {
    selector: ".feedback-dialog",
    description:
      "Finally but most importantly, you can submit your feedback, any bugs/issues you come across or any feature requests from here. This will help us to make this product an ideal fit for you when transitioning.",
  },
  // ...
];
