import React, { useEffect } from "react";

import AlertError from "../AlertError";
import DashboardReportStatBoxes from "../DashboardReportStatBoxes";
import DataboardChartGrid from "../DataboardChartGrid";
import AlertWarning from "../AlertWarning";
import "./customStyles.css";
import StorePicker from "../StorePicker";
import DataboardPageHeader from "../DataboardPageHeader";
import { getError } from "../../utils";

const RestaurantOverview = ({
  site = null,
  showStorePicker = true,
  setShowStorePicker = null,
  cogWheelMenuRef = null,
  setCurrentPageState = null,
  menuItems = [],
  showAdvance = false,
  setShowAdvance = null,
  setTimeFrame = null,
  timeFrame = [],
  onTimeFrameChange = null,
  currentPageState = null,
  error = null,
  oldOEPE = false,
  processedData,
  loading = false,
  round = false,
  peak15DataResults = {},
  sensitivity = {},
  setSensitivity = null,
}) => {
  if (!setSensitivity) {
    throw new Error("Missing the setSensitivity function");
  }

  return (
    <div className="flex flex-col w-0 flex-1 lg:mt-0 mt-10">
      <div className="flex overflow-y-auto flex-grow">
        <main className="flex-1 relative z-0 overflow-y-scroll py-6 focus:outline-none">
          {(!site || showStorePicker) && (
            <StorePicker toggleModal={setShowStorePicker} />
          )}
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col  justify-between flex-wrap mb-1">
            <DataboardPageHeader
              cogWheelMenuRef={cogWheelMenuRef}
              setCurrentPageState={setCurrentPageState}
              menuItems={menuItems}
              showAdvance={showAdvance}
              setShowAdvance={setShowAdvance}
              setTimeFrame={setTimeFrame}
              timeFrame={timeFrame}
              onTimeFrameChange={onTimeFrameChange}
              currentPageState={currentPageState}
              site={site}
              sensitivity={sensitivity}
              setSensitivity={setSensitivity}
            />
          </div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
            {error && (
              <div className="rounded-md overflow-hidden w-full mb-3">
                <AlertError alertText={`${getError(error)}`} />
              </div>
            )}
            {oldOEPE && (
              <AlertWarning
                badgeText="Old OEPE"
                alertText="Old OEPE is a deprecated metric. You are encouraged to use the New OEPE metric instead."
                className="mb-4"
              />
            )}

            {processedData && <DashboardReportStatBoxes
              processedData={processedData}
              loading={loading}
              round={round}
              showOldOEPE={oldOEPE}
              peak15DataResults={peak15DataResults}
              sensitivity={sensitivity}
            />}
          </div>

          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between flex-wrap">
            <div className="w-full grid xl:grid-cols-2 gap-4">
              <DataboardChartGrid
                sensitivity={sensitivity}
                processedData={processedData}
                loading={loading}
                showOldOEPE={oldOEPE}
                timeFrame={timeFrame}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RestaurantOverview;
