import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "../Home";
import Databoard from "../Databoard";
import Portfolio from "../Databoard/Portfolio";
import ShiftReport from "../Databoard/ShiftReport";
import LeaderboardPage from "../Databoard/Leaderboard";
import AuthenticatedRoute from "../../auth/AuthenticatedRoute";
import { useAuthorized } from "../../auth";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUser, selectedOrganization } from "../../recoil/atoms";
import {
  availableOrganizations,
  transformAuthUser,
} from "../../recoil/selectors";
import ApolloWrapper from "../../graphql/index";

import LoadingSpinner from "../../components/LoadingSpinner";
import { withAuthenticationRequired, useAuth0 as useAuth } from "@auth0/auth0-react";

const AppRoot = () => {
  const [loggedInUser, setLoggedInUser] = useRecoilState(currentUser);
  const isSuperManager = useAuthorized("superManager");
  const isRestrictedManager = useAuthorized("restrictedManager");
  return (
    <ApolloWrapper>
      <Router>
        <Routes>
          <Route exact path="/"
            element={
              isSuperManager ? (
                <Navigate to="/databoard/leaderboard" />
              ) : isRestrictedManager ? (
                <Navigate to="/databoard/portfolio" />
              ) : Object.keys(loggedInUser).length ? (
                <Navigate to="/databoard/restaurant-overview" />
              ) : null
            }
          />
          <Route exact path="/organisation" element={<AuthenticatedRoute isAuthorized={false} />}>
            <Route exact path='/organisation' element={<Home />} />
          </Route>

          <Route exact path="/databoard/portfolio" element={<AuthenticatedRoute isAuthorized={useAuthorized("restrictedManager")} />}>
            <Route exact path='/databoard/portfolio' element={<Portfolio />} />
          </Route>

          <Route exact path="/databoard/leaderboard" element={<AuthenticatedRoute isAuthorized={useAuthorized("superManager")} />}>
            <Route exact path='/databoard/leaderboard' element={<LeaderboardPage />} />
          </Route>

          <Route exact path="/databoard/restaurant-overview" element={<Databoard />} />
          <Route exact path="/databoard/shift-report" element={<ShiftReport />} />

        </Routes>
      </Router>
    </ApolloWrapper>
  );
};

const App = () => {
  const [loggedInUser, setLoggedInUser] = useRecoilState(currentUser);
  const [organization, setOrganization] = useRecoilState(selectedOrganization);
  const availableOrgs = useRecoilValue(availableOrganizations);

  const { user } = useAuth();

  useEffect(() => {
    const setCurrentUser = async () => {
      if (user) {
        setLoggedInUser(transformAuthUser(user));
      }
    };
    setCurrentUser();
  }, [user]);

  useEffect(() => {
    if (!organization && availableOrgs.length) {
      setOrganization(availableOrgs[0]);
    }
  }, [availableOrgs, user]);
  return (
    <>
      {Object.keys(loggedInUser).length ? (
        <AppRoot />
      ) : (
        <div className="bg bg-gray-800 w-full h-screen flex justify-center items-center">
          <LoadingSpinner className="w-8 h-8 text-primary" />
        </div>
      )}
    </>
  );
};

export default withAuthenticationRequired(App);
