import React from "react";
import { subDays, startOfWeek, startOfMonth } from "date-fns";
import {
  setQuarterTimeFrame,
  setLastNWeeksTimeFrame,
} from "../../utils/portfolioHelper";
export const getMenus = (setTimeFrame) => {
  return [
    {
      text: "Since Yesterday",
      onClick: () =>
        setTimeFrame({
          startDate: subDays(new Date(), 1),
          endDate: new Date(),
        }),
    },
    {
      text: "Week to Date",
      onClick: () =>
        setTimeFrame({
          startDate: startOfWeek(new Date()),
          endDate: new Date(),
        }),
    },
    {
      text: "Last 2 Weeks",
      onClick: () => setLastNWeeksTimeFrame(2, setTimeFrame),
    },
    {
      text: "Month to Date",
      onClick: () =>
        setTimeFrame({
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
        }),
    },
    {
      text: "Last 4 Weeks",
      onClick: () => setLastNWeeksTimeFrame(4, setTimeFrame),
    },
    {
      text: (
        <>
          1<sup>st</sup> Quarter
        </>
      ),
      onClick: () => setQuarterTimeFrame(1, setTimeFrame),
    },
    {
      text: (
        <>
          2<sup>nd</sup> Quarter
        </>
      ),
      onClick: () => setQuarterTimeFrame(2, setTimeFrame),
    },
    {
      text: (
        <>
          3<sup>rd</sup> Quarter
        </>
      ),
      onClick: () => setQuarterTimeFrame(3, setTimeFrame),
    },
    {
      text: (
        <>
          4<sup>th</sup> Quarter
        </>
      ),
      onClick: () => setQuarterTimeFrame(4, setTimeFrame),
    },
  ];
};
