import React, { useState, useReducer, useRef, useMemo, useEffect } from "react";
import {
  SwitchHorizontalIcon as SwitchHorizontal,
  CalculatorIcon as Calculator,
  ExclamationIcon as Exclamation,
  CloudDownloadIcon as CloudDownload,
} from "@heroicons/react/outline";
import {
  useAggregatedData,
  use15MinuteAggregatedData,
} from "../../graphql/queries/databoard";
import {
  downloadCsv,
  setDayStart,
  setDayEnd,
} from "../../utils/databoardHelper";
import { roundObject } from "../../utils";
import { format } from "date-fns";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  selectedSite,
  queriedTimeFrame,
  selectedOrganization,
  currentUser,
} from "../../recoil/atoms";
import { loggedInUserLatest } from "../../recoil/selectors";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { useClickedOutside } from "../../utils";
import { get } from "lodash";
import RestaurantOverview from "../RestaurantOverview";
import { getTourSteps } from "./tours";
import { Walktour } from 'walktour';

const DataboardPage = () => {
  const { transition, getActiveFieldName } = FiniteStateMachine;

  const cogWheelMenuRef = useRef(null);
  const [currentPageState, setCurrentPageState] = useReducer(transition, {
    menuOpen: false,
    menuClosed: true,
  });
  const [storePickerState, setStorePickerState] = useReducer(transition, {
    showStorePicker: false,
    hideStorePicker: true,
  });
  const [sensitivity, setSensitivity] = useReducer(transition, {
    daily: true,
    hourly: false,
    fifteenMinute: false,
    eachCar: false,
  });

  const [, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const [authObj] = useRecoilState(currentUser);
  const organization = useRecoilValue(selectedOrganization);
  const selectedTimeFrame = useRecoilValue(queriedTimeFrame);
  const site = useRecoilValue(selectedSite);

  const [showAdvance, setShowAdvance] = useState(false);
  const [round, setRound] = useState(true);
  const [oldOEPE, setOldOEPE] = useState(false);
  const [timeFrame, setTimeFrame] = useState([
    get(selectedTimeFrame, "startDate", setDayStart()),
    get(selectedTimeFrame, "endDate", setDayEnd()),
  ]);
  const [timeFrom, timeTo] = timeFrame;
  const [startDate, setStartDate] = useState(timeFrom);
  const [endDate, setEndDate] = useState(timeTo);

  useEffect(() => {
    setTimeFrame([
      get(selectedTimeFrame, "startDate", setDayStart()),
      get(selectedTimeFrame, "endDate", setDayStart()),
    ]);
    setStartDate(get(selectedTimeFrame, "startDate", setDayStart()));
    setEndDate(get(selectedTimeFrame, "endDate", setDayStart()));
  }, [selectedTimeFrame]);

  // TODO: can use a finite machine here.
  // const [showStorePicker, setShowStorePicker] = useState(false);
  const [tour, setTour] = useState(!authObj.databoardTutorial && !!site);

  useEffect(() => {
    setTour(!authObj.databoardTutorial && !!site);
  }, [authObj, site]);

  useMemo(() => {
    setTour(!authObj.databoardTutorial && !!site);
  }, [site]);

  useClickedOutside(cogWheelMenuRef, () => setCurrentPageState("menuClosed"), tour);

  const args = {
    site_id: get(site, "id", 0),
    organization: get(organization, "id", null),
    startDate: startDate,
    startTime: format(startDate, "HH:mm:ss"),
    endDate: endDate,
    endTime: format(endDate, "HH:mm:ss"),
    timezone: get(site, "time_zone", ""),
    sensitivity: getActiveFieldName(sensitivity)(),
  };

  const tdata = useAggregatedData(args);

  const [aggregatedData, setAggregatedData] = useState(null);
  let { loading, error, data: { getProcessedAggregatedData } = {} } = tdata;

  useEffect(() => {

    if(loading) return;

    setAggregatedData(tdata.data);

  }, [loading, tdata]);

  const peak15DataResults = use15MinuteAggregatedData({
    site_id: get(site, "id", 0),
    organization: get(organization, "id", null),
    startDate: startDate,
    startTime: format(startDate, "HH:mm:ss"),
    endDate: endDate,
    endTime: format(endDate, "HH:mm:ss"),
    timezone: get(site, "time_zone", ""),
  });

  const onTimeFrameChange = () => {
    setStartDate(timeFrame[0]);
    setEndDate(timeFrame[1]);
  };

  const [processedData, setProcessedData] = useState(null);
  useEffect(() => {
    const getProcessedAggregatedData = get(aggregatedData, 'getProcessedAggregatedData', {});
    setProcessedData(round
      ? roundObject(getProcessedAggregatedData, 0)
      : roundObject(getProcessedAggregatedData, 4));

  }, [aggregatedData, round]);

  const menuItems = [
    {
      className: "dont-round",
      text: `${round ? "Don't" : ""} Round`,
      onClick: () => setRound(!round),
      icon: <Calculator className="h-4 w-4 inline mr-1" />,
    },
    {
      className: "switch-to-old-oepe",
      text: `${oldOEPE ? "Switch to New OEPE" : "Switch to old OEPE"}`,
      onClick: () => setOldOEPE(!oldOEPE),
      icon: <Exclamation className="h-4 w-4 inline mr-1" />,
    },
    {
      className: "download-csv",
      text: `Download a CSV`,
      onClick: () =>
        downloadCsv(
          processedData,
          getActiveFieldName(sensitivity)(),
          oldOEPE,
          site,
          organization
        ),
      icon: <CloudDownload className="h-4 w-4 inline mr-1" />,
    },
  ];

  if (
    get(authObj, "allowedSites", [])[0] === "*" ||
    get(authObj, "allowedSites", []).length > 1
  ) {
    menuItems.push({
      className: "multi-store",
      text: `Switch the Store`,
      onClick: () => {
        setStorePickerState("showStorePicker");
      },
      icon: <SwitchHorizontal className="h-4 w-4 inline mr-1" />,
    });
  }
  const activeState = getActiveFieldName(currentPageState)();

  const tourSteps = getTourSteps(activeState, setCurrentPageState);

  return (
    <>
      <RestaurantOverview
        site={site}
        showStorePicker={storePickerState.showStorePicker}
        setShowStorePicker={(val) =>
          val
            ? setStorePickerState("showStorePicker")
            : setStorePickerState("hideStorePicker")
        }
        cogWheelMenuRef={cogWheelMenuRef}
        setCurrentPageState={setCurrentPageState}
        menuItems={menuItems}
        showAdvance={showAdvance}
        setShowAdvance={setShowAdvance}
        setTimeFrame={setTimeFrame}
        timeFrame={timeFrame}
        onTimeFrameChange={onTimeFrameChange}
        currentPageState={currentPageState}
        error={error}
        oldOEPE={oldOEPE}
        processedData={processedData}
        loading={loading}
        round={round}
        peak15DataResults={peak15DataResults}
        sensitivity={sensitivity}
        setSensitivity={setSensitivity}
      />
      <Walktour
        steps={tourSteps}
        customCloseFunc={(logic) => {
          setTour(false);
          logic.close();
        }}
      />
    </>
  );
};
export default DataboardPage;
