import React from "react";
export const TableHeaderCell = ({
  children,
  onClick = null,
  className = "",
} = {}) => {
  const defaultClasses = [
    "px-6",
    "py-3",
    "border-b",
    "border-gray-100",
    "bg-gray-50",
    "text-center",
    "text-xs",
    "leading-4",
    "font-medium",
    "text-gray-500",
    "uppercase",
    "tracking-wider",
  ];
  const thClasses = defaultClasses.concat(className.split(" "));
  return (
    <th onClick={onClick} className={thClasses.join(" ")}>
      {children}
    </th>
  );
};

export const TableRow = ({ index, children } = {}) => (
  <tr className={`${index % 2 ? "bg-gray-200" : ""}`}>{children}</tr>
);

export const TableRowAdvance = ({
  index,
  children,
  className = "",
  onClick = null,
} = {}) => {
  const defaultClasses = ["opacity-50"];
  const rowClasses = defaultClasses.concat(className.split(" "));
  return (
    <tr
      onClick={onClick}
      className={`${
        index % 2 ? rowClasses.join(" ") + " bg-gray-200" : rowClasses.join(" ")
      }`}
    >
      {children}
    </tr>
  );
};

export const TableCell = ({ children, className = "" } = {}) => {
  const defaultCellClasses = [
    "px-6",
    "py-4",
    "whitespace-nowrap",
    "border-b",
    "border-gray-100",
    "text-sm",
    "leading-5",
    "font-medium",
    "text-black-500",
  ];
  const cellClasses = defaultCellClasses.concat(className.split(" "));
  return <td className={cellClasses.join(" ")}>{children}</td>;
};
