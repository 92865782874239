import McdLogo from "../src/assets/images/organizations/mcd.svg";
import HardeesLogo from "../src/assets/images/organizations/hardees.png";
import AmericanaLogo from "../src/assets/images/organizations/americana.png";
import BcdLogo from "../src/assets/images/organizations/bcd.png";
import CafeZupaLogo from "../src/assets/images/organizations/czp.png";
import KfcLogo from "../src/assets/images/organizations/kfc-logo.svg";
import FingermarkLogo from "../src/assets/images/favicon-32x32.png";
import StbLogo from "../src/assets/images/organizations/stb.png";
import EljLogo from "../src/assets/images/organizations/elj.png";
import PocLogo from "../src/assets/images/organizations/fmpoc.png";
import BkgLogo from "../src/assets/images/organizations/bkg.png";
import PopLogo from "../src/assets/images/organizations/pop.png";
import CulLogo from "../src/assets/images/organizations/cul.png";
import ZmbLogo from "../src/assets/images/organizations/zmb.png";
import TimLogo from "../src/assets/images/organizations/tim.png";
import PtlLogo from "../src/assets/images/organizations/ptl.png";


export const settings = () => {
  return {
    tempActivityFeed: [
      {
        time: "16:53pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: false,
        status: 1,
      },
      {
        time: "16:54pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: false,
        status: 2,
      },
      {
        time: "16:54pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: false,
        status: 3,
      },
      {
        time: "16:54pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: true,
        status: 1,
      },
      {
        time: "16:54pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: true,
        status: 1,
      },
      {
        time: "16:54pm",
        device: "Kiosk #1",
        location: "KFC Sydney Airport",
        old: true,
        status: 2,
      },
    ],
    tempAlerts: [
      {
        status: 3,
        value: 3,
        diffBy: 2,
        title: "Alerts",
      },
      {
        status: 2,
        value: 3,
        diffBy: -2,
        title: "Offline Devices",
      },
      {
        status: 1,
        value: 24,
        diffBy: 2,
        title: "Online Devices",
      },
    ],
    tempStorePerfomances: [
      {
        name: "KFC Sydney Airport",
        status: 1,
        avg_session_time: 1.43,
        avg_spend: 10.2,
        weekly_total: 5580,
        per_minute: 13.25,
        per_minute_diff_by: 1,
      },
      {
        name: "KFC Sydney CBD",
        status: 2,
        avg_session_time: 1.43,
        avg_spend: 10.2,
        weekly_total: 5580,
        per_minute: 10.25,
        per_minute_diff_by: -1,
      },
      {
        name: "KFC Frenches Forest",
        status: 3,
        avg_session_time: 1.43,
        avg_spend: 10.2,
        weekly_total: 5580,
        per_minute: 8.25,
        per_minute_diff_by: 1,
      },
      {
        name: "KFC Melbourne",
        status: 1,
        avg_session_time: 1.43,
        avg_spend: 10.2,
        weekly_total: 5580,
        per_minute: 7.25,
        per_minute_diff_by: -1,
      },
    ],
    organizations: [
      /* 
      
      available options for organization object.
      {
        id => client id
        name => client display name
        thumbnail=> organization thumbnail for the side bar , this can be a url or react component
        paymentLabel => replacement label for the payment label
        deliverLabel => replacement label for the delivery label
        waitBayLabel => replacement label for the wait bay label
        pullForwardLabel => replacement label for the pull forward label
        pullOutLabel => replacement label for pull outs label
        oepeLabel => replacement label for the oepe label
        tetLabel => replacement label for the osfd label
        peakPerformanceLabel => replacement label for the peak 15 label
      }

      */
      {
        id: "mcd",
        name: "mcd",
        thumbnail: McdLogo,
        orderLabel: "COD",
        pullForwardLabel: "Pull Forward",
        oepeLabel: "OEPE",
        tetLabel: "TET",
      },
      {
        id: "mnz",
        name: "mnz",
        thumbnail: "https://www.crwflags.com/fotw/images/n/nz$mcdon.gif",
        pullForwardLabel: "Pull Forward",
        oepeLabel: "OEPE",
        tetLabel: "TET",
      },
      {
        id: "demo-mcd",
        name: "demo-mcd",
        thumbnail: FingermarkLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "OEPE",
        tetLabel: "TET",
      },
      {
        id: "kfc",
        name: "kfc",
        thumbnail: KfcLogo,
        oepeLabel: "Efficiency",
        tetLabel: "Total Journey Time",
        orderLabel: "Order",
        pullForwardLabel: "Pull Forward",
        peakPerformanceLabel: "Thru-put",
      },
      {
        id: "hrd",
        name: "hrd",
        thumbnail: HardeesLogo,
        thumbnail: AmericanaLogo,
        oepeLabel: "Efficiency",
        tetLabel: "Total Journey Time",
        orderLabel: "Order",
        pullForwardLabel: "Pull Forward",
        peakPerformanceLabel: "Thru-put",
      },
      {
        id: "czp",
        name: "czp",
        thumbnail: CafeZupaLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Efficiency",
        tetLabel: "Total Journey Time",
      },
      {
        id: "stb",
        name: "stb",
        thumbnail: StbLogo,
        oepeLabel: "Customer journey time",
        tetLabel: "Total journey time",
      },
      {
        id: "bcd",
        name: "bcd",
        thumbnail: BcdLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "OEPE",
        tetLabel: "TET",
      },
      {
        id: "elj",
        name: "elj",
        thumbnail: EljLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "poc",
        name: "poc",
        thumbnail: PocLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "bkg",
        name: "bkg",
        thumbnail: BkgLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "pop",
        name: "pop",
        thumbnail: PopLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "stb-nzl",
        name: "stb-nzl",
        thumbnail: StbLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "cul-usa",
        name: "cul-usa",
        thumbnail: CulLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "zmb-aus",
        name: "zmb-aus",
        thumbnail: ZmbLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "tim-can",
        name: "tim-can",
        thumbnail: TimLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      },
      {
        id: "ptl-usa",
        name: "ptl-usa",
        thumbnail: PtlLogo,
        pullForwardLabel: "Pull Forward",
        oepeLabel: "Customer Journey Time",
        tetLabel: "Total Journey Time",
      }
    ],
  };
};
