import React, { useReducer, useState, useEffect } from "react";
import Modal from "../Modal";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import { UPDATE_EMAIL_ADDRESS, UPDATE_PASSWORD } from "../../graphql/mutations";
import { DESCRIBE_POOL } from "../../graphql/queries/user";
// import { client } from "../../graphql";
import { currentUser } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { get } from "lodash";
import { loggedInUserLatest } from "../../recoil/selectors";
import LoadingSpinner from "../LoadingSpinner";
import { validateEmail } from "../../utils";
import { isPasswordInvalid } from "../../utils/users";
  import { useApolloClient } from '@apollo/client';

const ProfileModal = ({ toggleModal = null }) => {
  const { transition } = FiniteStateMachine;

  const [loggedInUser, setLoggedInUser] = useRecoilState(currentUser);
  const [authAttribute, setAuthAttribute] = useRecoilState(loggedInUserLatest);
  const client = useApolloClient();

  const [email, setEmail] = useState(loggedInUser.email);
  const [password, setPassword] = useState("randompassword");
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordPolicy, setPasswordPolicy] = useState(null);
  const [isPasswordInvalidText, setIsPasswordInvalidText] = useState("");
  const [changeProfile, setChangeProfile] = useReducer(transition, {
    email: false,
    password: false,
    none: true,
  });

  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
    successWithMessage: false,
  });

  const username = get(loggedInUser, "name", null);
  const userId = get(loggedInUser, "sub", null);

  useEffect(() => {
    if (!passwordPolicy) {
      const getPolicy = async () => {
        const policy = await client.query({
          query: DESCRIBE_POOL,
        });
        const passwordPolicy = get(
          policy,
          "data.getCognitoPoolData.UserPool.Policies.PasswordPolicy",
          {}
        );
        setPasswordPolicy(passwordPolicy);
      };
      getPolicy();
    }
  }, [passwordPolicy]);

  useEffect(() => {
    const isPasswordValid = isPasswordInvalid({
      policy: passwordPolicy,
      password,
    });
    setIsPasswordInvalidText(isPasswordValid);
  }, [password]);

  const changeEmailAddress = async () => {
    setCurrentModalState("loading");
    //validate email address,
    if (validateEmail(email)) {
      setErrorMessage(null);
      //save email address
      try {
        const response = await client.mutate({
          mutation: UPDATE_EMAIL_ADDRESS,
          variables: {
            user_id: userId,
            username: username,
            email,
          },
        });
        setAuthAttribute({
          email: email,
        });

        setCurrentModalState("successWithMessage");
        setChangeProfile("none");
      } catch (e) {
        setCurrentModalState("error");
        setErrorMessage(`${e}`);
      }
    } else {
      setCurrentModalState("error");
      setErrorMessage("Incorrect Email address");
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    setCurrentModalState("loading");

    //validate password
    if (!isPasswordInvalid({ policy: passwordPolicy, password })) {
      try {
        const response = await client.mutate({
          mutation: UPDATE_PASSWORD,
          variables: {
            user_id: userId,
            username,
            password,
          },
        });

        setCurrentModalState("successWithMessage");
        setChangeProfile("none");
      } catch (e) {
        setCurrentModalState("error");
        setErrorMessage(`${e}`);
      }
    } else {
      setCurrentModalState("error");
      setErrorMessage("Incorrect Password");
    }
  };

  return (
    <Modal
      modalState={currentModalState}
      alertText={errorMessage}
      toggleModal={toggleModal}
      saveDataHandler={toggleModal}
      successButtonText="Go Back"
    >
      <div className="bg-white p-6 ">
        <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          My Profile
        </h2>
        <table className="min-w-full divide-y divide-gray-200 mt-4 table-fixed">
          <tbody>
            <tr className="mb-2">
              <td className="px-2 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Username :
              </td>
              <td>
                <input
                  className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400  text-sm mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring disabled:bg-gray-200"
                  value={username}
                  type="text"
                  disabled
                />{" "}
              </td>
            </tr>
            <tr className="mb-2">
              <td className="w-1/4 px-2 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Email Address :
              </td>
              <td className="w-1/4">
                <input
                  className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400  text-sm mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring disabled:bg-gray-200"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!changeProfile.email}
                  type="email"
                />{" "}
              </td>
              <td className="w-1/4">
                {!changeProfile.email ? (
                  <span
                    className="text-sm pl-2 text-teal-600 hover:cursor-pointer hover:text-blue-600"
                    onClick={(e) => setChangeProfile("email")}
                  >
                    Change Email
                  </span>
                ) : (
                  <button
                    type="button"
                    className="inline-flex justify-center w-auto rounded-md border border-transparent ml-2 px-2 py-1 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-600 focus:ring-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:opacity-25 disabled:cursor-not-allowed"
                    onClick={() => changeEmailAddress()}
                  >
                    {currentModalState.loading && <LoadingSpinner />} Save
                  </button>
                )}
              </td>
            </tr>
            <tr className="mb-2">
              <td className="px-2 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Password :
              </td>
              <td className="relative">
                <input
                  className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400  text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring disabled:bg-gray-200"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={!changeProfile.password}
                  type="password"
                />
                <span className="text-xs text-red-400 absolute left-0 -bottom-4">
                  {isPasswordInvalidText}
                </span>
              </td>
              <td>
                {!changeProfile.password ? (
                  <span
                    className="text-sm pl-2 text-teal-600 hover:cursor-pointer hover:text-blue-600"
                    onClick={(e) => {
                      setChangeProfile("password");
                      setPassword("");
                    }}
                  >
                    Change Password
                  </span>
                ) : (
                  <button
                    type="button"
                    className="inline-flex justify-center w-auto rounded-md border border-transparent ml-2 px-2 py-1 bg-green-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-400 focus:outline-none focus:border-green-600 focus:ring-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:opacity-25 disabled:cursor-not-allowed"
                    onClick={(e) => changePassword(e)}
                    disabled={isPasswordInvalidText}
                  >
                    {currentModalState.loading && <LoadingSpinner />} Save
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ProfileModal;
