export const isPasswordInvalid = ({ policy = {}, password = "" } = {}) => {
  // if (policy) {
  //   let error = false;
  //   Object.keys(policy).map((p) => {
  //     switch (p) {
  //       case "MinimumLength":
  //         if (password.length < policy[p]) {
  //           error = `Require ${policy[p]} characters`;
  //           return error;
  //         }
  //         break;
  //       case "RequireUppercase":
  //         if (policy[p] && !password.match(/[A-Z]/g)) {
  //           error = "Require Uppercase characters";
  //           return error;
  //         }
  //         break;
  //       case "RequireLowercase":
  //         if (policy[p] && !password.match(/[a-z]/g)) {
  //           error = "Require Lowercase characters";
  //           return error;
  //         }
  //         break;
  //       case "RequireSymbols":
  //         if (policy[p] && !password.match(/[^\w\s]/g)) {
  //           error = "Require Special Characters";
  //           return error;
  //         }
  //         break;
  //       case "RequireNumbers":
  //         if (policy[p] && !password.match(/\d/g)) {
  //           error = "Require Numbers";
  //           return error;
  //         }
  //         break;
  //       default:
  //         return false;
  //     }
  //   });
  //   return error;
  // }

  if (!password) {
    return "Password is required";
  }

  return false;
};
