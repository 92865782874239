export const getTourSteps = (
  activeState,
  setMenuToggle,
  showShiftCustomiser,
  setShowShiftCustomiser
) => {
  return [
    {
      selector: ".page-header",
      description:
        "This page will quickly show you an overview of the shift wise data.",
    },
    {
      selector: ".queried-time-frame",
      description: "Queried shift is shown here",
    },
    {
      selector: ".stat-boxes",
      description:
        "From here you can view the performance of the store for a shift at a quick glance",
    },
    {
      selector: ".tet-chart",
      description:
        "Peak 15 interval slot for the queried timeframe is highlighted in green color. You can click on the legend items and temporarily remove it from the chart. Target TET line also been displayed in red color as a reference.",
    },
    {
      selector: ".order-end-pay-start-comparison",
      description:
        "From here you can easily see how well the todays shift performed against the last 2 recent shifts of the same day. (Functionality to customize the number of previous shifts you want to display here also coming soon.)",
    },
    {
      selector: ".options-menu-button",
      description:
        "Use this menu to select the shift you want to view. By default the data for the first shift in the list will be queried.",
        customNextFunc: (logic) => {
          setMenuToggle("menuOpen");
          logic.next();
        },
      },
    {
      selector: ".options-menu .customize-shift",
      description:
        "Use this menu to select the shift you want to view. By default the data for the first shift in the list will be queried.",
        customNextFunc: (logic) => {
          if (!showShiftCustomiser) {
            setShowShiftCustomiser(true);
          }
          logic.next();
        },
    },
    {
      selector: ".shift-customize-dialog",
      description:
        "You can add/remove/modify shift data from here. Shift managers will automatically get an email with the shift performance once a shift finishes.",
        customNextFunc: (logic) => {
          if (!showShiftCustomiser) {
            setShowShiftCustomiser(true);
          }
          logic.next();
        },
    },
    {
      selector: ".shift-customize-dialog .add-reporting-user",
      description:
        "You can add a new reporting user from here. Make sure to use a valid email as this email address will be used to send out performance reviews",
      customNextFunc: (logic) => {
        if (!showShiftCustomiser) {
          setShowShiftCustomiser(true);
        }
        logic.next();
      },
    },
    {
      selector: ".shift-customize-dialog .add-scheduled-report",
      description:
        "You can add a new reporting user from here. Make sure to use a valid email as this email address will be used to send out performance reviews",
      customNextFunc: (logic) => {
        if (showShiftCustomiser) {
          setShowShiftCustomiser(false);
        }
        logic.next();
      },
    },
    {
      selector: ".feedback-dialog",
      description:
        "Finally but most importantly, you can submit your feedback, any bugs/issues you come across or any feature requests from here. This will help us to make this product an ideal fit for you when transitioning.",
    },
  ];
};
