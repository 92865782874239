import gql from "graphql-tag";

export const DESCRIBE_POOL = gql`
  query describePool {
    getCognitoPoolData {
      UserPool {
        Policies {
          PasswordPolicy {
            MinimumLength
            RequireUppercase
            RequireLowercase
            RequireSymbols
          }
        }
      }
    }
  }
`;
