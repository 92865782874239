import React, { useEffect, useState } from "react";
import PulseBox from "../PulseBox";
import DataboardPromoStatBlock from "../DataboardPromoStatBlock";
import { maxBy } from "lodash";
import { addMinutes } from "date-fns";
import { get, meanBy } from "lodash";
import { selectedOrganization, selectedSite } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { formatDate } from "../../utils";
import { useSiteConfigs } from "../../graphql/queries";

const BasicDataboardStatBox = ({
  statBoxTitle = "Stat Box title",
  value = 0,
  className = "",
  subText = null,
  loading = false,
}) => {
  return (
    <div
      className={`bg-white shadow overflow-hidden rounded-lg flex flex-col flex-1 ${className}`}
    >
      <div className="bg-gray-200 text-center text-green-800">
        {statBoxTitle}
      </div>
      <div className="px-4 py-3 flex-grow">
        <div className="flex items-center">
          <div className="w-0 flex-1">
            <dl className="grid grid-cols-2">
              <div className="col-span-2 pt-2">
                <dd className="flex flex-wrap justify-center items-center flex-col">
                  <div className="text-2xl leading-8 font-semibold text-gray-900 mr-2">
                    {loading ? <PulseBox className="h-4 w-12" /> : value}
                  </div>
                  {subText && (
                    <div className="text-xs text-gray-500">
                      {loading ? (
                        <PulseBox className="mt-2 mr-2 h-2 w-32" />
                      ) : (
                        subText
                      )}
                    </div>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardReportStatBoxes = ({
  processedData,
  loading = false,
  round = true,
  showOldOEPE = false,
  peak15DataResults,
  sensitivity = "daily",
}) => {
  const organization = useRecoilValue(selectedOrganization);
  const orgId = get(organization, "id", null);
  const site = useRecoilValue(selectedSite);

  const {
    loading: loadingSiteConfigs,
    error: errorSiteConfigs,
    data: siteConfigData,
  } = useSiteConfigs({
    id: get(site, "fingermark_id", ""),
  });

  const [statsData, setStatsData] = useState({});

  useEffect(() => {
    console.log("processedData", processedData);
    const thruput = Math.round(meanBy(processedData.pivot_data, "thruput", 0));

    setStatsData({
      cod: get(processedData, "averageTime.cod", 0),
      deliver: get(processedData, "averageTime.deliver", 0),
      payment: get(processedData, "averageTime.payment", 0),
      total_count: get(processedData, "vehicle_counts.total_count", 0),
      thruput,
      thruputValue: !isNaN(thruput) ? thruput : "-",
    });

    console.log({ statsData });
  }, [processedData]);

  const { loading: peak15Loading, data: { getPeak15Data = {} } = {} } =
    peak15DataResults;

  const peak15 = maxBy(get(getPeak15Data, "data", []), "car_counts");

  const peak15Date = get(peak15, "date", null)
    ? new Date(parseInt(get(peak15, "date")))
    : new Date();

  const peak15DateString = `${formatDate(
    peak15Date,
    "yyyy-MM-dd (HH:mm"
  )}-${formatDate(addMinutes(peak15Date, 15), "HH:mm)")}`;

  const containerFlexClasses = "w-full flex flex-row  space-x-4 flex-wrap";

  let containerClasses =
    "grid w-full gap-4 grid-cols-2 md:grid-cols-6 lg:grid-cols-5";
  let statBoxClass1 = "col-span-1 md:col-span-2 lg:col-span-1 ";
  let statBoxClass2 = "col-span-1 md:col-span-3 lg:col-span-1";
  let statBoxClass3 = "col-span-2 md:col-span-3 lg:col-span-1";
  let numberOfStatBoxes = 5;

  const notAllowedBays = get(
    siteConfigData,
    "getSiteConfigs.not_allowed_bays",
    []
  );

  if (notAllowedBays.includes("order")) {
    numberOfStatBoxes -= 1;
  }
  if (notAllowedBays.includes("payment")) {
    numberOfStatBoxes -= 1;
  }
  if (notAllowedBays.includes("deliver")) {
    numberOfStatBoxes -= 1;
  }

  if (orgId === "czp") {
    numberOfStatBoxes = 4;
  }

  containerClasses = `grid w-full gap-${numberOfStatBoxes} grid-cols-2 md:grid-cols-2 lg:grid-cols-${numberOfStatBoxes}`;
  return (
    <>
      <div className={containerClasses}>
        {!loading ? (
          <>
            {!get(
              siteConfigData,
              "getSiteConfigs.not_allowed_bays",
              []
            ).includes("order") && (
              <BasicDataboardStatBox
                statBoxTitle={get(organization, "orderLabel", "Order Point")}
                value={`${statsData.cod}s`}
                className={statBoxClass1}
              />
            )}

            {orgId !== "czp" &&
              !get(
                siteConfigData,
                "getSiteConfigs.not_allowed_bays",
                []
              ).includes("payment") && (
                <BasicDataboardStatBox
                  statBoxTitle={get(organization, "paymentLabel", "Payment")}
                  value={`${statsData.payment}s`}
                  className={statBoxClass1}
                />
              )}

            {!get(
              siteConfigData,
              "getSiteConfigs.not_allowed_bays",
              []
            ).includes("deliver") && (
              <BasicDataboardStatBox
                statBoxTitle={get(organization, "deliverLabel", "Deliver")}
                value={`${statsData.deliver}s`}
                className={statBoxClass1}
              />
            )}

            <BasicDataboardStatBox
              statBoxTitle={get(
                organization,
                "peakPerformanceLabel",
                "Peak 15min"
              )}
              value={
                orgId === "kfc" || orgId === "kfc_uae" || orgId === "hrd"
                  ? statsData.thruputValue
                  : get(peak15, "car_counts", 0)
              }
              loading={
                orgId === "kfc" || orgId === "kfc_uae" || orgId === "hrd"
                  ? false
                  : peak15Loading
              }
              subText={
                orgId === "kfc" || orgId === "kfc_uae" || orgId === "hrd"
                  ? "cars per hour"
                  : `${peak15DateString}`
              }
              className={statBoxClass2}
            />
            <BasicDataboardStatBox
              statBoxTitle="Total Cars"
              value={`${statsData.total_count}`}
              className={statBoxClass3}
            />
          </>
        ) : (
          [...Array(numberOfStatBoxes)].map((item, key) => (
            <PulseBox key={key} className="h-24" />
          ))
        )}
      </div>

      <div className="mt-4 grid w-full grid-cols-6 gap-4 bg-gray-300 p-4  rounded-lg overflow-hidden">
        {!loading ? (
          <DataboardPromoStatBlock
            processedData={processedData}
            round={round}
            showOldOEPE={showOldOEPE}
            sensitivity={sensitivity}
          />
        ) : (
          <>
            {[...Array(3)].map((item, key) => (
              <PulseBox key={key} className="col-span-2 h-32" />
            ))}
            {[...Array(2)].map((item, key) => (
              <PulseBox key={key} className="col-span-3 h-32" />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default DashboardReportStatBoxes;
