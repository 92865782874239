import React from "react";

const PulseBox = ({ className }) => {
  const defaultClasses = ["rounded-md", "skeleton-box", "inline-block"];

  const customClasses = className.split(" ");

  if (!customClasses.some((item) => /^w-\d/.test(item))) {
    defaultClasses.push("w-full");
  }
  if (!customClasses.some((item) => /^h-\d/.test(item))) {
    defaultClasses.push("h-16");
  }

  return (
    <span className={defaultClasses.concat(customClasses).join(" ")}></span>
  );
};

export default PulseBox;
