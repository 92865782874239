import React, { useReducer, useState, useMemo } from "react";
import { FiniteStateMachine } from "../../utils/FiniteStateMachine";
import Modal from "../Modal";
import {
  QuestionMarkCircleIcon as QuestionMarkCircleOutline,
  ExclamationCircleIcon as ExclamationCircleOutline,
  ViewGridAddIcon as ViewGridAddOutline,
  StarIcon as StarOutline,
} from "@heroicons/react/outline";
import {
  StarIcon as StarSolid,
} from "@heroicons/react/solid";
import { useSubmitFeedback } from "../../graphql/mutations";
import { currentUser } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { get } from "lodash";

const FeedbackDialog = ({ setShowFeedback = null } = {}) => {
  if (!setShowFeedback) {
    throw Error("setShowFeedback function is required");
  }
  const [authObj] = useRecoilState(currentUser);
  const { transition } = FiniteStateMachine;
  const [starRating, setStartRating] = useState(0);
  const [tempStarRating, setTempStartRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const [
    submitFeedback,
    { data: saveData, error: saveError, loading: saveLoading },
  ] = useSubmitFeedback();

  const [currentModalState, setCurrentModalState] = useReducer(transition, {
    error: false,
    loading: false,
    success: false,
  });

  useMemo(() => {
    if (saveData) {
      setCurrentModalState("success");
    } else if (saveError) {
      setCurrentModalState("error");
    } else if (saveLoading) {
      setCurrentModalState("loading");
    }
  }, [saveData, saveError, saveLoading]);

  const onSubmitFeedback = () => {

    const vars = {
      username: get(authObj, "name", ""),
      email,
      starRating,
      feedback,
      url: window.location.href,
      version: process.env.REACT_APP_VERSION,
    };
    console.log('submit feedback', { vars });
    submitFeedback({
      variables: vars,
    });
  };

  return (
    <Modal
      modalState={currentModalState}
      toggleModal={setShowFeedback}
      saveDataHandler={onSubmitFeedback}
      successDisabled={feedback === "" && starRating === 0}
    >
      <div className="bg-white p-6 ">
        <h2 className="mb-2 text-xl leading-6 font-medium text-gray-900">
          Feedback/Bug Report
        </h2>
        <p className="px-2 py-4 text-gray-900 text-sm">
          Here you can submit any feedback of the new databoard(good/bad).
          <ul className="mt-2">
            <li>
              <QuestionMarkCircleOutline className="h-4 w-4 mr-2 inline-flex" />{" "}
              Need any help understanding something(make sure to enter your
              email address)
            </li>
            <li>
              <ExclamationCircleOutline className="h-4 w-4 mr-2 inline-flex" />{" "}
              Issues/Bugs you are having
            </li>
            <li>
              <ViewGridAddOutline className="h-4 w-4 mr-2 inline-flex" />
              Feature requests.{" "}
            </li>
          </ul>
        </p>
        <table className="min-w-full divide-y divide-gray-200">
          <tbody>
            <tr>
              <td className="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                How Satisfied are you with this product.
              </td>
            </tr>
            <tr>
              <td
                className="px-2"
                onMouseLeave={() => {
                  setTempStartRating(0);
                }}
              >
                {[...Array(5)].map((e, i) => {
                  return starRating >= i + 1 ? (
                    <StarSolid
                      key={i}
                      onClick={() => setStartRating(i + 1)}
                      className="h-6 w-6 text-yellow-600 inline-flex cursor-pointer"
                    />
                  ) : tempStarRating >= i + 1 ? (
                    <StarSolid
                      key={i}
                      onMouseEnter={() => setTempStartRating(i + 1)}
                      onClick={() => setStartRating(i + 1)}
                      className="h-6 w-6 text-yellow-400 inline-flex cursor-pointer"
                    />
                  ) : (
                    <StarOutline
                      key={i}
                      onClick={() => setStartRating(i + 1)}
                      onMouseEnter={() => setTempStartRating(i + 1)}
                      className="h-6 w-6 text-yellow-600 inline-flex cursor-pointer"
                    />
                  );
                })}
              </td>
            </tr>
            <tr>
              <td className="px-2 pt-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Email
              </td>
            </tr>
            <tr>
              <td
                className="px-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
                colSpan="2"
              >
                <input
                  type="email"
                  className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400  mt-1 block w-full"
                  placeholder="john@example.com (Fill this only if you wish to receive updates on this feedback)"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td className="px-2 pt-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                Feedback / Bug
              </td>
            </tr>
            <tr>
              <td
                className="px-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
                colSpan="2"
              >
                <textarea
                  className="rounded border-gray-200 focus:ring-2 focus:border-transparent focus:ring-green-400  mt-1 block w-full"
                  placeholder="Feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default FeedbackDialog;
